import React, { useEffect, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

const hidden = {
  visibility: 'hidden',
};

export const ProcessInstanceTimeline = ({ pi }) => {

  if( !(pi?.transitions) ){
    return <></>;
  }
  

  return (
    <Timeline position="left">
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          <span style={hidden}>{pi?.transitions[0]?.date_start}</span>
        </TimelineOppositeContent>

        <TimelineSeparator>
          <TimelineDot color="grey" />
          <TimelineConnector />
        </TimelineSeparator>

        <TimelineContent>
          <span style={hidden}>{pi?.transitions[0]?.step?.robot_name}</span>
        </TimelineContent>
      </TimelineItem>
      {pi.transitions.map((transition) => (
        <TimelineItem key={transition.id}>
          <TimelineOppositeContent color="text.secondary">
            {transition.date_start}
          </TimelineOppositeContent>

          <TimelineSeparator>
            <TimelineDot
              color={
                transition.status === 'SUCCESS'
                  ? 'success'
                  : transition.status === 'STARTED'
                  ? 'info'
                  : transition.status === 'FAILURE'
                  ? 'error'
                  : transition.status === 'CANCEL'
                  ? 'error'
                  : transition.status === 'WARNING'
                  ? 'warning'
                  : 'grey'
              }
            />
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent>{transition?.step?.robot_name}</TimelineContent>
        </TimelineItem>
      ))}
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          <span style={hidden}>{pi?.transitions[0]?.date_start}</span>
        </TimelineOppositeContent>

        <TimelineSeparator>
          <TimelineDot color="grey" />
        </TimelineSeparator>

        <TimelineContent>
          <span style={hidden}>{pi?.transitions[0]?.step?.robot_name}</span>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};
