import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Stack, CircularProgress } from '@mui/material';
import Chip from '@mui/material/Chip';
import { LoadingButton } from '@mui/lab';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useDispatch } from 'react-redux';
import Terminal from '../../components/transition/terminal/Terminal';
import { Breadcrumb, CardBase } from '../../components/shared';
import PageContainer from '../../components/container/PageContainer';
import { Transition } from '../../interface/Transition';
import { setTransitionId } from '../../redux/brearcrumbs-processes/breadCrumbsActions';
import './styles.css';
import DotLoading from './DotLoading';
import { useFetch } from '../../hooks/useFetch';
import { useWebSocket } from '../../hooks/useWebSocket';
import { InfraLogs } from './InfraLogs';
import TimelineTransition from '../../components/shared/timelineTransition/TimelineTransition';
import { getTransitionLogsById, cancelTransition } from '../../lib/apiClient';
import { useSnackbar } from 'notistack';

const PENDING = 'PENDING';
const RECEIVED = 'RECEIVED';
const STARTED = 'STARTED';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const WARNING = 'WARNING';
const CANCEL = 'CANCEL';

const BEFORE_STARTED = [PENDING, RECEIVED];
const AFTER_STARTED = [SUCCESS, FAILURE, WARNING, CANCEL];

export const TransitionDetail = () => {
  const { transitionId } = useParams();
  const dispatch = useDispatch();
  const {
    data: transitionDetail,
    loading,
    refresh: refreshData,
    error,
  } = useFetch<Transition>(`/frontend/transition/detail/${transitionId}/`);
  const { onMessage, close } = useWebSocket(`/ws/transition/${transitionId}/`);
  const { onMessage: onMessageVnc } = useWebSocket(`/ws/vnc/${transitionId}/`);
  const { onMessage: onMessageLog } = useWebSocket(`/ws/log/${transitionId}/`);
  const [showLiveLogs, setShowLiveLogs] = useState(true);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if (finished) {
      refreshData(false);
    }
    return () => close();
  }, [finished]);

  useEffect(() => {
    onMessageVnc(async () => {
      await refreshData(false);
    });
    onMessage(async () => {
      await refreshData(false);
    });
    onMessageLog(() => {
      setTimeout(async () => {
        await refreshData(false);
      }, 2000);
    });
  }, []);

  useEffect(() => {
    if (transitionId) dispatch(setTransitionId(transitionId));
  }, []);

  useEffect(() => {
    if (showLiveLogs) {
      if (AFTER_STARTED.includes(transitionDetail?.status as string)) {
        setShowLiveLogs(false);
        return;
      }
    }
  }, [transitionDetail]);

  const { enqueueSnackbar } = useSnackbar();
  const cancelTransitionHandler = async () => {
    if (!transitionDetail?.id) return;

    try {
      await cancelTransition(transitionDetail?.id);
      enqueueSnackbar('Transition canceled successfully', {
        preventDuplicate: true,
      });

      

    } catch (e) {
      enqueueSnackbar('Error, try again later', {
        variant: 'error',
      });
    }
  };

  return (
    <PageContainer title={`Process Run #${transitionId}`}>
      {/* breadcrumb */}
      <Breadcrumb title={` #${transitionId}`} subtitle="Transition">
      
          <LoadingButton
            loading={loading}
            variant="outlined"
            style={{ marginRight: '8px' }}
            onClick={cancelTransitionHandler}
          >
            Cancel
          </LoadingButton>
       

        <LoadingButton loading={loading} variant="outlined" onClick={() => refreshData()}>
          Refresh
        </LoadingButton>
      </Breadcrumb>
      {/* end breadcrumb */}
      <Grid container spacing={0} sx={{ m: 3 }}>
        {/* ------------------------- row 1 ------------------------- */}
        <Grid item xs={12} lg={12}>
          <CardBase>
            <Grid container spacing={2}>
              {loading || error || transitionDetail === undefined ? (
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                >
                  <CircularProgress />
                </Grid>
              ) : (
                <>
                  <Grid item lg={12}>
                    <Chip label={transitionDetail?.status} variant="outlined" />
                  </Grid>
                  <Grid item sx={{ ml: 'auto', mr: 'auto' }}>
                    <TimelineTransition info={transitionDetail} />
                  </Grid>
                </>
              )}
            </Grid>
          </CardBase>
        </Grid>
        {transitionDetail?.message && (
          <Grid item xs={12} lg={12}>
            <CardBase>
              <Grid container spacing={2}>
                <Grid item lg={12} sx={{ mb: 2 }}>
                  <Typography component="span" variant="h4">
                    {transitionDetail?.message}
                  </Typography>
                </Grid>
              </Grid>
            </CardBase>
          </Grid>
        )}
        {/* ------------------------- DETAILS ------------------------- */}
        {transitionDetail?.status === STARTED && !finished && transitionDetail?.vnc && (
          <Grid item xs={12} lg={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  {/* ------------------------- row 1 ------------------------- */}
                  <Grid item lg={12} sx={{ mb: 2 }}>
                    <Typography component="span" variant="h2">
                      Live View
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {transitionDetail!.status === STARTED && !finished && (
                      <div className="responsiveContent">
                        <iframe allowFullScreen frameBorder={0} src={transitionDetail?.vnc} />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
        {showLiveLogs && !loading && (
          <Grid item xs={12} lg={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  {/* ------------------------- row 1 ------------------------- */}
                  <Grid item lg={12} sx={{ mb: 2 }}>
                    <Typography component="span" variant="h2">
                      Live Logs
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Terminal transitionId={transitionId as string} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
        {(finished || AFTER_STARTED.includes(transitionDetail?.status as string)) &&
          transitionDetail?.log && (
            <Grid item xs={12} lg={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={1}>
                    {/* ------------------------- row 1 ------------------------- */}
                    <Grid item lg={12} sx={{ mb: 2 }}>
                      <Typography component="span" variant="h2">
                        Transition Details
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <iframe
                        title="iframe logs"
                        style={{ width: '100%', height: '700px', overflow: 'hidden', border: 0 }}
                        src={transitionDetail?.log}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        {(finished || AFTER_STARTED.includes(transitionDetail?.status as string)) &&
          transitionDetail?.infra_logs && (
            <Grid item xs={12} lg={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={1}>
                    {/* ------------------------- row 1 ------------------------- */}
                    <Grid item lg={12} sx={{ mb: 2 }}>
                      <Typography component="span" variant="h2">
                        Infraestructure Logs
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      {!transitionDetail?.infra_logs ? (
                        <CircularProgress style={{ marginTop: '20px' }} />
                      ) : (
                        <InfraLogs transition={transitionDetail} />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
      </Grid>
    </PageContainer>
  );
};

export default TransitionDetail;
