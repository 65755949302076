import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Chip, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { CardBase } from '../../../../components/shared';
import FeatherIcon from '../../../../components/feather-icon/icon';
import { Process } from '../../../../interface/Process';
import { runProcess } from '../../../../lib/apiClient';
import { useNavigate } from 'react-router';

type Props = {
  process: Process;
  handleOpenConfigDialog: () => void;
  loading: boolean;
  error: boolean;
};

export const RunProcess = ({ process, handleOpenConfigDialog, loading, error }: Props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [runButtonLoading, setRunButtonLoading] = useState(false);

  const handleRunProcess = async () => {
    if (runButtonLoading) {
      return;
    }
    setRunButtonLoading(true);
    try {
      const response = await runProcess(process.id);
      const instanceId = response.instance_id;
      
      if(!instanceId) {
        enqueueSnackbar(`Failed to run process ${response.Error}`, { variant: 'error' });
        setRunButtonLoading(false);
        return;
      }
      enqueueSnackbar(`Successfully to run process ${process.id} ${process.name}`, {
        variant: 'success',
      });
      navigate(`/instance/${instanceId}`);
    } catch (error) {
      enqueueSnackbar(`Failed to run process ${process.id} ${process.name}`, { variant: 'error' });
    }
    setRunButtonLoading(false);
  };

  return (
    <Grid item xs={12} lg={12}>
      <CardBase>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} sm={6}>
            {process?.steps.length > 0 && (
              <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
                <Chip label={process?.status} variant="outlined" />
                <Chip label={"Parallel instances " + process?.current_parallel + "/" + process?.max_parallel} variant="outlined" />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} lg={3} sm={3}>
            <LoadingButton
              variant="contained"
              color="secondary"
              fullWidth
              size="large"
              startIcon={<FeatherIcon icon="play" width="18" />}
              onClick={handleRunProcess}
              loading={runButtonLoading}
              disabled={loading || error || process?.steps.length === 0}
            >
              Run Process
            </LoadingButton>
          </Grid>
          <Grid item xs={12} lg={3} sm={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<FeatherIcon icon="settings" width="18" />}
              onClick={handleOpenConfigDialog}
              disabled={loading || error}
            >
              Process Settings
            </Button>
          </Grid>
        </Grid>
      </CardBase>
    </Grid>
  );
};
