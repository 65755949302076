import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Typography, IconButton, Button, OutlinedInput, InputAdornment } from '@mui/material';

import { useSnackbar } from 'notistack';
import FeatherIcon from '../feather-icon/icon';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { apiSecret } from '../../lib/apiClient';
import CancelAndSave from '../shared/cancel-save/CancelAndSave';

const EditAddSecretModal = ({
  onClose,
  isOpen,
  companyId,
  workspace,
  secrets,
  setSecrets
}: any) => {
  const [loading, setLoading] = useState(false);
  const [newSecrets, setNewSecrets] = useState<{ key: string, value: string }[]>([]);
  const [deletedSecrets, setDeletedSecrets] = useState<{ key: string, value: string }[]>([]);
  const [value, setValue] = useState({
    key: '',
    value: '',
    showValue: true
  });
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = ({ target }: any) => {
    setValue({
      ...value,
      [target.name]: target.value
    })
  }

  const handleSave = async () => {
    let thereIsError = false;
    let newSecret: any = null;
    if (value.key.trim().length > 0 && value.value.trim().length > 0) {
      newSecret = {
        key: value.key.trim(),
        value: value.value.trim()
      }
    }
    const newSecretsV2 = newSecret ? [...newSecrets, newSecret] : newSecrets;
    for (const secret of newSecretsV2) {
      setLoading(true);
      try {
        await apiSecret({
          action: 'C',
          secret_key: secret.key,
          secret_value: secret.value,
          company_id: companyId,
          workspace_id: workspace.id
        });
      } catch (error) {
        thereIsError = true;
        enqueueSnackbar('Error, try again later', {
          variant: 'error',
        });
        break;
      }
    }
    for (const secret of deletedSecrets) {
      setLoading(true);
      try {
        await apiSecret({
          action: 'D',
          secret_key: secret.key,
          company_id: companyId,
          workspace_id: workspace.id
        });
      } catch (error) {
        thereIsError = true;
        enqueueSnackbar('Error, try again later', {
          variant: 'error',
        });
        break;
      }
    }
    setLoading(false);
    if (!thereIsError) {
      enqueueSnackbar('Workspace edited successfully', {
        preventDuplicate: true,
      });
    }
    onClose();
  }


  const addSecret = () => {
    if (value.key.trim().length > 0 && value.value.trim().length > 0) {
      setValue(() => {
        const newSecret = {
          key: value.key.trim(),
          value: value.value.trim()
        }
        setNewSecrets([
          ...newSecrets,
          {
            ...newSecret
          }
        ])
        return {
          key: '',
          value: '',
          showValue: true
        }
      })

    }
  }

  const deleteSecret = async (key: string) => {
    setSecrets(secrets.filter((secret: any) => secret.key !== key));
    setNewSecrets(newSecrets.filter((secret: any) => secret.key !== key));
    const secretWillDelete = secrets.find((secret: any) => secret.key === key)!;
    setDeletedSecrets([...deletedSecrets, secretWillDelete]);
  }

  useEffect(() => {
    return () => {
      setNewSecrets([]);
      setDeletedSecrets([]);
    }
  }, []);

  const handleClose = () => {
    onClose();
  }

  return (
    <div>
      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
        <DialogTitle sx={{ mt: 2, ml: 3 }}>
          <Grid container justifyContent="space-between">
            <Grid item lg={11} sx={{ mb: 2 }}>
              <Typography variant="h4" sx={{ fontWeight: 500, color: '#363636' }}>
                Secrets
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <FeatherIcon fontSize="small" icon="x" width="16" height="16" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ ml: 3 }}>
          <Grid container spacing={1} direction="row" alignItems="center">
            {[...secrets, ...newSecrets].map((secret, index) => (
              <Grid item container spacing={1} key={secret.key + index}>
                <Grid item>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type="text"
                    value={secret.key}
                    onChange={() => handleChange('password')}
                    placeholder="key"
                    size='small'
                    disabled
                  />
                </Grid>
                <Grid item>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={'password'}
                    value={secret.value}
                    onChange={() => handleChange('password')}
                    placeholder="value"
                    size='small'
                    disabled
                  />
                </Grid>
                <Grid item>
                  <IconButton size="large" onClick={() => deleteSecret(secret.key)}>
                    <FeatherIcon icon="trash" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Grid item container spacing={1} alignItems="center">
              <Grid item>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  value={value.key}
                  onChange={handleChange}
                  placeholder="key"
                  size='small'
                  name='key'
                />
              </Grid>
              <Grid item>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  value={value.value}
                  onChange={handleChange}
                  placeholder="value"
                  size='small'
                  name='value'
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addSecret();
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <IconButton size="large" onClick={addSecret}>
                  <FeatherIcon icon="plus-circle" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container sx={{ marginTop: '50px' }}>
              <CancelAndSave handleSave={handleSave} handleCancel={onClose} loading={loading} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditAddSecretModal;
