import { LoadingButton } from '@mui/lab';
import { Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageContainer from '../../components/container/PageContainer';
import ProcessConfig from '../../components/process/ProcessConfig';
import { Breadcrumb } from '../../components/shared';
import { PROCESS_PATH } from '../../constants/apiPaths';
import { useFetch } from '../../hooks/useFetch';
import { Process } from '../../interface/Process';
import { processDetailAction } from '../../redux/brearcrumbs-processes/breadCrumbsActions';
import { getProcessByIdAction, setConfigDialogOpen } from '../../redux/processes/processesActions';
import { LogsForSteps } from './components/ProcessDetail/LogsForSteps';
import { ProcessRuns } from './components/ProcessDetail/ProcessRuns';
import { RunProcess } from './components/ProcessDetail/RunProcess';
import { Steps } from './components/ProcessDetail/Steps';
import { WithoutSteps } from './components/ProcessDetail/WithoutSteps';

import { useWebSocket } from '../../hooks/useWebSocket';

export const ProcessDetail = () => {
  const { processId } = useParams();
  const { companiesState, processesState } = useSelector((state: any) => state);
  const { selectedCompany } = companiesState;
  console.log('processId', processId);

  const { process: processRedux, addStepDialogOpen } = processesState;

  const { onMessage } = useWebSocket(`/ws/process/${processRedux!?.id}/`);
  const [selectedStep, setSelectedStep] = useState(null);
  const [selectedStepName, setSelectedStepName] = useState<string | null>(null);
  const [runButtonLoading, setRunButtonLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const {
    data: process,
    loading,
    refresh: refreshProcess,
    error,
  } = useFetch<Process>(PROCESS_PATH(processId || ''));

  useEffect(() => {
    onMessage(() => refreshProcess(false));

    if (selectedCompany && processRedux) {
      dispatch(processDetailAction(selectedCompany.name, processRedux.name, processRedux.id));
    }
  }, []);

  useEffect(() => {
    if (process) {
      dispatch(getProcessByIdAction(process));
    }
  }, [process]);

  const handleCloseLogsCard = () => {
    setSelectedStep(null);
    setSelectedStepName(null);
  };

  const refresh = useCallback(() => {
    refreshProcess();
    setRunButtonLoading((state: boolean) => !state);
  }, [refreshProcess]);

  const handleStepClick = useCallback((stepId: any, stepName: any) => {
    setSelectedStep(stepId);
    setSelectedStepName(stepName);
  }, []);

  const handleOpenConfigDialog = () => {
    dispatch(setConfigDialogOpen());
  };

  const handleCloseConfigDialog = useCallback(() => {
    dispatch(setConfigDialogOpen());
    refresh();
  }, [dispatch, refresh]);

  const handleRefreshClick = () => {
    refresh();
  };

  return (
    <PageContainer title={`Process ${processId}`}>
      {/* breadcrumb */}
      <Breadcrumb title={`${processRedux?.name || '...'}`} subtitle="Process Detail">
        <LoadingButton
          loading={loading}
          onClick={handleRefreshClick}
          variant="outlined"
          disabled={loading}
        >
          Refresh
        </LoadingButton>
      </Breadcrumb>
      {/* end breadcrumb */}
      <Grid container spacing={0}>
        {/* ------------------------- Run Process ------------------------- */}
        {
          <RunProcess
            process={process!}
            handleOpenConfigDialog={handleOpenConfigDialog}
            loading={loading}
            error={error}
          />
        }
        {/* ------------------------- STEPS LIST ------------------------- */}
        {loading || error ? (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography component="span" variant="h2">
                  Steps
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </div>
              </CardContent>
            </Card>
          </Grid>
        ) : process?.steps.length === 0 ? (
          <WithoutSteps refresh={refresh} companyId={selectedCompany.id} processId={process.id} />
        ) : (
          !selectedStep && (
            <Steps
              steps={process?.steps || []}
              handleStepClick={handleStepClick}
              loading={loading}
              error={error}
            />
          )
        )}
        {/* ------------------------- ADD MODAL SETP (in process) -------------------- */}

        {/* ------------------------- lOGS LIST ------------------------- */}
        {selectedStep && (
          <LogsForSteps
            handleCloseLogsCard={handleCloseLogsCard}
            selectedStepName={selectedStepName}
            selectedStep={selectedStep}
          />
        )}
        {/* ------------------------- INSTANCE PROCESS LIST ------------------------- */}
        {!loading && (
          <ProcessRuns isNecessaryRefresh={runButtonLoading} companyId={selectedCompany.id} />
        )}
      </Grid>
      <ProcessConfig onClose={handleCloseConfigDialog} processId={processId} />
      <Grid />
    </PageContainer>
  );
};

export default ProcessDetail;
