import { useCallback, useEffect, useState } from 'react';
import { baseUrlLet } from '../constants/baseUrl';
import { Storage } from '../lib/utils';

const baseUrl = baseUrlLet;

interface Session {
  accessToken?: string;
}

export const useFetch = <T>(path: string, initialState: null | T = null, condition = true) => {
  const [data, setData] = useState<T | null>(initialState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);

  const getData = async () => {
    try {
      const session = Storage.get<Session>('session');
      const url = `${baseUrl}${path}`;
      const headers = new Headers();
      headers.append('Authorization', `Token ${session?.accessToken}`);
      const response = await fetch(url, { headers });
      const result = await response.json();
      return result as T;
    } catch (error) {
      return null;
    }
  };

  const patchData = async (data: Partial<T>) => {
    try {
      const session = Storage.get<Session>('session');
      const url = `${baseUrl}${path}`;
      console.log('Patching data to:', url, 'with data:', data);
      const headers = new Headers();
      headers.append('Authorization', `Token ${session?.accessToken}`);
      headers.append('Content-Type', 'application/json');
      const response = await fetch(url, {
        method: 'PATCH',
        headers,
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      return result as T;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const refresh = useCallback(
    async (withLoading = true) => {
      if (withLoading) {
        setLoading(true);
      }
      try {
        const result = await getData();
        setData(result);
        setError(false);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [path],
  );

  useEffect(() => {
    let isMounted = true;
    if (condition) {
      setLoading(true);
      getData()
        .then((result) => {
          if (isMounted) {
            setData(result);
            setError(false);
          }
        })
        .catch((error) => {
          console.log(error);
          if (isMounted) {
            setError(true);
          }
        })
        .finally(() => setLoading(false));
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return { data, loading, error, refresh, patchData };
};
