import React, { useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import Slide from '@mui/material/Slide';
import { logoutAction } from './redux/auth/authActions';
import { cleanCompanies } from './redux/companies/companiesActions';
import { cleanProcess } from './redux/processes/processesActions';
import { useDispatch } from 'react-redux';

import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';
import * as dotenv from 'dotenv'
dotenv.config()


const InactivityTimeout = 60 * 60 * 1000;

const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(true);
 

  const handleLogOut = () => {
    dispatch({ type: 'Clean BreadCrumbs' });
    dispatch(cleanCompanies());
    dispatch(cleanProcess());
    dispatch(logoutAction());
  };

  useEffect(() => {
    let inactivityTimer;
    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(handleInactivity, InactivityTimeout);
    };

    const handleInactivity = () => {
      handleLogOut();
      setIsActive(false);
    }

    const handleActivity = () => {
      setIsActive(true);
      resetTimer();
    }

    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);
    document.addEventListener('touchstart', handleActivity);
    resetTimer();

    return () => {
      clearTimeout(inactivityTimer);
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      document.removeEventListener('touchstart', handleActivity);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={2000}
        TransitionComponent={Slide}
      >
        {routing}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
