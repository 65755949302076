import React, { Fragment, useRef, useEffect, useState, useContext } from 'react';
import { TextField, InputAdornment } from '@mui/material';
// @ts-ignore
import PaymentIcon from 'react-payment-icons';
import InputMask from 'react-input-mask';
import FeatherIcon from '../feather-icon/icon';
import { number } from "card-validator";
import { CreditCardDataContext } from './CreditCard';
import { absLenght } from './helpers/converters';
import CustomFormLabel from '../forms/custom-elements/CustomFormLabel';

//TODO: still not proper valid at last char if number is not valid

const CardNumberInput = ({leaveFieldCallback, focus, tabIndex}) => {
  const [cardType, setCardType] = useState("");
  const [error, setError] = useState(false);
  const [info, setInfo] = useState("");
  const inputRef = useRef(null);

  const CardContext = useContext(CreditCardDataContext);

  const handleChange = (event) => {
    const cardNumberValue = event?.target?.value;
    const cardNumberValidator = number(cardNumberValue);

    setCardType(cardNumberValidator?.card?.type || "");
      if(absLenght(cardNumberValue) > 0  && !cardNumberValidator.isPotentiallyValid) {
        setError(true);
        setInfo("are you shure is valid?");
        //TODO: is not good
      // } else if (/([0-9]{4,})/.test(cardNumberValue) && !cardNumberValidator.isValid) {
      //   setError(true);
      //   setInfo("still something sticky");
      } else if (!/([0-9]+)/.test(cardNumberValue)) {
        setError(false);
        setInfo("");
      } else if (cardNumberValidator.isValid) {
        setError(false);
        setInfo("");
        if(leaveFieldCallback) {
          leaveFieldCallback(tabIndex + 1);
        }
      }
  }

  const handleBlur = (event) => {
    const cardNumberValue = event?.target?.value;
    const cardNumberValidator = number(cardNumberValue);
    if(cardNumberValidator.isValid) {
      setError(false);
      setInfo("");
      CardContext?.setCardData({
        ...CardContext.cardData,
        cardNumber: event?.target?.value || "",
        cvclenght: cardNumberValidator?.card?.code.size || 3
      });
    } else {
      setError(true);
      setInfo("still something sticky");
      if(leaveFieldCallback) {
        leaveFieldCallback(tabIndex);
      }
    }
  }

  useEffect(() => {
    if(focus) {
      
    }
  }, [focus])

  return (
    <Fragment>
      <CustomFormLabel >Card Number</CustomFormLabel>
      <InputMask
        mask="9999 9999 9999 9999"
        maskChar=" "
        onChange={handleChange}
        onBlur={handleBlur}
      >
      {() =>
        <TextField
          fullWidth
          size='small'
          variant='outlined'
          error={error}
          id="standard-error-helper-text"
          tabIndex={tabIndex}
          helperText={info}
          inputRef={inputRef}
          InputProps={{
          startAdornment: (
              <InputAdornment position="start">
                {
                  cardType === "" &&
                  <FeatherIcon fontSize="small" icon="credit-card"/>
                }
                {
                  cardType !== "" &&
                  <PaymentIcon
                    id={cardType}
                    style={{ margin: 10, width: 24 }}
                    className="payment-icon"
                  />
                }
              </InputAdornment>
            ),
          }}
        />
      }
      </InputMask>
    </Fragment>
  )
}

export default CardNumberInput;