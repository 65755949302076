import React, { useEffect, useState } from 'react';

import { Grid, Button, Card, CardContent, Typography, Skeleton, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { Breadcrumb } from '../../components/shared';
import PageContainer from '../../components/container/PageContainer';
import ProcessTable from '../../components/process/ProcessTable';
import FormDialog from '../../components/process/NewProcess';
import { processListAction } from '../../redux/brearcrumbs-processes/breadCrumbsActions';
import { useWebSocket } from '../../hooks/useWebSocket';
import { useFetch } from '../../hooks/useFetch';
import { PROCESS_LIST_PATH } from '../../constants/apiPaths';

export const ProcessList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { companiesState } = useSelector((state: any) => state);
  const { selectedCompany, companiesLoading } = companiesState;
  const [newProcessModalOpen, setNewProcessModalOpen] = React.useState(false);
  const { onMessage } = useWebSocket(`/ws/company/${selectedCompany?.id}/`);
  const [height, setHeight] = useState(400);

  const {
    data: processes,
    loading,
    error,
    refresh,
  } = useFetch(PROCESS_LIST_PATH(selectedCompany?.id), [], selectedCompany?.id !== undefined);

  const refreshProcessList = async () => {
    enqueueSnackbar('Refreshing...', {
      preventDuplicate: true,
    });
    if (processes && processes.length > 0) {
      setHeight(processes.length * 70);
    }
    await refresh();
  };

  const establishWsConnection = () => {
    onMessage( async (message: any) => {
      enqueueSnackbar('Refreshing...', {
        preventDuplicate: true,
      });
      await refresh(false);
    });
  };

  useEffect(() => {
    if (selectedCompany && processes) {
      refreshProcessList();
      establishWsConnection();
      dispatch(processListAction(selectedCompany));
    } else {
      enqueueSnackbar('Please select a company to continue...', {
        preventDuplicate: true,
      });
    }
  }, [selectedCompany]);
  
  const handleOnRefresh = () => {
    refreshProcessList();
  };

  const handleOpenNewProcessModal = () => {
    setNewProcessModalOpen(true);
  };

  return companiesLoading ? 
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '200px'}}>
    <CircularProgress 
      size={40}
      style={{ margin: 'auto' }}
    />
  </div>
  :
  selectedCompany ? (
    <PageContainer title="Process List" description="this is Starter Page">
      {/* breadcrumb */}
      <Breadcrumb title="Process List" subtitle="Welcome User">
        <LoadingButton loading={loading} onClick={handleOnRefresh} variant="outlined">
          Refresh
        </LoadingButton>
      </Breadcrumb>
      {/* end breadcrumb */}
      <Grid container spacing={0}>
        {/* ------------------------- row 1 ------------------------- */}
        <Grid item xs={12} lg={12}>
          {loading || error ? (
            <Card>
              <CardContent>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={height}
                  style={{
                    borderRadius: '20px',
                  }}
                />
              </CardContent>
            </Card>
          ) : (
            <Card>
              <CardContent>
                <Grid container spacing={0} justifyContent="space-between">
                  {/* ------------------------- row 1 ------------------------- */}
                  <Grid item lg={10} sx={{ mb: 4 }}>
                    <Typography component="span" variant="h4">
                      Company {selectedCompany && selectedCompany.name}
                    </Typography>
                  </Grid>
                  <Grid item lg={2} sx={{ marginBottom: { xs: '15px' } }}>
                    <Button
                      fullWidth
                      // variant='outlined'
                      onClick={handleOpenNewProcessModal}
                    >
                      {' '}
                      + New Process{' '}
                    </Button>

                    <FormDialog
                      modalState={newProcessModalOpen}
                      setModalState={setNewProcessModalOpen}
                      refresh={refreshProcessList}
                    />
                  </Grid>
                </Grid>
                <ProcessTable refresh={refreshProcessList} processes={error ? [] : processes} />
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </PageContainer>
  ) : (
    <PageContainer title="Process List" description="this is Starter Page">
      {/* breadcrumb */}
      <Breadcrumb title="Select a Company to continue" subtitle="Welcome User">
        <LoadingButton
          loading={loading}
          onClick={refreshProcessList}
          variant="outlined"
          disabled={!selectedCompany}
        >
          Refresh
        </LoadingButton>
      </Breadcrumb>
      {/* end breadcrumb */}
    </PageContainer>
  );
};

export default ProcessList;
