import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { ITerminalOptions, Terminal as TerminalCreate } from 'xterm';
import 'xterm/css/xterm.css';
import './styles.css';
import { getTransitionLogsById } from '../../../lib/apiClient';
import { useWebSocket } from '../../../hooks/useWebSocket';

type TProps = {
  transitionId: string;
}

const terminalOptions: ITerminalOptions = {
  fontSize: 15,
  theme: {
    background: '#031B4E',
  },
  lineHeight: 1.5,
  fontFamily: 'monospace',
  cursorStyle: 'underline',
  cursorBlink: true,
};

const Terminal = ({ transitionId }: TProps ) => {
    const terminal = new TerminalCreate(terminalOptions);
    const [terminalIsOpen, setTerminalIsOpen] = useState(false);
    const idTerm = useRef(null);
    const { onMessage } = useWebSocket(`/ws/console/${transitionId}/`);

    useEffect(() => {
      onMessage(({ data }: any ) => {
        try {
          terminal.writeln( (JSON.parse(data)).message );
        } catch (error) {
          terminal.writeln( data );
        }
      });
    }, []);

    useEffect(() => {
      getTransitionLogsById(transitionId)
        .then(resp => {
          if(resp.console){
            for (const msg of resp.console?.message) {
              terminal.writeln(msg);
            }   
          }
        });
    }, [])
    


    useLayoutEffect(() => {
      if (!terminalIsOpen) {
        const terminalElement = document.getElementById('terminal');
        terminal.open(terminalElement as HTMLElement);
        setTerminalIsOpen(true);
      }
    }, []);
    
    return (
      <div className="content-term">
        <Box ref={idTerm} id="terminal" sx={{ width: '100%' }} />
      </div>
    );
  };

export default Terminal;
