import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const HistoryBreadCrumbs = ({ option }) => {
  const itemsAux = useSelector((state) => state[option]);
  const items = itemsAux.filter((e) => e !== undefined);

  return (
    <Grid
      container
      sx={{
        p: '15px',
      }}
    >
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {items
          ? items.map((item) => (
              <div key={item?.title}>
                {item?.to ? (
                  <Link underline="none" color="inherit" component={NavLink} to={item.to}>
                    {item?.title}
                  </Link>
                ) : (
                  <Typography color="textPrimary">{item?.title}</Typography>
                )}
              </div>
            ))
          : ''}
      </Breadcrumbs>
    </Grid>
  );
};
