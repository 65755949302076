import { combineReducers } from 'redux';
import { authReducer } from './auth/authReducer';
import { breadCrumbsGeneralReducer } from './breadcrumb-general/breadCrumbsReducer';
import { breadCrumbsReducer } from './brearcrumbs-processes/breadCrumbsReducer';
import { companiesReducer } from './companies/companiesReducer';
import CustomizerReducer from './customizer/CustomizerReducer';
import { processesReducer } from './processes/processesReducer';
import { robotReducer } from './robot/robotReducer';
import { workspaceReducer } from './workspace/workspaceReducer';
import { formReducer } from './forms/formReducer';

const RootReducers = combineReducers({
  CustomizerReducer,
  auth: authReducer,
  companiesState: companiesReducer,
  processesState: processesReducer,
  robot: robotReducer,
  forms: formReducer,
  workspace: workspaceReducer,
  breadcrumbs: breadCrumbsReducer,
  breadcrumbsGeneral: breadCrumbsGeneralReducer,
});

export default RootReducers;
