export const GET_PROCESSES = 'Get Processes';
export const GET_PROCESS_BY_ID = 'Get process by id';
export const LOADING = 'Loading';
export const SET_CONFIG_DIALOG_OPEN = 'Open or close dialog';
export const SET_ADD_STEP_DIALOG = 'Open or close ADD STEP dialog';
export const CLEAN_PROCESS = 'Clean Process';

export type ActionType =
  | 'Get Processes'
  | 'Get process by id'
  | 'Loading'
  | 'Open or close dialog'
  | 'Open or close ADD STEP dialog'
  | 'Clean Process';
