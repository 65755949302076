import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// eslint-disable-next-linstepse import/extensions
import { MetaForm } from '../MetaForm';
import { WithoutSteps } from '../../../views/process/components/ProcessDetail/WithoutSteps';
import { Button, Grid, Chip } from '@mui/material';
import { setAddStepDialogOpen } from '../../../redux/processes/processesActions';
import AddStepModal from '../AddStepModal';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import url from '../../../assets/images/up-and-down-arrows.svg';
import { getProcessById, updatePartialSteps } from '../../../lib/apiClient';

import DeleteIcon from '@mui/icons-material/Delete';

import DeleteModal from '../DeleteModal';
import { deleteStep } from '../../../lib/apiClient';
import { BorderAllRounded } from '@mui/icons-material';

import AvancedStepSettings from '../AdvancedStepSettings';

import { useWebSocket } from '../../../hooks/useWebSocket';

function Row(props: any) {
  const { row, onClose, provided, trashIcon, companyId, processId, refresh } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        {...provided.draggableProps}
        ref={provided.innerRef}
      >
        <TableCell width={'10%'}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell width={200} component="th" scope="row" align="center">
          {row.step_nro}
        </TableCell>
        <TableCell width={'10%'} align="center">
          {row.id}
        </TableCell>
        <TableCell width={'30%'} align="center">
          {row.robot_name}
        </TableCell>
        <TableCell width={'30%'} align="center">
          <Chip
            color={
              row.status === 'FAILURE' ? 'error' : row.status === 'WARNING' ? 'warning' : 'default'
            }
            label={row.status}
            variant="outlined"
          />
        </TableCell>
        <TableCell width={'30%'} align="center">
          {row.workspace_name}
        </TableCell>
        <TableCell {...provided.dragHandleProps} width={'10%'} align="center">
          <img src={url} alt="" width={16} />
        </TableCell>
        <TableCell
          width={'30%'}
          align="center"
          onClick={(e) => trashIcon(e, row.id, row.robot_name)}
        >
          <DeleteIcon style={{ color: '#FE2E2E' }}></DeleteIcon>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ m: 5 }}>
              <Typography variant="h6" gutterBottom component="div">
                Step Advanced Settings
              </Typography>
              <AvancedStepSettings
                companyId={companyId}
                processId={processId}
                refresh={refresh}
                step={row}
                onClose={refresh}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result.map((value, index) => ({ ...value, step_nro: index + 1 }));
};

export const StepsConfig = ({ onClose }: any) => {
  const { processesState, companiesState } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { process, addStepDialogOpen } = processesState;
  const { selectedCompany } = companiesState;

  const { onMessage } = useWebSocket(`/ws/process/${process.id}/`);

  onMessage(() => handleRefresh());

  const [steps, setSteps] = React.useState(process?.steps);

  console.log(steps);

  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');
  const [name, setName] = React.useState('');

  const handleTrashIcon = async (event: any, id: string, name: string) => {
    event.stopPropagation();
    event.preventDefault();
    setDeleteId(id);
    setName(name);
    setOpenDelete(true);
    return;
  };

  const handleClose = () => {
    setOpenDelete(false);
  };

  const handleRefresh = () => {
    getProcessById(process.id).then((result) => setSteps(result.steps));
  };

  const handleDeleteStep = async () => {
    try {
      const data = await deleteStep(deleteId);
      console.log('delete successful', data);
      handleRefresh();
    } catch {
      console.log('error');
    }
    setOpenDelete(false);
  };

  const handleOpenDialog = () => {
    dispatch(setAddStepDialogOpen());
  };
  const handleOnDragEnd = async (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.index === destination.index && source.droppableId === destination.droppableId) {
      return;
    }
    const changeStepNro = reorder([...steps], source.index, destination.index);
    const body: any = changeStepNro.map(({ id, config, time_out, step_nro }) => ({
      id,
      step_nro,
    }));
    setSteps(changeStepNro);
    try {
      await updatePartialSteps(body);
    } catch (error) {
      return;
    }
  };

  if (process?.steps?.length === 0) {
    return (
      <WithoutSteps
        refresh={() => {}}
        companyId={selectedCompany?.id}
        processId={process?.id}
        withOutTitle={true}
      />
    );
  }

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="steps">
          {(droppableProvided) => (
            <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
              <TableContainer component={Paper}>
                <Table
                  style={{
                    width: '100%',
                    border: '1px solid',
                    borderColor: '#E0E0E1',
                    borderRadius: '50px',
                  }}
                  aria-label="collapsible table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell width={'10%'} />
                      <TableCell width={'10%'} align="center">
                        Ex #
                      </TableCell>
                      <TableCell width={'10%'} align="center">
                        Id
                      </TableCell>
                      <TableCell width={'30%'} align="center">
                        Step Name
                      </TableCell>
                      <TableCell width={'30%'} align="center">
                        Status
                      </TableCell>
                      <TableCell width={'30%'} align="center">
                        Workspace
                      </TableCell>
                      <TableCell width={'10%'} />
                      <TableCell width={'10%'} align="center">
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {steps?.map((row: any, i: number) => (
                      <Draggable key={row.id} draggableId={row.id.toString()} index={i}>
                        {(draggableProvided) => (
                          <Row
                            companyId={selectedCompany?.id}
                            processId={process?.id}
                            refresh={handleRefresh}
                            trashIcon={handleTrashIcon}
                            row={row}
                            onClose={onClose}
                            provided={draggableProvided}
                          />
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </Droppable>
        {addStepDialogOpen && (
          <AddStepModal
            onClose={() => dispatch(setAddStepDialogOpen())}
            companyId={selectedCompany?.id}
            openDialog={addStepDialogOpen}
            processId={process?.id}
            refresh={handleRefresh}
          />
        )}
      </DragDropContext>

      <DeleteModal
        modalClose={() => handleClose()}
        showDeleteModal={openDelete}
        name={name}
        id={deleteId}
        action={'step'}
        fxDelete={handleDeleteStep}
      />
    </>
  );
};
