import { Robot } from '../../interface/Robot';
import { Storage } from '../../lib/utils';
import { ROBOT_SELECTED } from './types';

interface State {
  robotSelected: Robot | null;
}

const initialState: State = {
  robotSelected: Storage.get('robotSelected') || null,
};

export const robotReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case ROBOT_SELECTED:
      Storage.set('robotSelected', action.payload);
      return {
        ...state,
        robotSelected: action.payload,
      };
    default:
      return state;
  }
};
