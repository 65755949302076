import { Button, Grid, CircularProgress } from '@mui/material';
import React from 'react';

type IProps = {
  handleCancel: () => void;
  handleSave: () => void;
  handlePreview: () => void;
  loading?: boolean;
};

const PreviewCancelSave = ({ handleCancel, handleSave, handlePreview, loading }: IProps) => (
  <Grid container alignItems="center" justifyContent="center" spacing={1}>
    <Grid item lg={2}>
      <Button disabled={loading} onClick={handleCancel} fullWidth variant="outlined">
        Cancel
      </Button>
    </Grid>

    <Grid item lg={2}>
      <Button fullWidth onClick={handleSave} variant="outlined">
        {!loading && <span>Save</span>}
        {loading && <CircularProgress size={27} />}
      </Button>
    </Grid>

    <Grid item lg={2}>
      <Button disabled={loading} onClick={handlePreview} fullWidth variant="outlined">
        Preview
      </Button>
    </Grid>
  </Grid>
);

export default PreviewCancelSave;
