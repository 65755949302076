import { useEffect } from 'react';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { baseWSLet } from '../constants/baseUrl';

export const useWebSocket = (path: string) => {
  const client = new W3CWebSocket(`${baseWSLet}${path}`);

  useEffect(() => {
    client.onopen = () => console.log(`WebSocket Client Connected: ${path}`);
    client.onclose = () => console.log(`WebSocket Client Closed: ${path}`);
    return () => client.close();
  }, []);

  const onMessage = (callback: any) => {
    client.onmessage = callback;
  };

  const close = () => {
    client.close();
  };

  return {
    onMessage,
    close,
  };
};
