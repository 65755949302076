import { Robot } from '../../interface/Robot';
import { Workspace } from '../../interface/Workspace';
import { Storage } from '../../lib/utils';
import { WORKSPACE_SELECTED } from './types';

interface State {
  workspaceSelected: Workspace | null;
}

const initialState: State = {
  workspaceSelected: Storage.get('workspaceSelected') || null,
};

export const workspaceReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case WORKSPACE_SELECTED:
      Storage.set('workspaceSelected', action.payload);
      return {
        ...state,
        workspaceSelected: action.payload,
      };
    default:
      return state;
  }
};
