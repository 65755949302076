import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Grid, List, ListItem, ListItemText } from '@mui/material'
import React from 'react'
import PageContainer from '../../components/container/PageContainer'
import { Breadcrumb } from '../../components/shared'
import { useSelector } from 'react-redux';


const Company = () => {
    const { selectedCompany } = useSelector((state: any) => state?.companiesState);
    console.log(selectedCompany);
    return (

        <PageContainer title="Company Info" description="Company Info">
            <Breadcrumb title="Company Info" />
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item xs={6}>
                            <List>
                                <ListItem divider>
                                    <span style={{ marginRight: '10px' }}>Company:</span>
                                    <ListItemText secondaryTypographyProps={{ align: "left" }} secondary={selectedCompany?.name || ''} />
                                </ListItem>
                                <ListItem divider>
                                    <span style={{ marginRight: '10px' }}>Country:</span>
                                    <ListItemText secondaryTypographyProps={{ align: "left" }} secondary={selectedCompany?.country || ''} />
                                </ListItem>
                                <ListItem divider>
                                    <span style={{ marginRight: '10px' }}>Contact:</span>
                                    <ListItemText secondaryTypographyProps={{ align: "left" }} secondary={selectedCompany?.mail || ''} />
                                </ListItem>
                                <ListItem divider>
                                    <span style={{ marginRight: '10px' }}>Token:</span>
                                    <ListItemText secondaryTypographyProps={{ align: "left" }} secondary={selectedCompany?.token_id || ''} />
                                </ListItem>

                            </List>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </PageContainer>
    )
}
export default Company