import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Button } from '@mui/material';

type Props = {
  modalClose: () => void;
  showDeleteModal: boolean;
  name: string;
  id: string;
  action: string;
  fxDelete: (id: string) => void;
};

function DeleteModal({ modalClose, showDeleteModal, name, id, action, fxDelete }: Props) {
  return (
    <>
      <Dialog
        open={showDeleteModal}
        onClose={() => modalClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete {action}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this {name} {action}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => modalClose()}>No</Button>
          <Button onClick={() => fxDelete(id)}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteModal;
