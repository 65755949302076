import * as React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import { darken, lighten } from '@mui/material/styles';

import { useSnackbar } from 'notistack';
import { runProcess } from '../../lib/apiClient';
import { useDispatch } from 'react-redux';
import { setRobotSelected } from '../../redux/robot/robotActions';
import { setRobotDetailBreadcrumb } from '../../redux/breadcrumb-general/breadCrumbsActions';

import DeleteIcon from '@mui/icons-material/Delete';

import { deleteRobot } from '../../lib/apiClient';

import DeleteModal from '../process/DeleteModal';

const defCols = (clickDelete: any) => {
  const columns: GridColumns = [
    { field: 'nombre', headerName: 'Nombre', minWidth: 200, flex: 1 },
    {
      field: 'keyword',
      headerName: 'Keyword',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'git_url',
      headerName: 'Git URL',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'docker_url',
      headerName: 'Docker URL',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'delete',
      headerName: 'Delete',
      align: 'center',
      headerAlign: 'center',
      renderCell: (cellValues) => {
        return (
          <IconButton
            onClick={(event) => {
              clickDelete(event, cellValues);
            }}
          >
            <DeleteIcon style={{ color: '#FE2E2E' }}></DeleteIcon>
          </IconButton>
        );
      },
    },
  ];
  return columns;
};

export default function RobotTable({ refresh, robots, company }: any) {
  const [pageSize, setPageSize] = React.useState(15);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');
  const [nameRobot, setNameRobot] = React.useState('');

  const handleClick = (params: any) => {
    dispatch(setRobotSelected(params.row));
    dispatch(setRobotDetailBreadcrumb(company, 'robots', params.row.nombre, params.id));
    navigate({ pathname: `/robots/${params.id}` });
  };
  const handleTrashIcon = async (event: MouseEvent, cellValues: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (!cellValues.row.id && !cellValues.row.nombres) return;
    setDeleteId(cellValues.row.id);
    setNameRobot(cellValues.row.nombre);
    setOpen(true);

    return;
  };

  const handleDeleteRobot = async () => {
    try {
      const data = await deleteRobot(deleteId);
      console.log('delete successful', data);
      refresh();
    } catch (e: any) {
      console.log(e.message);
    }
    setOpen(false);
    return;
  };

  const handleClose = () => {
    setOpen(false);
    return;
  };

  const cols = defCols(handleTrashIcon);

  return (
    <>
      <div style={{ width: '100%', marginTop: '20px' }}>
        <DataGrid
          className="animate__animated animate__fadeIn"
          initialState={{
            sorting: {
              sortModel: [{ field: 'enable', sort: 'desc' }],
            },
          }}
          rows={robots || []}
          columns={cols}
          pageSize={pageSize}
          onRowClick={handleClick}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15]}
          autoHeight
          getRowClassName={(params) => {
            return params.row.enable ? 'row-custom-enabled' : 'row-custom-disabled';
          }}
        />
      </div>
      <DeleteModal
        action="robot"
        showDeleteModal={open}
        id={deleteId}
        name={nameRobot}
        modalClose={handleClose}
        fxDelete={handleDeleteRobot}
      />
    </>
  );
}
