import validator from 'email-validator';

export const Validators = {
    isRequired: (value: string): boolean => {
        if(value.trim().length > 0){
            return true;
        }
        return false;
    },
    minLength: (min: number) => {
        return (value: string) => {
            if(value.trim().length > min){
                return true;
            }
            return false;
        }
    },
    isEmail: (value: string): boolean => {
        if(validator.validate(value)){
            return true;
        }
        return false;
    }
}