import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  Typography,
  Skeleton,
  CircularProgress,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_PATH, ROBOT_DEPLOY_LIST_PATH } from '../../constants/apiPaths';
import { baseWSLet } from '../../constants/baseUrl';
import { useFetch } from '../../hooks/useFetch';
import { Form } from '../../interface/Form';
import { submitForm } from '../../lib/apiClient';
import { ObjectInput } from 'react-object-input';
import CustomTextField from '../../components/forms/custom-elements/CustomTextField';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

//import 'survey-core/defaultV2.min.css';

//import 'survey-core/modern.min.css';

//import { Survey } from 'survey-react-ui';
//import { StylesManager, Model } from 'survey-core';

//StylesManager.applyTheme('defaultV2');

type TypeFormMeta = {
  name: string;
  process: number;
  publicUrl: boolean;
};

export const FormDetail = () => {
  const { keyurl } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, error } = useFetch<any>(FORM_PATH(keyurl as string), []);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  //const [survey, setSurvey] = useState(new Model({}));
  const [formMeta, setFormMeta] = useState<any>({});

  useEffect(() => {
    if (!Array.isArray(data)) {
      console.log(data);
      setFormMeta(data.results[0].meta);
      //setSurvey(new Model({ ...data.results[0].meta }));
    }
  }, [loading, data]);

  const onSubmit = async () => {
    setLoadingSubmit(true);

    const data = {
      meta: formMeta,
      key: keyurl as string,
    };

    try {
      const rta = (await submitForm(data)) as any;

      enqueueSnackbar('Form sent successfully, ' + rta.instance_id, {
        preventDuplicate: true,
      });
      setLoadingSubmit(false);
    } catch (e) {
      setLoadingSubmit(false);

      enqueueSnackbar('Error, try again later', {
        variant: 'error',
      });
    }
  };

  const surveyComplete = useCallback((sender: any) => {
    console.log(sender.data);
    setFormMeta(sender.data);
    onSubmit();
  }, []);

  //survey.onComplete.add(surveyComplete);

  return (
    <>
      <Grid container alignContent="center" flexDirection="column" width="100%">
        <Typography fontSize={50} color="textPrimary" fontWeight="800" variant="h1">
          {data.results ? data.results[0].name : <Skeleton variant="rectangular" width="100%" height={400} /> }
        </Typography>
      </Grid>
      <Card>
      <CardContent>
          {loading || error || !data ? (
            <Skeleton variant="rectangular" width="100%" height={400} />
          ) : (
            <Grid container flexDirection="column" justifyContent="center">
              <Grid item xs={6} style={{width: "450px"}}>
                <List>
                  <ListItem divider style={{ display: 'flex' }}>
                    <span style={{ width: '100%' }}>Name:</span>
                    <Typography>{data?.results[0].name || ''}</Typography>
                  </ListItem>
                  <ListItem divider style={{ display: 'flex' }}>
                    <span style={{ width: '100%' }}>Description:</span>
                    <Typography>{data?.results[0].description || ''}</Typography>
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={12} width="full">
                <ObjectInput
                  obj={formMeta}
                  onChange={setFormMeta}
                  renderAdd={(addItem): any => {
                    return '';
                  }}
                  renderItem={(key, value, updateKey, updateValue, deleteProperty) => (
                    // render an editor row for an item, using the provided callbacks
                    // to propagate changes { "A": [ "b","c" ] }
                    <div>
                      <Typography style={{ textAlign: 'start' }}>{key}</Typography>

                      <CustomTextField
                        value={value || ''} // value will be undefined for new rows
                        onChange={(e: any) => {
                          if (Array.isArray(value)) {
                            updateValue(e.target.value.split(','));
                            return;
                          }
                          updateValue(e.target.value);
                        }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ mb: 1, width: 450 }}
                      />
                    </div>
                  )}
                />
                <Button style={{ width: '250px', marginLeft: "auto", marginTop: "20px" }} onClick={onSubmit} variant="contained">
                  {loadingSubmit ? <CircularProgress size={27} /> : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          )}
        </CardContent>
        {/*<CardContent>{survey && <Survey model={survey} />}</CardContent>*/}
      </Card>
    </>
  );
};
