import React from 'react';
import { Card, CardContent, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { ProcessInstance } from '../../../../interface/ProcessInstance';

type Props = {
  processInstance: ProcessInstance;
  loading: boolean;
  error: boolean;
};

export const TextResult = ({ processInstance, loading, error }: Props) => (
  <Grid item xs={12} lg={4}>
    <Card style={{ height: '250px' }}>
      <CardContent>
        <Grid container spacing={1}>
          {/* ------------------------- row 1 ------------------------- */}
          <Grid item xs={12}>
            <Typography component="span" variant="h2">
              Result
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {!loading || !error ? (
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={4}
                defaultValue={JSON.stringify(processInstance?.meta)}
                style={{ width: '100%' }}
                disabled={true}
              />
            ) : !loading && !error ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                  width: '100%',
                }}
              >
                <p>Nothing to show </p>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                  width: '100%',
                }}
              >
                <CircularProgress className="animate__animated animate__fadeIn" />
              </div>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);
