import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Grid } from '@mui/material'
import React from 'react'
import PageContainer from '../../components/container/PageContainer'
import { Breadcrumb } from '../../components/shared'
import { CustomTable } from '../../shared'
import { TField } from '../../shared/CustomTable'
import CustomInput from '../../shared/input/CustomInput'

const fields: TField[] = [
  {
      headerName: 'User',
      field: 'user',
  },
  {
      headerName: 'Role',
      field: 'role',
  },
  {
      headerName: 'Action',
      field: 'action',
  }
]

const rows = [
  {
    user: 'nico',
    role: 'admin',
    action: '',
    id: 1
  }
]

export const Members = () => {
  return (
    
    <PageContainer title="Users and rols management" description="this is management members">
      <Breadcrumb title="Users and rols management" />
      <Card>
        <CardContent>
          <Grid container gap={3}>
            <Grid item xs={4}>
              <CustomInput label={''} value={''} onChange={undefined} />
            </Grid>
            <Grid item>
              <LoadingButton variant='contained' color='secondary'>Invite User</LoadingButton>
            </Grid>
            <Grid xs={12}>
              <CustomTable 
                fields={fields} 
                rows={ rows } 
                handleRowClick={ () => {} }          
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </PageContainer>
  )
}





