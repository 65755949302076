import * as React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import { darken, lighten } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { runProcess } from '../../lib/apiClient';
import { useDispatch } from 'react-redux';
import { setFormSelected } from '../../redux/forms/formActions';
import { setRobotDetailBreadcrumb } from '../../redux/breadcrumb-general/breadCrumbsActions';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteForm } from '../../lib/apiClient';

import DeleteModal from './DeleteModal';

const defCols = (clickDelete: any) => {
  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      maxWidth: 50,
      flex: 1,
    },
    { field: 'name', headerName: 'Name', maxWidth: 170, flex: 1 },
    {
      field: 'process_name',
      headerName: 'Process',
      maxWidth: 170,
      flex: 1,
    },
    { field: 'public', headerName: 'Public', maxWidth: 80, flex: 1 },
    { field: 'url', headerName: 'URL', minWidth: 200, flex: 1 },
    { field: 'referal_form_id', headerName: 'JotForm ID', minWidth: 50, flex: 1 },
    {
      field: 'delete',
      headerName: 'Delete',
      align: 'center',
      headerAlign: 'center',
      renderCell: (cellValues) => {
        return (
          <IconButton
            onClick={(event) => {
              clickDelete(event, cellValues);
            }}
          >
            <DeleteIcon style={{ color: '#FE2E2E' }}></DeleteIcon>
          </IconButton>
        );
      },
    },
  ];
  return columns;
};

export default function FormTable({
  forms,
  company,
  setOpenDialogForm,
  setFormChanged,
  setIsEdit,
  refresh
}: any) {
  const [pageSize, setPageSize] = React.useState(15);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');
  const [nameForm, setNameForm] = React.useState('');

  const handleClick = (params: any) => {
    setIsEdit(true);
    setOpenDialogForm(true);
    setFormChanged(params.row);
  };

  const handleTrashIcon = async (event: MouseEvent, cellValues: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (!cellValues.row.id && !cellValues.row.name) return;
    setDeleteId(cellValues.row.id);
    setNameForm(cellValues.row.name);
    setOpen(true);

    return;
  };

  const handleDeleteForm = async () => {
    try {
      const data = await deleteForm(deleteId);
      console.log('delete successful', data);
      refresh();
    } catch (e) {
      console.log(e);
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const cols = defCols(handleTrashIcon);

  return (
    <>
      <div style={{ width: '100%', marginTop: '20px' }}>
        <DataGrid
          className="animate__animated animate__fadeIn"
          initialState={{
            sorting: {
              sortModel: [{ field: 'enable', sort: 'desc' }],
            },
          }}
          rows={forms || []}
          columns={cols}
          pageSize={pageSize}
          onRowClick={handleClick}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15]}
          autoHeight
          getRowClassName={(params) => {
            return params.row.enable ? 'row-custom-enabled' : 'row-custom-disabled';
          }}
        />
      </div>
      <DeleteModal
        action={'form'}
        modalClose={handleClose}
        fxDelete={handleDeleteForm}
        name={nameForm}
        id={deleteId}
        showDeleteModal={open}
      />
    </>
  );
}
