import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import { formsRoutes } from './formsRoutes';
import { robotRoutes } from './robotRoutes';
import { workspaceRoutes } from './workspaceRoutes';
import { processesRoutes } from './processesRoutes';
import { PublicMiddleware } from './guards/PublicMiddleware';
import { ErrorPublic } from '../views/authentication/ErrorPublic';
import ForgotPassword from '../views/authentication/ForgotPassword';
import Register from '../views/authentication/Register';
import EmailVerify from '../views/authentication/EmailVerify';
import { FullLayout } from './lazyload/FullLayout';
import MainBilling from '../views/billing/MainBilling';
import { Members } from '../views/members/Members';
import { RequireAuth } from './guards/RequireAuth';
import { FormDetail } from '../views/form/FormDetail';
import Company from '../views/company/Company';
/* ***Layouts**** */
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));

// eslint-disable-next-line no-sparse-arrays
const Router = [
  ...processesRoutes,
  ...robotRoutes,
  ...workspaceRoutes,
  ...formsRoutes,
  ,
  /*
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/vault', element: <h1>workspaces</h1> },
    ]
  }*/ {
    path: '/billing',
    element: <FullLayout />,
    children: [
      {
        path: '',
        element: (
          <RequireAuth>
            {' '}
            <MainBilling />{' '}
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: '/company',
    element: <FullLayout />,
    children: [
      {
        path: '',
        element: (
          <RequireAuth>
            {' '}
            <Company />{' '}
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: '/members',
    element: <FullLayout />,
    children: [
      {
        path: '',
        element: (
          <RequireAuth>
            {' '}
            <Members />{' '}
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: '/noauth',
    element: <BlankLayout />,
    children: [
      { path: '', element: <PublicMiddleware /> },
      { path: 'error', element: <ErrorPublic /> },
    ],
  },
  {
    path: '/form',
    element: <BlankLayout />,
    children: [
      {
        path: ':keyurl',
        element: <FormDetail />,
      },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: 'password-reset', element: <ForgotPassword /> },
      { path: 'email-verify', element: <EmailVerify /> },
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
