import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router';
import { Storage } from '../../lib/utils';

export function HasCompany({ children }: any) {
  const { selectedCompany } = useSelector((state: any) => state.companiesState);
  if (!selectedCompany) {
    Storage.set('tab', 'processes');
    return <Navigate to="/processes" />;
  }

  return children;
}
