import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react'
import { ITerminalOptions, Terminal as TerminalCreate } from 'xterm';
import 'xterm/css/xterm.css';
import './styles2.css';

const terminalOptions: ITerminalOptions = {
    fontSize: 15,
    theme: {
      background: '#031B4E',
    },
    lineHeight: 1.5,
    fontFamily: 'monospace',
    cursorStyle: 'underline',
    cursorBlink: true
};

export const InfraLogs = ({ transition }: any) => {

    const container: any = useRef({ offsetWidth: 0 });
    const terminal = new TerminalCreate(terminalOptions);
    const [terminalIsOpen, setTerminalIsOpen] = useState(false);

    useEffect(() => {
      const resize = () => {
        if(container.current){
          terminal.resize( Math.round(container.current?.offsetWidth / 10) , terminal.rows)
        }
      };
      resize();
      window.addEventListener('resize', resize);
      return () => window.removeEventListener('resize', resize );
    }, [container]);
    

    useEffect(() => {
    if (!terminalIsOpen) {
        const terminalElement = document.getElementById('terminal2');
        terminal.open(terminalElement as HTMLElement);
        setTerminalIsOpen(true);
    }
    terminal.write(transition?.infra_logs);
    }, [terminalIsOpen, transition]);

  return (
    <div ref={container} className="content-term2">
        <Box id="terminal2" sx={{ width: '100%' }} />
    </div>
  )
}
