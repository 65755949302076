import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Skeleton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb } from '../../components/shared';
import PageContainer from '../../components/container/PageContainer';
import { FormsList, Form } from '../../interface/Form';
import FormTable from '../../components/process/FormTable';
import AddNewForm from '../../components/process/Forms/AddNewForm';
import { firstListAction } from '../../redux/breadcrumb-general/breadCrumbsActions';
import { useFetch } from '../../hooks/useFetch';
import { FORMS_LIST_PATH } from '../../constants/apiPaths';

export const FormList = () => {
  const { companiesState } = useSelector((state: any) => state);
  const { selectedCompany } = companiesState;
  const [isEdit, setIsEdit] = useState<null | boolean>(null);
  const dispatch = useDispatch();
  const [formChanged, setFormChanged] = useState<null | Form>(null);

  const {
    data: forms,
    loading,
    error,
    refresh: refreshForms,
  } = useFetch<FormsList>(FORMS_LIST_PATH(selectedCompany.id));

  const [openDialogForm, setOpenDialogForm] = React.useState(false);

  useEffect(() => {
    dispatch(firstListAction(selectedCompany, 'Forms'));
  }, []);

  useEffect(() => {
    refreshForms();
  }, [selectedCompany]);

  return (
    <>
      <PageContainer title="Form List" description="this is Form list">
        {/* breadcrumb */}

        <Breadcrumb title="Forms" subtitle="Welcome User">
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={() => {
              setIsEdit(false);
              setOpenDialogForm(!openDialogForm);
            }}
          >
            New Form
          </LoadingButton>
        </Breadcrumb>
        <Card>
          <CardContent>
            <Grid container>
              {/* ------------------------- row 1 ------------------------- */}
              <Grid item xs={12} lg={12}>
                {loading || error ? (
                  <Skeleton variant="rectangular" width="100%" height={400} />
                ) : (
                  <FormTable
                    setFormChanged={setFormChanged}
                    setIsEdit={setIsEdit}
                    setOpenDialogForm={setOpenDialogForm}
                    forms={forms?.results}
                    company={selectedCompany}
                    refresh={refreshForms}
                  />
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </PageContainer>
      <AddNewForm
        onClose={() => setOpenDialogForm(!openDialogForm)}
        isOpen={openDialogForm}
        isEdit={isEdit}
        setFormChanged={formChanged}
        formChanged={formChanged}
        refresh={refreshForms}
        companyId={selectedCompany.id}
      />
    </>
  );
};

export default FormList;
