import React, { useEffect, useRef, useState } from 'react';
import './styles.css';

const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const WARNING = 'WARNING';
const CANCEL = 'CANCEL';

const AFTER_STARTED = [ SUCCESS, FAILURE, WARNING, CANCEL ];

const TimelineTransition = ({ info }: any ) => {

    const pending: any = useRef({ offsetWidth: 0 });
    const received: any = useRef({ offsetWidth: 0 });
    const started: any = useRef({ offsetWidth: 0 });
    const finish: any = useRef({ offsetWidth: 0 });

    const [finishWidth, setFinishWidth] = useState(0);
    const [startedWidth, setStartedWidth] = useState(0);

    useEffect(() => {
        setFinishWidth(
            info?.date_end ?
            (finish.current?.offsetWidth - 20) / 2
            :
            (started.current?.offsetWidth - 20) / 2
        );

        setStartedWidth(
            info?.date_started ?
            (started.current?.offsetWidth - 20) / 2
            :
            (received.current?.offsetWidth - 20) / 2
        );
    }, [info]);
    
    const getBackgroudColor = (status: string) => {
        if(status === 'SUCCESS'){
            return '#00c292'
        }
        if(status === 'FAILURE'){
            return '#e46a76'
        }
        if(status === 'WARNING'){
            return '#fec90f'
        }
        if(status === 'CANCEL'){
         return '#e46a76'
        }
    }

    const getBackgroudStarted = (status: string) => {
        if(status !== 'RECEIVED'){
            return '#00c292'
        }
    }

    const getBackgroudReceived = (status: string) => {
        if(status !== 'PENDING'){
            return '#00c292'
        }
    }

    return (
        <div className='timeline'>
            <div className='container-status'>
                <div ref={pending} className='container-circle'>
                    <p>PENDING<span className='d-block'>{info?.date_create?.toString()}</span> </p>
                </div>
                <div ref={received} className='container-circle'>
                    <p>RECEIVED<span className='d-block'>{info?.date_start?.toString()}</span> </p>
                </div>
                <div ref={started} className='container-circle'>
                    <p>STARTED
                    <span style={{ visibility: info?.date_started ? 'visible' : 'hidden' }} className='d-block'>{info?.date_started?.toString() || info?.date_create?.toString()}</span> </p>
                </div>
                {
                    <div  ref={finish} className='container-circle'>
                        <p>{ !(AFTER_STARTED.includes(info.status) ) ? 'FINISH' : info.status }
                        <span style={{ visibility: info?.date_end ? 'visible' : 'hidden' }} className='d-block'>{info?.date_end?.toString() || info?.date_create?.toString() }</span> </p>
                    </div>
                }
            </div>
            <div className='container-color'>
                <div  className='container-circle'>
                    <div className='container-line'>
                        <div style={{ visibility: 'hidden', width: (pending.current?.offsetWidth - 40) / 2 }} className='line'></div>
                        <div className='circle' style={{ backgroundColor: '#00c292' }}></div>
                        <div className='line m-left' style={{ width: (pending.current?.offsetWidth - 40) / 2 }}></div>
                    </div>
                    <div className='container-line'>
                        <div style={{ width: (received.current?.offsetWidth - 40) / 2 }} className='line m-right'></div>
                        <div className='circle'style={{ backgroundColor: getBackgroudReceived(info.status) }} ></div>
                        <div 
                            className='line m-left' 
                            style={{
                                width: (started.current?.offsetWidth - 20) / 2 
                            }}>
                        </div>
                    </div>
                    <div className='container-line'>
                        <div style={{ width: (received.current?.offsetWidth - 40) / 2 }} className='line m-right'></div>
                        <div className='circle' style={{ backgroundColor: getBackgroudStarted(info.status) }}></div>
                        <div 
                            className='line m-left' 
                            style={{
                                visibility: 'visible',
                                width: (started.current?.offsetWidth - 20) / 2 
                            }}>
                        </div>
                    </div>
                    {
                        
                        <div className='container-line'>
                            <div style={{ width: (finish.current?.offsetWidth - 40) / 2 }} className='line m-right'></div>
                            <div className='circle' style={{ backgroundColor: getBackgroudColor(info.status) }}></div>
                            <div className='line m-left' style={{ visibility: 'hidden', width: (finish.current?.offsetWidth - 40) / 2 }}></div>
                        </div>
                    }
                </div>
            </div>
            
        </div>
    );
}

export default TimelineTransition;