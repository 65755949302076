import React from 'react';
import { Grid, Typography, TextField, IconButton } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ReactNode, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetch } from '../../../hooks/useFetch';
import { PROCESS_LIST_TO_CREATE_FORM_PATH } from '../../../constants/apiPaths';
import { ProcessToCreateForm } from '../../../interface/Form';
import { useSnackbar } from 'notistack';

function ListProcess({
  process,
  handleChange,
}: {
  process: number;
  handleChange: (event: SelectChangeEvent<string>) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState(process);
  const { companiesState } = useSelector((state: any) => state);
  const { selectedCompany } = companiesState;
  const dispatch = useDispatch();

  const handleCustomChange = (event: any) => {
    handleChange(event);
    setSelected(event.target.value);
  };

  useEffect(() => {}, [selected]);

  const { data: processList, loading } = useFetch<ProcessToCreateForm>(
    PROCESS_LIST_TO_CREATE_FORM_PATH(selectedCompany.id),
  );

  return (
    <Grid item>
      <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
        Process to Trigger
      </Typography>
      <Box  style={{marginTop: '10px', marginBottom: '10px'}}>
        <FormControl  fullWidth>
          <InputLabel
            id="demo-simple-select-label"
            
             
          >
            Process
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
           
            value={selected}
            label="Process"
            size="small"
            fullWidth
            onChange={handleCustomChange}
          >
           
            {!loading &&
              processList &&
              processList.results.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </Grid>
  );
}

export default ListProcess;
