import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';

const defCols: any = () => {
  const columns = [
    { field: 'id', headerName: 'ID', headerAlign: 'center', align: 'center', minWidth: 100 },
    {
      field: 'log',
      headerName: 'Log',
      flex: 1,
      minWidth: 200,
      renderCell: (cellValues: any) => {
        return (
          cellValues.row.log && (
            <a target="_blank" rel="noreferrer" href={cellValues.row.log}>
              {`log-${cellValues.row.id}.html`}
            </a>
          )
        );
      },
    },
    {
      field: 'report',
      headerName: 'Report',
      flex: 1,
      minWidth: 200,
      renderCell: (cellValues: any) => {
        return (
          cellValues.row.report && (
            <a target="_blank" rel="noreferrer" href={cellValues.row.report}>
              {`report-${cellValues.row.id}.html`}
            </a>
          )
        );
      },
    },
    { field: 'message', headerName: 'Message', flex: 1, minWidth: 200 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (cellValues: any) => {
        return <Chip label={cellValues.row.status} variant="outlined" />;
      },
    },
    { field: 'date_start', type: 'dateTime', headerName: 'Run Date', flex: 1, minWidth: 200 },

    // { field: 'actions', headerName: 'Actions', flex: 1, align: 'center', headerAlign: 'center' },
  ];
  return columns;
};

export default function LogsTable({ logs }: any) {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = React.useState(5);

  const handleClick = (params: any) => {
    navigate(`/transition/${params.id}`);
  };

  const cols = defCols();

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        rows={logs}
        columns={cols}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15]}
        autoHeight
        disableSelectionOnClick
        onRowClick={handleClick}
        sx={{
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        }}
      />
    </div>
  );
}
