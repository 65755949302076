import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Button, Grid, Typography, Select, MenuItem } from '@mui/material';

import { Workspace } from '../../interface/Workspace';
import { Dispatch } from 'react';

const helperTextStyles = {
  margin: '10px 0 0 0',
  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: '12px',
  lineHeight: '12px',
};

type Props = {
  modalClose: () => void;
  showModal: boolean;
  steps: StepRobot[] | null;
  step: number | null;
  fx: () => void;
  setStep: Dispatch<any>;
};

interface StepRobot {
  id: number;
  step_name: string;
}

function RobotDeployModal({ modalClose, showModal, fx, setStep, step, steps }: Props) {
  return (
    <>
      <Dialog
        open={showModal}
        onClose={() => modalClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">Select Step</DialogTitle>
        <DialogContent>
          <Grid item width="250px">
            <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
              Step
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              placeholder="Workspace"
              onChange={({ target }: any) => setStep(target.value)}
              size="small"
              value={step}
            >
              {steps &&
                Array.isArray(steps) &&
                steps?.map((step) => (
                  <MenuItem key={step.id} value={step.id}>
                    {' '}
                    {step.step_name}{' '}
                  </MenuItem>
                ))}
            </Select>
            <p style={helperTextStyles}>This is where the robot will run</p>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => modalClose()}>Cancel</Button>
          <Button onClick={() => fx()}>Continue</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RobotDeployModal;
