import { Card, CardContent, CircularProgress, Grid, Typography, Button } from '@mui/material';
import React, { useEffect } from 'react';
import StepsTable from '../../../../components/process/StepsTable';
import { Step } from '../../../../interface/Process';
import AddStepModal from '../../../../components/process/AddStepModal';
import { setAddStepDialogOpen } from '../../../../redux/processes/processesActions';
import { useDispatch, useSelector } from 'react-redux';

import { getProcessById } from '../../../../lib/apiClient';

import { StepsConfig } from '../../../../components/process/ProcessConfigTabs/StepsConfig';
import { useWebSocket } from '../../../../hooks/useWebSocket';

type Props = {
  steps: Step[];
  handleStepClick: Function;
  loading: boolean;
  error: boolean;
};

export const Steps = ({ steps, handleStepClick, loading, error }: Props) => {
  const { processesState, companiesState } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { process, addStepDialogOpen } = processesState;
  const { selectedCompany } = companiesState;
  const [newSteps, setSteps] = React.useState(steps);
  ///const { onMessage } = useWebSocket(`/ws/process/${process.id}/`);

  const handleOpenDialog = () => {
    dispatch(setAddStepDialogOpen());
  };

  const handleRefresh = () => {
    getProcessById(process.id).then((result) => setSteps(result.steps));
  };

  //onMessage(()=>handleRefresh())

  return (
    <Grid item xs={12} lg={12}>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            {/* ------------------------- row 1 ------------------------- */}
            <Grid item xs={12} container justifyContent="space-between">
              <Typography component="span" variant="h2">
                Steps
              </Typography>

              <Button variant="contained" color="primary" onClick={handleOpenDialog}>
                Add Step
              </Button>

              {addStepDialogOpen && (
                <AddStepModal
                  onClose={() => dispatch(setAddStepDialogOpen())}
                  companyId={selectedCompany?.id}
                  openDialog={addStepDialogOpen}
                  processId={process?.id}
                  refresh={handleRefresh}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {loading || error ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </div>
              ) : (
                //<StepsTable onStepSelect={handleStepClick} steps={newSteps} />
                <StepsConfig />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
