import React, { useState } from 'react';
import { Card, CardContent, Grid } from '@mui/material';

import { Breadcrumb } from '../../components/shared';
import PageContainer from '../../components/container/PageContainer';
import { TriggeredBy } from '../process/components/ProcessIntance/TriggeredBy';
import { TransitionDetails } from '../process/components/ProcessIntance/TransitionDetails';
import { Timeline } from '../process/components/ProcessIntance/Timeline';

export const ProcessInstanceDetail = ({ processInstanceId, processInstance }: any) => {
  const [log, setLog] = useState<string | null>(null);

  const handleClick = (params: any) => {
    setLog(params.row.log);
  };
  return (
    <Card style={{ backgroundColor: '#fafbfb' }}>
      <CardContent>
        <PageContainer title={`Process Run #${processInstanceId}`}>
          {/* breadcrumb */}
          {/* end breadcrumb */}
          <Breadcrumb title={` #${processInstanceId}`} subtitle="Process Run"></Breadcrumb>
          <Grid container spacing={1}>
            {/* ------------------------- TRIGGERED BY ------------------------- */}
            <TriggeredBy loading={false} error={false} processInstance={processInstance} />
            {/* ------------------------- DETAILS ------------------------- */}
            <TransitionDetails
              loading={false}
              error={false}
              processInstance={processInstance}
              onClick={handleClick}
            />
            {/* ------------------------- TIME LINE ------------------------- */}
            <Timeline loading={false} error={false} processInstance={processInstance!} />
            {log && (
              <Grid item xs={12} className="animate__animated animate__fadeIn">
                <Card>
                  <CardContent>
                    <iframe
                      title="iframe logs"
                      style={{ width: '100%', height: '700px', overflow: 'hidden', border: 0 }}
                      src={log}
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </PageContainer>
      </CardContent>
    </Card>
  );
};

export default ProcessInstanceDetail;
