import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import { Company } from '../../interface/Company';
import { getProcessById } from '../../lib/apiClient';
import { selectCompanyAction } from '../../redux/companies/companiesActions';

export function ProcessDetailCompanySelected({ children }: any) {
  const { selectedCompany, companies } = useSelector((state: any) => state.companiesState);
  const dispatch = useDispatch();
  const { processId } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectedCompany && processId) {
      setLoading(true);
      getProcessById(processId).then((process) => {
        const company = companies.find((c: Company) => c.id == process.empresa);
        dispatch(selectCompanyAction(company));
        setLoading(false);
      });
    }
  }, [companies]);

  if (loading || !selectedCompany) {
    return <></>;
  }
  return children;
}
