import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { Grid, Box, Typography } from '@mui/material';
import PageContainer from '../../components/container/PageContainer';
import { startLogin, startLoginWithGoogle } from '../../redux/auth/authActions';
import LogoScreen from './LogoScreen';
import CustomForm, { InputField } from '../../shared/form/CustomForm';
import { postCode, getUser } from '../../lib/apiClient';
import { baseUrlLet } from '../../constants/baseUrl';

import '../../styles/google-btn.css';

interface Session {
  accessToken?: string;
  id?: number;
}

const inputsField: InputField[] = [
  {
    label: 'Username',
    validators: [],
    name: 'username',
    type: 'email',
  },
  {
    label: 'Password',
    validators: [],
    name: 'password',
    helperText: 'Must contain at least 3 characters',
    type: 'password',
  },
];

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {

    const urlNoUncode = window.location.href;
    const regex = /[?&]code=([^&#]*)/i;
    const match = urlNoUncode.match(regex);
    const code = match ? match[1] : null;
    console.log(code);
    if (code) {
      const decode = decodeURIComponent(code);
      console.log(decode);
      const redirectUri = process.env.REACT_APP_MODE === 'staging'
        ? 'https://frontend.staging.rpamaker.com/auth/login'
        :  'https://app.rpamaker.com/auth/login';

      const body = {
        grant_type: 'authorization_code',
        code: decode,
        client_id: '',
        client_secret: '',
        //https://frontend.staging.rpamaker.com/auth/logi 
        redirect_uri: redirectUri

      };

      postCode(body).then(async (res: any) => {
        try {
          console.log(res);

          const accessToken: string = res.access_token as string;

          await dispatch(startLoginWithGoogle(accessToken, null, null));

          const user: any = await getUser();

          await dispatch(startLoginWithGoogle(accessToken, user.username, user.id));

          navigate('/');
          enqueueSnackbar('Succesfully logged in.', { variant: 'success' });
        } catch (error: any) {
          enqueueSnackbar(error.detail ?? 'something went wrong.', { variant: 'error' });
        }
      });
    }
  }, []);

  const handleLogin = async (form: any) => {
    const { username, password } = form;
    try {
      await dispatch(startLogin(username, password));
      navigate('/');
      enqueueSnackbar('Succesfully logged in.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Email or Password is incorrect.', { variant: 'error' });
    }
  };

  const handleGoogleLogin = async () => {
    const redirectUri = process.env.REACT_APP_MODE === 'staging'
        ? 'https://frontend.staging.rpamaker.com/auth/login'
        :  'https://app.rpamaker.com/auth/login';

    window.location.href = `${baseUrlLet}/accounts/oauth/?client_id=1&redirect_uri=${redirectUri}`;
  };

  return (
    <PageContainer title="Login" description="this is Login page">
      <Grid container spacing={0} sx={{ height: '100vh', justifyContent: 'center' }}>
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          sx={{
            background: (theme) => `${theme.palette.mode === 'dark' ? '#1c1f25' : '#ffffff'}`,
          }}
        >
          <LogoScreen />
        </Grid>
        <Grid item xs={12} sm={8} lg={6} display="flex" alignItems="center">
          <Grid container spacing={0} display="flex" justifyContent="center">
            <Grid item xs={12} lg={9} xl={6}>
              <Box
                sx={{
                  p: 4,
                }}
              >
                <CustomForm
                  inputsField={inputsField}
                  endpoint={handleLogin}
                  buttonLabel="Sign In"
                  titleForm="Welcome RPAMAKER"
                />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginTop: '20px',
                  }}
                >
                  <button
                    type="button"
                    onClick={handleGoogleLogin}
                    className="login-with-google-btn"
                  >
                    Sign in with Google
                  </button>
                  <Box>
                    <Typography
                      component={Link}
                      to="/auth/register"
                      fontWeight="500"
                      sx={{
                        display: 'block',
                        textDecoration: 'none',
                        color: 'primary.main',
                      }}
                    >
                      Register
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      component={Link}
                      to="/auth/password-reset"
                      fontWeight="500"
                      sx={{
                        display: 'block',
                        textDecoration: 'none',
                        mb: '16px',
                        color: 'primary.main',
                      }}
                    >
                      Forgot Password ?
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Login;
