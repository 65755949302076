import { Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';
import { ProcessInstanceTimeline } from '../../../../components/process/ProcessInstanceTimeline';
import { ProcessInstance } from '../../../../interface/ProcessInstance';

type Props = {
  processInstance: ProcessInstance;
  loading: boolean;
  error: boolean;
};

export const Timeline = ({ processInstance, loading, error }: Props) => (
  <Grid item xs={6} lg={4}>
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          {/* ------------------------- row 1 ------------------------- */}
          <Grid item lg={12}>
            <Typography component="span" variant="h2">
              Timeline
            </Typography>
            {!loading || !error ? (
              <ProcessInstanceTimeline pi={processInstance} />
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                  width: '100%',
                }}
              >
                <CircularProgress className="animate__animated animate__fadeIn" />
              </div>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);
