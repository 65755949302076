import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { w3cwebsocket } from 'websocket';
import PageContainer from '../../components/container/PageContainer';
import AddNewRobot from '../../components/process/AddNewRobot';
import { Breadcrumb } from '../../components/shared';
import { ROBOT_DEPLOY_PATH, ROBOT_DEPLOY_LIST_PATH } from '../../constants/apiPaths';
import { baseWSLet } from '../../constants/baseUrl';
import { useFetch } from '../../hooks/useFetch';
import { Robot } from '../../interface/Robot';
import { deployRobot } from '../../lib/apiClient';
import { setRobotSelected } from '../../redux/robot/robotActions';
import RobotHistorialTable from './RobotHistorialTable';
import { getWorkspaceById } from '../../lib/apiClient';
import RobotDeployModal from '../../components/robot/RobotDeployModal';

import { Workspace } from '../../interface/Workspace';
import { Step } from '../../interface/Process';

interface StepRobot {
  id: number;
  step_name: string;
}

export const RobotDetails = () => {
  const { robot, companiesState } = useSelector((state: any) => state);
  const { robotSelected } = robot;
  const { selectedCompany } = companiesState;
  const [open, setOpen] = useState(false);
  const [robotChanged, setRobotChanged] = useState<null | Robot>(null);
  const [loading, setLoading] = useState(false);

  const [deployOpen, setDeployOpen] = useState<boolean>(false);

  const [selectedStep, setSelectedStep] = useState<number>(-1);
  const { data, refresh } = useFetch<any>(ROBOT_DEPLOY_PATH(robotSelected?.id), []);

  //console.log(data);

  const { data: listRobotHistory, refresh: tableRefresh } = useFetch<any>(
    ROBOT_DEPLOY_LIST_PATH(robotSelected?.id),
    [],
  );

  const {
    data: steps,
    loading: loadingSteps,
    error: errorStep,
    refresh: refreshStep,
  } = useFetch<StepRobot[]>(`/frontend/v1/robot/step/${robotSelected?.id}/`);

  const dispatch = useDispatch();

  useEffect(() => {
    let newClient: any = null;
    if (listRobotHistory !== null && listRobotHistory.length > 0) {
      newClient = new w3cwebsocket(`${baseWSLet}/ws/deployment/${listRobotHistory[0].id}/`);
      newClient.onopen = () =>
        console.log(`WebSocket Client Connected: /ws/deployment/${listRobotHistory[0].id}/`);
      newClient.onclose = () =>
        console.log(`WebSocket Client Closed: /ws/deployment/${listRobotHistory[0].id}/`);
      newClient.onmessage = () => {
        refresh();
        tableRefresh();
      };
    }

    return () => {
      if (newClient) {
        newClient.close();
      }
    };
  }, [listRobotHistory]);

  useEffect(() => {
    if (robotChanged && robotSelected) {
      dispatch(setRobotSelected(robotChanged));
    }
    return () => {
      dispatch(setRobotSelected(null));
    };
  }, [robotChanged]);

  const handleDeploy = async () => {
    try {
      setLoading(true);
      await deployRobot({ id: robotSelected.id, step_id: selectedStep });
      refresh();
      tableRefresh();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function handleContinue() {
    console.log(robotSelected.id, selectedStep);
    handleDeploy();
    setDeployOpen(false);
  }

  return (
    <>
      <PageContainer title="Robot Detail" description="this is robot detail">
        <Breadcrumb title={robotSelected?.nombre} subtitle="Welcome User">
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            onClick={() => {
              setDeployOpen(true);
            }}
            style={{ marginRight: '20px' }}
          >
            Deploy
          </LoadingButton>
          <LoadingButton variant="contained" color="primary" onClick={() => setOpen(true)}>
            Edit Robot
          </LoadingButton>
        </Breadcrumb>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <List>
                  <ListItem divider style={{ display: 'flex' }}>
                    <span style={{ width: '20%' }}>Nombre:</span>
                    <Typography>{robotSelected?.nombre || ''}</Typography>
                  </ListItem>

                  <ListItem divider style={{ display: 'flex' }}>
                    <span style={{ width: '20%' }}>Filename:</span>
                    <Typography>{robotSelected?.keyword || ''}</Typography>
                  </ListItem>
                  <ListItem divider style={{ display: 'flex' }}>
                    <span style={{ width: '20%' }}>Docker URL:</span>
                    <Typography>{robotSelected?.docker_url || ''}</Typography>
                  </ListItem>
                  <ListItem divider style={{ display: 'flex' }}>
                    <span style={{ width: '20%' }}>Git URL:</span>
                    <Typography>{robotSelected?.git_url || ''}</Typography>
                  </ListItem>
                  <ListItem divider style={{ display: 'flex' }}>
                    <span style={{ width: '20%' }}>Status:</span>
                    <Typography>{data?.status || ''}</Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <RobotHistorialTable data={listRobotHistory} />
          </CardContent>
        </Card>
      </PageContainer>
      <AddNewRobot
        onClose={() => setOpen(false)}
        isOpen={open}
        companyId={selectedCompany.id}
        robot={robotSelected}
        setRobotChanged={setRobotChanged}
        isEdit={true}
      />
      <RobotDeployModal
        showModal={deployOpen}
        modalClose={() => setDeployOpen(false)}
        steps={steps}
        fx={handleContinue}
        setStep={setSelectedStep}
        step={selectedStep}
      />
    </>
  );
};
