/* eslint no-underscore-dangle: ["error", { "allow": ["_value"] }] */

export class Storage {
  static set<T>(key: string, value: T) {
    const _value = JSON.stringify(value);
    localStorage.setItem(key, _value);
  }

  static get<T>(key: string, _default?: T): T | undefined {
    const _value = localStorage.getItem(key);
    try {
      return _value === null ? _default : (JSON.parse(_value) as T);
    } catch (error) {
      return undefined;
    }
  }

  static assign(key: string, props: any) {
    const existing = this.get(key, {});
    this.set(key, Object.assign(existing ? existing: {}, props));
  }

  static remove(key: string) {
    localStorage.removeItem(key);
  }
}
