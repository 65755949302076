import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import CustomModal from '../../../shared/modal/CustomModal'
import { Box } from '@mui/system'
import { LoadingButton } from '@mui/lab'
import RequestBillingInfo from '../../../components/request-billing-info/RequestBillingInfo'
import { checkErrors } from '../../../components/request-billing-info/billingHelper'
import { createBillingInfo } from '../../../lib/apiClient'
import { useSnackbar } from 'notistack'

interface FormValues {
  [key: string]: string,
  country: string,
  name: string,
  addres: string,
  city: string,
  state: string,
  zip_code: string,
  mail: string
}

interface FormValuesError {
  [key: string]: boolean,
  country: boolean,
  name: boolean,
  addres: boolean,
  city: boolean,
  state: boolean,
  zip_code: boolean,
  mail: boolean
}

const BillingInfo = ({ isOpen, setIsOpen }: any) => {

  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useSelector((state: any) => state.companiesState);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    country: '',
    name: '',
    addres: '',
    city: '',
    state: '',
    zip_code: '',
    mail: ''
  });
  const [formValuesError, setFormValuesError] = useState<FormValuesError>({
    country: false,
    name: false,
    addres: false,
    city: false,
    state: false,
    zip_code: false,
    mail: false
  });
  const [cardData, setCard] = useState<any>(undefined);
  const [generalError, setGeneralError] = useState(false);
  
  const setCardData = (v: any) => {
    setGeneralError(false);
    setCard(v);
  }

  const handleSave = async () => {
    const error = checkErrors(formValues, setFormValuesError, cardData, setGeneralError);
    if(error){
      return; 
    }
    setLoading(true);
    const { name, mail, addres, city, state, zip_code, country } = formValues;
    const body = {
      company: selectedCompany?.id,
      pan: cardData.cardNumber,
      cvc: cardData.cvc,
      exp: `${cardData.date.moth}/${cardData.date.year}`,
      name,
      mail,
      addres,
      city,
      state,
      zip_code,
      country
    }
    try {
      await createBillingInfo(body);
      enqueueSnackbar('Congrats', {
        preventDuplicate: true,
        variant: 'success'
    });
    } catch (error) {
      enqueueSnackbar('error', {
        preventDuplicate: true,
        variant: 'error'
    });
    } finally {
      setLoading(false);
      setIsOpen(!isOpen);
    }
  }

  return (
    <CustomModal
          isOpen={isOpen}
          onClose={() => setIsOpen(!isOpen)} 
          title={'Add billing details'} 
          size={'md'}
    >
        <RequestBillingInfo 
          cardData={cardData} 
          setCardData={setCardData} 
          formValues={formValues} 
          setFormValues={setFormValues} 
          formValuesError={formValuesError} 
          setFormValuesError={setFormValuesError} 
          setGeneralError={setGeneralError}          
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px'
          }}
        >
          <LoadingButton
            variant='contained'
            sx={{
              width: '250px'
            }}
            onClick={handleSave}
            disabled={generalError}
            type='button'
            loading={loading}
          >
            Save
          </LoadingButton>
        </Box>
    </CustomModal>
  )
}

export default BillingInfo