import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Skeleton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb } from '../../components/shared';
import PageContainer from '../../components/container/PageContainer';
import AddNewWorkspace from '../../components/process/AddNewWorkspace';
import WorkspaceTable from '../../components/workspace/WorkspaceTable';
import { firstListAction } from '../../redux/breadcrumb-general/breadCrumbsActions';
import { useFetch } from '../../hooks/useFetch';
import { WORKSPACE_LIST_PATH } from '../../constants/apiPaths';

export const WorkspaceList = () => {
  const { companiesState } = useSelector((state: any) => state);
  const { selectedCompany } = companiesState;
  const dispatch = useDispatch();
  const {
    data: workspaces,
    loading,
    error,
    refresh: refreshWorkspaces,
  } = useFetch(WORKSPACE_LIST_PATH(selectedCompany?.id), []);

  const [openDialogWorkspace, setOpenDialogWorkspace] = React.useState(false);
  const [workspace, setWorkspace] = React.useState<number>(-1);


  useEffect(() => {
    dispatch(firstListAction(selectedCompany, 'Workspace'));
  }, []);

  useEffect(() => {
    refreshWorkspaces();
  }, [selectedCompany]);

  return (
    selectedCompany && (
      <>
        <PageContainer title="Workspace List" description="this is workspace page">
          {/* breadcrumb */}
          <Breadcrumb title="Workspace List" subtitle="Welcome User">
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => setOpenDialogWorkspace(!openDialogWorkspace)}
            >
              New Workspace
            </LoadingButton>
          </Breadcrumb>
          <Card>
            <CardContent>
              <Grid container>
                {/* ------------------------- row 1 ------------------------- */}
                <Grid item xs={12} lg={12}>
                  {loading || error ? (
                    <Skeleton variant="rectangular" width="100%" height={400} />
                  ) : (
                    <WorkspaceTable workspaces={workspaces} company={selectedCompany} />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </PageContainer>
        <AddNewWorkspace
          onClose={() => setOpenDialogWorkspace(!openDialogWorkspace)}
          isOpen={openDialogWorkspace}
          companyId={selectedCompany.id}
          refresh={refreshWorkspaces}
          setWorkspace={setWorkspace}
        />
      </>
    )
  );
};

export default WorkspaceList;
