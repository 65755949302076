import { Company } from '../../interface/Company';
import { FIRST_OPTION, ROBOT_DETAIL_BREADCRUMB, FORM_DETAIL_BREADCRUMB } from './types';

export const firstListAction = (selectedCompany: Company, option: string) => ({
  type: FIRST_OPTION,
  payload: {
    selectedCompany,
    option,
  },
});

export const setRobotDetailBreadcrumb = (
  selectedCompany: Company,
  option: string,
  robotName: string,
  id: any,
) => ({
  type: ROBOT_DETAIL_BREADCRUMB,
  payload: {
    selectedCompany,
    option,
    robotName,
    id,
  },
});

export const setFormDetailBreadcrumb = (
  selectedCompany: Company,
  option: string,
  formName: string,
  id: any,
) => ({
  type: FORM_DETAIL_BREADCRUMB,
  payload: {
    selectedCompany,
    option,
    formName,
    id,
  },
});
