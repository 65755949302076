import { Breakpoint, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import FeatherIcon from '../../components/feather-icon/icon';
import React from 'react';

type TProps = { 
    children?: any, 
    isOpen: boolean,
    title: string, 
    onClose: any;
    size: any;
}

const CustomModal = ({ children, isOpen, title, onClose, size }: TProps) => {

  return (
    
    <Dialog fullWidth maxWidth={size} open={isOpen} onClose={onClose}>
        <DialogTitle sx={{ mt: 2, ml: 3 }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h4" sx={{ mb: 2  }}>
                { title }
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose}>
                <FeatherIcon fontSize="small" icon="x" width="16" height="16" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ ml: 3 }}>
            {children}
        </DialogContent>
      </Dialog>
  )
}

export default CustomModal