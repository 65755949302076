import { HistoryBreadCrumbs } from '../components/shared';
import { WorkspaceDetails } from '../views/workspace/WorkspaceDetails';
import WorkspaceList from '../views/workspace/WorkspaceList';
import { HasCompany } from './guards/HasCompany';
import { RequireAuth } from './guards/RequireAuth';
import { FullLayout } from './lazyload/FullLayout';

export const workspaceRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      {
        path: '/workspaces',
        element: (
          <RequireAuth>
            <HasCompany>
              <HistoryBreadCrumbs option="breadcrumbsGeneral" />
              <WorkspaceList />
            </HasCompany>
          </RequireAuth>
        ),
      },
      {
        path: '/workspaces/:id',
        element: (
          <RequireAuth>
            <HasCompany>
              <HistoryBreadCrumbs option="breadcrumbsGeneral" />
              <WorkspaceDetails />
            </HasCompany>
          </RequireAuth>
        ),
      },
    ],
  },
];
