import { FormControlLabel, Grid, IconButton, Switch, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useSelector } from 'react-redux';
import { updateProcess } from '../../../lib/apiClient';
import CancelAndSave from '../../shared/cancel-save/CancelAndSave';
import FeatherIcon from '../../feather-icon/icon';

const ProcessSetting = ({ onClose }: any) => {
  
  const { enqueueSnackbar } = useSnackbar();
  const { process } = useSelector((state: any) => state.processesState);
  const [loading, setLoading] = React.useState(false);

  const {
    id,
    mail: mailArray,
    etiqueta: etiquetaProcess,
    name: nameProcess,
    enable,
    console: consoleProp,
    notify,
    pushbullet_token: pushbulletTokenProcess,
    slack_mail: slackMailProcess,
  } = process;

  const [formValues, setFormValues] = React.useState({
    name: nameProcess,
    mail: mailArray,
    etiqueta: etiquetaProcess,
    console: consoleProp,
    enable,
    id,
    notify,
    pushbullet_token: pushbulletTokenProcess,
    slack_mail: slackMailProcess,
  });

  const { name, mail,pushbullet_token, slack_mail, etiqueta, console: _console, notify: _notify } = formValues;

  const [arrMail, setArrMail] = React.useState(
    formValues.mail.length > 0 ? [...formValues.mail] : [''],
  );


  const handleChange = ({ target }: any) => {
    if (target.name === 'console') {
      return setFormValues({
        ...formValues,
        console: !_console,
      });
    }
    if (target.name === 'notify') {
      return setFormValues({
        ...formValues,
        notify: !_notify,
      });
    }
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
    
  };

  const cleanArrayMail = (arrayEmails: string[]) => {
    return arrayEmails.filter((value) => !(value.trim().length === 0));
  };

  const handleChangeMail = ({ target }: any, index: number) => {
    const auxArr = [...arrMail];
    auxArr[index] = target.value;
    setArrMail(auxArr);
    setFormValues({ ...formValues, mail: cleanArrayMail(auxArr) });
  };

  const handleSave = async () => {
    await setLoading(false);
    try {
      await updateProcess(formValues);
      enqueueSnackbar('Process update successfully', {
        preventDuplicate: true,
      });
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Error, try again later', {
        variant: 'error',
      });
    }
  };

  const addEmailInput = () => {
    setArrMail([...arrMail, '']);
  };

  const removeEmailInput = (value: string, index: number) => {
    const newArray = arrMail.filter((v, i) => !(index === i && value === v));
    setArrMail(newArray);
  };

  return (
    <Grid container style={{ marginTop: '30px' }}>
      <Grid item lg={3} xs={10} md={6}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
              Name
            </Typography>
            <TextField
              variant="outlined"
              name="name"
              value={name}
              onChange={handleChange}
              size="small"
            />
          </Grid>
          <Grid item>
            <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
              Slack Channel Integration
            </Typography>
            <TextField
              variant="outlined"
              name="slack_mail"
              value={slack_mail}
              onChange={handleChange}
              size="small"
            />
          </Grid>
          <Grid item>
            <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
              PagerBullet Integration Token
            </Typography>
            <TextField
              variant="outlined"
              name="pushbullet_token"
              value={pushbullet_token}
              onChange={handleChange}
              size="small"
            />
          </Grid>
          <Grid item>
            <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
              Tag
            </Typography>
            <TextField
              variant="outlined"
              onChange={handleChange}
              name="etiqueta"
              value={etiqueta}
              size="small"
            />
          </Grid>
          <Grid item>
            <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
              Mail Alerts
            </Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TextField
                variant="outlined"
                name="mail-0"
                value={arrMail[0]}
                onChange={(e: any) => handleChangeMail(e, 0)}
                size="small"
              />
              <IconButton onClick={addEmailInput} size="large">
                <FeatherIcon icon="plus-circle" />
              </IconButton>
            </div>
            {arrMail.slice(1).map((value, index) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                }}
              >
                <TextField
                  variant="outlined"
                  key={index}
                  name={`mail-${index}`}
                  value={value}
                  onChange={(e: any) => handleChangeMail(e, index + 1)}
                  size="small"
                  sx={{
                    display: 'block',
                  }}
                />
                <IconButton onClick={() => removeEmailInput(value, index + 1)} size="large">
                  <FeatherIcon icon="trash" />
                </IconButton>
              </div>
            ))}
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={_console}
                  onChange={handleChange}
                  name="console"
                  inputProps={{ 'aria-label': 'controlled' }}
                  value={_console}
                />
              }
              label="Console"
              sx={{ display: 'block' }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={_notify}
                  onChange={handleChange}
                  name="notify"
                  inputProps={{ 'aria-label': 'controlled' }}
                  value={_notify}
                />
              }
              label="Notify"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: '50px' }}>
        <CancelAndSave handleSave={handleSave} handleCancel={onClose} loading={loading} />
      </Grid>
    </Grid>
  );
};

export default ProcessSetting;
