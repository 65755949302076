
import React, { Fragment, useEffect, useState, useCallback, createContext } from 'react';
import CVCInput from './CVCInput';
import DateInput from './DateInput';
import CardNumberInput from './CardNumberInput';
import { Box, Grid } from '@mui/material';


export const CreditCardDataContext = createContext(null);

export const CardProvider = ({ children }) => {
  const [cardData, setCardData] = useState(undefined);

  return (
    <CreditCardDataContext.Provider value={{ cardData, setCardData }}>
      {children}
    </CreditCardDataContext.Provider>
  );
};

const CreditCardInput = ({cardData, setCardData}) => {
  const [focusIndex, setFocusIndex] = useState([]);
  

  const leaveField = (index) => {
    const indexArray = new Array(3).fill(false);
    indexArray[index] = true;
    setFocusIndex(indexArray);;
  }

  const setFocusOnFirst = useCallback(
    () => {
      leaveField(0);
    },
    [],
  )

  useEffect(() => {
    leaveField(0);
  }, []);

  useEffect(() => {
    
    
  }, [setFocusOnFirst]);

  return (
    <CreditCardDataContext.Provider value={{ cardData, setCardData }}>
      <Fragment>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
        >
          <Grid item xs={12}>
            <CardNumberInput
              leaveFieldCallback={leaveField}
              focus={focusIndex[0]}
              tabIndex={0}
            />
          </Grid>
          <Grid item xs={5.8}>
            <DateInput
              leaveFieldCallback={leaveField}
              focus={focusIndex[1]}
              tabIndex={1}
            />
          </Grid>
          <Grid item xs={5.8}>
            <CVCInput
              focus={focusIndex[2]}
              tabIndex={2}
            />
          </Grid>
        </Grid>
      </Fragment>
    </CreditCardDataContext.Provider>
  )
}

export default CreditCardInput;