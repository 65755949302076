import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import { darken, lighten } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { getProcessById, getProcesses, runProcess } from '../../lib/apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { getProcessByIdAction, getProcessesStart } from '../../redux/processes/processesActions';
import { processDetailAction } from '../../redux/brearcrumbs-processes/breadCrumbsActions';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteProcess } from '../../lib/apiClient';
import DeleteModal from './DeleteModal';

const defCols = (clickAction, clickDelete) => {
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'run',
      headerName: 'Run',
      align: 'center',
      headerAlign: 'center',
      renderCell: (cellValues) => {
        return (
          <IconButton
            onClick={(event) => {
              clickAction(event, cellValues);
            }}
            disabled={!cellValues.row.enable}
          >
            <PlayCircleFilledRoundedIcon
              variant="contained"
              color={cellValues.row.enable ? 'primary' : 'disabled'}
              fontSize="large"
            />
          </IconButton>
        );
      },
    },
    { field: 'name', headerName: 'Name', minWidth: 150, flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      renderCell: (cellValues) => {
        return <Chip label={cellValues.row.status} variant="outlined" />;
      },
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'status_result',
      headerName: 'Step Status',
      align: 'center',
      headerAlign: 'center',
      renderCell: (cellValues) => {
        return (
          <Chip
            color={cellValues.row.status_result === 'FAILURE' ? 'error' : 'default'}
            label={cellValues.row.status_result}
            variant="outlined"
          />
        );
      },
      minWidth: 120,
      flex: 1,
    },
    { field: 'last_run', type: 'dateTime', headerName: 'Last Run', minWidth: 160, flex: 1 },
    { field: 'next_run', type: 'dateTime', headerName: 'Next Run', minWidth: 160, flex: 1 },
    { field: 'enable', headerName: 'Enabled', hide: true },
    {
      field: 'delete',
      flex:1,
      headerName: 'Delete',
      align: 'start',
      headerAlign: 'start',
      renderCell: (cellValues) => {
        return (
          <IconButton
            onClick={(event) => {
              clickDelete(event, cellValues);
            }}
          >
            <DeleteIcon style={{ color: '#FE2E2E' }}></DeleteIcon>
          </IconButton>
        );
      },
    },
    // { field: 'actions', headerName: 'Actions', flex: 1, align: 'center', headerAlign: 'center' },
  ];
  return columns;
};

export default function ProcessTable({ refresh ,processes }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { companiesState, processesState } = useSelector((state) => state);
  const { selectedCompany } = companiesState;
  const { process: processRedux } = processesState;
  const { enqueueSnackbar } = useSnackbar();
  const [pageSize, setPageSize] = React.useState(15);
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');
  const [nameProcess, setNameProcess] = React.useState('');

  const handleRunProcess = async (event, cellValues) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      const response = await runProcess(cellValues.id);
      dispatch(processDetailAction(selectedCompany.name, cellValues.row.name, cellValues.id));

      const instanceId = response.instance_id;
      
      if(!instanceId) {
        enqueueSnackbar(`Failed to run process ${response.Error}`, { variant: 'error' });
    
        return;
      }
      
      enqueueSnackbar(`Successfully to run process ${cellValues.id} ${cellValues.row.name}`, {
        variant: 'success',
      });
      navigate(`/instance/${instanceId}`);
    } catch (error) {
      enqueueSnackbar(`Failed to run process ${cellValues.id} ${cellValues.row.name}`, {
        variant: 'error',
      });
    }
  };

  const handleTrashIcon = async (event, cellValues) => {
    event.stopPropagation();
    event.preventDefault();
    setDeleteId(cellValues.row.id);
    setNameProcess(cellValues.row.name);
    setOpen(true);

    return;
  };

  const handleDeleteProcess = async () => {
    try {
      const data = await deleteProcess(deleteId);
      console.log('delete successful', data);
      refresh()
    } catch {
      console.log('error');
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = async (params) => {
    dispatch(getProcessByIdAction(params.row));
    navigate(`/process/${params.id}`);
  };

  const cols = defCols(handleRunProcess, handleTrashIcon);

  const getBackgroundColor = (color, mode) => {
    return mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
  };
  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  return (
    <>
      <div style={{ width: '100%' }}>
        <DataGrid
          className="animate__animated animate__fadeIn"
          initialState={{
            sorting: {
              sortModel: [{ field: 'enable', sort: 'desc' }],
            },
          }}
          rows={processes}
          columns={cols}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15]}
          autoHeight
          disableMultipleSelection
          disableSelectionOnClick
          onRowClick={handleClick}
          getRowClassName={(params) => {
            return params.row.enable ? 'row-custom-enabled' : 'row-custom-disabled';
          }}
          sx={{
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .row-custom-disabled': {
              bgcolor: (theme) => getBackgroundColor('#e0e0e0', theme.palette.mode),
              '&:hover': {
                bgcolor: (theme) => getHoverBackgroundColor('#e0e0e0', theme.palette.mode),
              },
            },
          }}
        />
      </div>

      <DeleteModal
        modalClose={handleClose}
        showDeleteModal={open}
        id={deleteId}
        name={nameProcess}
        action={'Process'}
        fxDelete={handleDeleteProcess}
      />
    </>
  );
}
