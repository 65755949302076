import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Grid } from '@mui/material';
import PageContainer from '../../components/container/PageContainer';
import SendEmail from './SendEmail';
import { resetEmail } from '../../lib/apiClient';
import ChangePassword from './ChangePassword';
import LogoScreen from './LogoScreen';



export const ForgotPassword = () => {

  const [showReset , setShowReset] = useState(false);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [token, setToken] = useState('');

  useEffect(() => {
    try {
      if(location.search){
        const token = location.search.split('?token=')[1];
        if(token){
          setShowReset( () => {
            setToken(token);
            return true;
          });
        }
      }
      
    } catch (error) {
      
    }
   
  }, []);
  

  const handleSendEmail = async (data: any) => {
    try {
      await resetEmail({ email: data.email });
      enqueueSnackbar('Check your email inbox', {
        preventDuplicate: true,
        autoHideDuration: 4000
      });
    } catch (error) {
      enqueueSnackbar("We couldn't find an account associated with that email. Please try a different e-mail address.", {
        variant: 'error',
        autoHideDuration: 4000
      });
    }
  }

  return (
    <PageContainer title="Forgot Password" description="this is forgot password page">
      <Grid container spacing={0} sx={{ height: '100vh', justifyContent: 'center' }}>
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          sx={{
            background: (theme) => `${theme.palette.mode === 'dark' ? '#1c1f25' : '#ffffff'}`,
          }}
        >
          <LogoScreen />
        </Grid>
        {
          !showReset 
          ?
          <SendEmail 
            onClick={handleSendEmail}
          />
          :
          <ChangePassword token={token} />
        }      
      </Grid>
    </PageContainer>
  );
};

export default ForgotPassword;
