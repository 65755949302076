import { Storage } from '../../lib/utils';
import {
  PROCESS_DETAIL,
  PROCESS_LIST,
  PROCESS_INSTANCE,
  CLEAN,
  TRANSITION_DETAIL,
  ActionType,
} from './types';

type BreadcrumbsItem = {
  title: string;
  to?: string;
};

const initialState = Storage.get<BreadcrumbsItem[]>('breadcrumbs') || [];

const processListItem = (companyName: string) => [
  {
    title: companyName,
  },
  {
    title: 'Process List',
    to: '/processes',
  },
];

export const breadCrumbsReducer = (
  state = initialState,
  action: { type: ActionType; payload: any },
) => {
  let newState = state;
  switch (action.type) {
    case PROCESS_LIST:
      newState = processListItem(action.payload.name);
      break;
    case PROCESS_DETAIL:
      newState = [
        ...processListItem(action.payload.companyName),
        {
          title: action.payload.processName,
          to: `/process/${action.payload.processId}`,
        },
      ];
      break;
    case PROCESS_INSTANCE:
      if (!newState[3] || newState[3]?.title !== `Run: #${action.payload}` || newState[4]) {
        newState = [
          newState[0],
          newState[1],
          newState[2],
          {
            title: `Run: #${action.payload}`,
            to: `/instance/${action.payload}`,
          },
        ];
      }
      break;
    case TRANSITION_DETAIL:
      const exist = newState.find((value) => value?.title.includes('Transition'));
      if (!exist) {
        newState = [
          ...newState,
          {
            title: `Transition: #${action.payload}`,
            to: `/transition/${action.payload}`,
          },
        ];
      }

      break;
    case CLEAN:
      newState = [];
      break;
    default:
  }
  Storage.set<BreadcrumbsItem[]>('breadcrumbs', newState);
  return newState;
};
