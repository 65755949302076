import { Company } from '../../interface/Company';
import { Process } from '../../interface/Process';
import { getProcessById, getProcesses } from '../../lib/apiClient';
import { Storage } from '../../lib/utils';
import {
  LOADING,
  CLEAN_PROCESS,
  SET_CONFIG_DIALOG_OPEN,
  GET_PROCESSES,
  GET_PROCESS_BY_ID,
  SET_ADD_STEP_DIALOG,
} from './types';

export const loading = () => ({ type: LOADING });

export const cleanProcess = () => {
  return { type: CLEAN_PROCESS };
};
export const setConfigDialogOpen = () => ({ type: SET_CONFIG_DIALOG_OPEN });
export const setAddStepDialogOpen = () => ({ type: SET_ADD_STEP_DIALOG });

export const getProcessesAction = (processes: Process[]) => ({
  type: GET_PROCESSES,
  payload: processes,
});

export const getProcessByIdAction = (process: Process) => {
  Storage.set('process', process);
  return {
    type: GET_PROCESS_BY_ID,
    payload: process,
  };
};

export const getProcessesStart = (company: Company) => {
  return async (dispatch: any) => {
    dispatch(loading());
    const result = await getProcesses(company);
    dispatch(getProcessesAction(result));
  };
};

export const getProcessesByIdStart = (companyId: string, processId: string) => {
  return async (dispatch: any) => {
    dispatch(loading());
    const resultProcess = await getProcessById(processId);
    Storage.set('process', resultProcess);
    dispatch(getProcessByIdAction(resultProcess));
  };
};
