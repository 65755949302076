let baseUrlLet = '';
let baseWSLet = '';
const currentUrl = window.location.host;

switch (currentUrl) {
  case 'localhost:3000':
    console.log('Dev environment');
    //baseUrlLet = "http://127.0.0.1:8000"
    //baseWSLet = "wss://127.0.0.1:8000"

    baseUrlLet = 'https://backend.staging.rpamaker.com';
    // baseUrlLet = 'http://localhost:8000';
    baseWSLet = 'wss://backend.staging.rpamaker.com';

    break;
  case '127.0.0.1:3000':
    console.log('Dev environment');
    // baseUrlLet = "http://127.0.0.1:8000"
    // baseWSLet = "wss://127.0.0.1:8000"

    baseUrlLet = 'https://backend.staging.rpamaker.com';
    // baseUrlLet = 'http://localhost:8000';
    baseWSLet = 'wss://backend.staging.rpamaker.com';

    break;

  case 'frontend.staging.rpamaker.com':
    console.log('Staging environment');

    baseUrlLet = 'https://backend.staging.rpamaker.com';
    baseWSLet = 'wss://backend.staging.rpamaker.com';
    break;
  case 'app.rpamaker.com':
    console.log('Production environment');
    baseUrlLet = 'https://orquestador.rpamaker.com';
    baseWSLet = 'wss://orquestador.rpamaker.com';
    break;
  default:
    break;
}

export { baseUrlLet, baseWSLet };
