import { HistoryBreadCrumbs } from '../components/shared';
import { FormDetail } from '../views/form/FormDetail';
import { FormList } from '../views/form/FormList';
import { HasCompany } from './guards/HasCompany';
import { RequireAuth } from './guards/RequireAuth';
import { FullLayout } from './lazyload/FullLayout';

export const formsRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      {
        path: '/forms',
        element: (
          <RequireAuth>
            <HasCompany>
              <HistoryBreadCrumbs option="breadcrumbsGeneral" />
              <FormList />
            </HasCompany>
          </RequireAuth>
        ),
      },
      
    ],
  },
];
