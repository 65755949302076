import { Validators } from "../../validators";


export const checkErrors = (formValues: any, setFormValuesError: any, cardData: any, setGeneralError: any ) => {
    let thereAreError = false;
    let errors: any = {
      country: false,
      name: false,
      addres: false,
      city: false,
      state: false,
      zip_code: false,
      mail: false
    }
    for (const key in formValues) {
      if( !Validators.isRequired(formValues[key]) ){
        errors[key] = true;
        thereAreError = true;
      }
    }
    setFormValuesError(errors);
    if(!cardData){
      thereAreError = true;
    }
    if(cardData){
      const isValidCardData = !cardData?.cardNumber || !cardData?.date || !cardData?.cvc;
      if(isValidCardData){
        thereAreError = true;
      }
    }
    setGeneralError(thereAreError);
    return thereAreError;
}