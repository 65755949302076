import React from 'react';
import { Box } from '@mui/system'
import img1 from '../../assets/images/backgrounds/login-bg.svg';
import LogoIcon from '../../layouts/full-layout/logo/LogoIcon';

const LogoScreen = () => (
    <Box
        sx={{
            position: 'relative',
        }}
    >
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
                position: {
                    xs: 'relative',
                    lg: 'absolute',
                },
                height: { xs: 'auto', lg: '100vh' },
                right: { xs: 'auto', lg: '-50px' },
                margin: '0 auto',
            }}
        >
            <img
                src={img1}
                alt="bg"
                style={{
                    width: '100%',
                    maxWidth: '812px',
                }}
            />
        </Box>

        <Box
            sx={{
                p: 4,
                position: 'absolute',
                top: '0',
            }}
        >
            <LogoIcon />
        </Box>
    </Box>
)

export default LogoScreen