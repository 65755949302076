import { CircularProgress, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import PageContainer from '../../components/container/PageContainer';
import { signUpValidateToken } from '../../lib/apiClient';
import LogoScreen from './LogoScreen';

const EmailVerify = () => {

    const location = useLocation();
    const [showMsg, setShowMsg]= useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        try {
          if(location.search){
            const token = location.search.split('?token=')[1];
            if(token){
              setLoading(true);
              signUpValidateToken({ token })
                .then( () => setShowMsg(true) )
                .catch( console.log )
                .finally( () => setLoading(false) )
            }
          }
          
        } catch (error) {
          
        }
       
      }, []);

      return (
        <PageContainer title="Login" description="this is Login page">
          <Grid container spacing={0} sx={{ height: '100vh', justifyContent: 'center' }}>
            <Grid
              item
              xs={12}
              sm={12}
              lg={6}
              sx={{
                background: (theme) => `${theme.palette.mode === 'dark' ? '#1c1f25' : '#ffffff'}`,
              }}
            >
              <LogoScreen />
            </Grid>
            <Grid item xs={12} sm={8} lg={6} display="flex" alignItems="center">
              <Grid container spacing={0} display="flex" justifyContent="center">
                <Grid item xs={12} lg={9} xl={6}>
                  <Box>
                    {
                      loading &&
                      <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={50} />
                      </Grid>
                    }
                    {
                      showMsg && !loading &&
                      <Grid item>
                        <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin: '15px 0 10px 0',
                              flexDirection: 'column'
                            }}
                          >
                            <Box>
                              <Typography
                                fontWeight="500"
                                sx={{
                                  display: 'block',
                                  textDecoration: 'none',
                                }}
                              >
                                
                                Registered successfully, then you will be contacted
                              </Typography>
                            </Box>
                          </Box>
                      </Grid>
                    }
                    {
                      !showMsg && !loading &&
                      <Grid item>
                        <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin: '15px 0 10px 0',
                              flexDirection: 'column'
                            }}
                          >
                            <Box>
                              <Typography
                                fontWeight="500"
                                sx={{
                                  display: 'block',
                                  textDecoration: 'none',
                                }}
                              >
                                The link has expired
                              </Typography>
                            </Box>
                          </Box>
                      </Grid>
                    }
                    {
                      !loading &&
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '20px'
                        }}
                      >
                        
                        <Box>
                          <Typography
                            component={Link}
                            to="/auth/login"
                            fontWeight="500"
                            sx={{
                              display: 'block',
                              textDecoration: 'none',
                              color: 'primary.main',
                            }}
                          >
                            Go back to log in page
                          </Typography>
                        </Box>
                      </Box>  
                    }
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </PageContainer>
      );
}

export default EmailVerify