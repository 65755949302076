import { Storage } from '../../lib/utils';
import { FIRST_OPTION, ROBOT_DETAIL_BREADCRUMB, FORM_DETAIL_BREADCRUMB } from './types';

type BreadcrumbsItem = {
  title: string;
  to?: string;
};

const firstListItem = (companyName: string, option: string) => [
  {
    title: companyName,
  },
  {
    title: option[0].toUpperCase() + option.slice(1),
    to: `/${option}`,
  },
];

const initialState = Storage.get<BreadcrumbsItem[]>('breadcrumbsRobot') || [];

export const breadCrumbsGeneralReducer = (
  state = initialState,
  action: { type: string; payload: any },
) => {
  let newState = state;
  switch (action.type) {
    case FIRST_OPTION:
      newState = firstListItem(action.payload.selectedCompany.name, action.payload.option);
      break;
    case ROBOT_DETAIL_BREADCRUMB:
      newState = [
        ...firstListItem(action.payload.selectedCompany.name, action.payload.option),
        {
          title: action.payload.robotName[0].toUpperCase() + action.payload.robotName.slice(1),
          to: `/${action.payload.option}/${action.payload.id}`,
        },
      ];
      break;

    case FORM_DETAIL_BREADCRUMB:
      newState = [
        ...firstListItem(action.payload.selectedCompany.name, action.payload.option),
        {
          title: action.payload.formName[0].toUpperCase() + action.payload.formName.slice(1),
          to: `/${action.payload.option}/${action.payload.id}`,
        },
      ];
      break;

    default:
      break;
  }
  Storage.set<BreadcrumbsItem[]>('breadcrumbsRobot', newState);
  return newState;
};
