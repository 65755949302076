import { darken, lighten } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';

export type TField = {
  field: string;
  headerName: string;
  minWidth?: number;
  renderCell?: (event: any) => JSX.Element;
  type?: string;
};

type TProps = {
  fields: TField[];
  rows: any[];
  pageSize?: number;
  handleRowClick: any;
};

const CustomTable = ({ fields, rows, pageSize: pSize, handleRowClick }: TProps) => {

  const [pageSize, setPageSize] = React.useState(pSize || 15);

  const getBackgroundColor = (color: string, mode: string) => {
    return mode === 'dark' ? darken(color, 0.0) : lighten(color, 0.6);
  };
  const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


  console.log("FIELDS ", fields)
  const cols: GridColDef[] = fields.map((value) => ({
    field: value.field,
    headerName: value.headerName,
    renderCell: value?.renderCell,
    headerAlign: 'center',
    align: 'center',
    minWidth: value?.minWidth || 100,
    type: value?.type,
    flex: 1,
  }));

  return <div style={{ width: '100%' }}>
  <DataGrid
    className="animate__animated animate__fadeIn"
    initialState={{
      sorting: {
        sortModel: [{ field: 'enable', sort: 'desc' }],
      },
    }}
    rows={rows}
    columns={cols}
    pageSize={pageSize}
    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
    rowsPerPageOptions={[5, 10, 15]}
    autoHeight
    disableSelectionOnClick
    onRowClick={handleRowClick}
    sx={{
      '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .row-custom-disabled': {
        bgcolor: (theme) => getBackgroundColor('#e0e0e0', theme.palette.mode),
        '&:hover': {
          bgcolor: (theme) => getHoverBackgroundColor('#e0e0e0', theme.palette.mode),
        },
      },
    }}
  />
</div>;
};

export default CustomTable;
