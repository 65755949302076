import React, { useEffect } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '../TabPanel';
import CustomInput from '../../shared/input/CustomInput';
import { Grid, Typography, TextField, IconButton } from '@mui/material';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function RobotTypeTabs({ onChange, keyword, python_code, console_code, error }: any) {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const handleChangeSelectedTab = (event: any, newValue: any) => {
    setSelectedTabIndex(newValue);
  };

  const handleChange = (event: any) => {
    onChange(event);
  }

  useEffect(() => {
    if (keyword) {
      setSelectedTabIndex(0)
    }
    else if (python_code) {
      setSelectedTabIndex(1)
    }
    else if (console_code) {
      setSelectedTabIndex(2)
    }
  }, [])

  return (
    <Box sx={{ width: '60%' }}>
      <Box>
        <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
          Type
        </Typography>
        <Tabs
          value={selectedTabIndex}
          onChange={handleChangeSelectedTab}
          aria-label="basic tabs example"
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Tab label="ROBOTFRAMEWORK" />
          <Tab label="PYTHON" />
          <Tab label="CONSOLE COMMAND" />
          {/*
            <Tab label="ZIP FILE"  />
            <Tab label="LOCAL ROBOT" />
          */}
        </Tabs>
      </Box>
      <TabPanel value={selectedTabIndex} index={0}>
        <CustomInput error={error} label='Keyword' value={keyword} name='keyword' onChange={handleChange} size='small' />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        <CustomInput error={error} label='Python code, only use single quotes' value={python_code} name='python_code' onChange={handleChange} size='small' />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={2}>
        <CustomInput error={error} label='Console command' value={console_code} name='console_code' onChange={handleChange} size='small' />
      </TabPanel>
      {
        /*
          <TabPanel value={selectedTabIndex} index={2}>
            <FileUploader handleChange={console.log} name="file" types={["JPG", "TXT", "GIF"]} />
          </TabPanel>
        */
      }
    </Box>
  );
}
