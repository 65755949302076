import { Company } from '../../interface/Company';
import { Storage } from '../../lib/utils';
import { GET_COMPANIES, SELECT_COMPANY, ActionType, CLEAN, COMPANIES_LOADING } from './types';

type State = {
  selectedCompany: Company | undefined;
  companies: Company[];
  companiesLoading: boolean;
};

const initialState: State = {
  selectedCompany: Storage.get<Company>('selectedCompany'),
  companies: [],
  companiesLoading: false
};

export const companiesReducer = (
  state = initialState,
  action: { type: ActionType; payload: any },
): State => {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case COMPANIES_LOADING:
        return {
          ...state,
          companiesLoading: !state.companiesLoading
        };
    case SELECT_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case CLEAN:
      Storage.remove('tab');
      Storage.remove('selectedCompany');
      return {
        companies: [],
        selectedCompany: undefined,
        companiesLoading: false
      };
    default:
      return state;
  }
};
