import { LoadingButton } from '@mui/lab';
import { Card, Grid, List, ListItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { updateCompany } from '../../../lib/apiClient';
import { selectCompanyAction } from '../../../redux/companies/companiesActions';
import CustomModal from '../../../shared/modal/CustomModal';

const PlanConfirm = ({ isOpen, setIsOpen, plan, idCompany }: any) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state: any) => state.companiesState);

  const handleUpgrade = async () => {
    setLoading(true);
    try {
      await updateCompany(idCompany, { plan: plan.id });
      enqueueSnackbar('your plan was updated', {
        preventDuplicate: true,
        variant: 'success',
      });
      dispatch(selectCompanyAction({ ...selectedCompany, plan: plan.id }));
    } catch (error: any) {
      const msg = JSON.parse(error.message);

      enqueueSnackbar(msg.plan[0], {
        preventDuplicate: true,
        variant: 'error',
      });
    } finally {
      setLoading(false);
      setIsOpen(!isOpen);
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      title={`Do you want to upgrade your plan to "${plan?.plan}" ?`}
      onClose={() => setIsOpen(!isOpen)}
      size={'md'}
    >
      <Grid container style={{ height: '100%' }} justifyContent="center">
        <Grid item xs={4}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              textAlign: 'center',
              overflow: 'unset',
              position: 'relative',
              height: '100%',
            }}
          >
            <Typography variant="h4" sx={{ mt: 3 }}>
              {plan?.plan}
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Box sx={{ mt: 3, fontSize: '30px', fontWeight: '600' }}>
                <sup style={{ fontSize: '13px' }}>$</sup>
                {plan?.price}
              </Box>
              <Typography sx={{ fontSize: '13px', color: 'grey.A200', fontWeight: '400', ml: 1 }}>
                Per Month
              </Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <List>
                <ListItem>
                  <Typography sx={{ fontSize: '13px', fontWeight: '400', ml: 1 }}>
                    {plan?.description}
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: '50px 0 50px 0' }} gap={3} justifyContent="center">
        <Grid item>
          <LoadingButton onClick={() => setIsOpen(!isOpen)} disabled={loading}>
            Cancel
          </LoadingButton>
        </Grid>
        <Grid item>
          <LoadingButton variant="contained" loading={loading} onClick={handleUpgrade}>
            Upgrade
          </LoadingButton>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default PlanConfirm;
