import { HistoryBreadCrumbs } from '../components/shared';
import { RobotDetails } from '../views/robot/RobotDetails';
import RobotList from '../views/robot/RobotList';
import { HasCompany } from './guards/HasCompany';
import { RequireAuth } from './guards/RequireAuth';
import { FullLayout } from './lazyload/FullLayout';

export const robotRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      {
        path: '/robots',
        element: (
          <RequireAuth>
            <HasCompany>
              <HistoryBreadCrumbs option="breadcrumbsGeneral" />
              <RobotList />
            </HasCompany>
          </RequireAuth>
        ),
      },
      {
        path: '/robots/:robotId',
        element: (
          <RequireAuth>
            <HistoryBreadCrumbs option="breadcrumbsGeneral" />
            <RobotDetails />
          </RequireAuth>
        ),
      },
    ],
  },
];
