import React from 'react';
import { Link } from 'react-router-dom';
import LogoLight from '../../../assets/images/logos/image.png';

const LogoIcon = () => {
  return (
    <Link underline="none" to="/">
      <img src={LogoLight} style={{ width: '80%' }} />
    </Link>
  );
};

export default LogoIcon;
