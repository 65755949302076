import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageContainer from '../../components/container/PageContainer';
import { Breadcrumb } from '../../components/shared';
import EditAddSecretModal from '../../components/vault/EditAddSecret';
import EditWorkspaceModal from '../../components/workspace/EditWorkspaceModal';
import { useFetch } from '../../hooks/useFetch';
import { Workspace } from '../../interface/Workspace';
import {
  apiSecret,
  healthcheckOn,
  getWorkspaceQueue,
  cancelTransition,
  startContainer,
  stopContainer,
} from '../../lib/apiClient';
import { setWorkspaceSelected } from '../../redux/workspace/workspaceActions';
import { WorkspaceQueue } from '../../interface/Workspace';
import { useWebSocket } from '../../hooks/useWebSocket';

const defCols = (onCancel: any) => {
  const columms: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      headerAlign: 'center',
      align: 'center',
      maxWidth: 70,
      flex: 1,
    },
    {
      field: 'process_name',
      headerName: 'Process',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'date_create',
      headerName: 'Pending',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'date_start',
      headerName: 'Received',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'date_started',
      headerName: 'Started',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      maxWidth: 100,
      flex: 1,
    },
    {
      field: 'X',
      headerName: 'Cancel',
      headerAlign: 'center',
      align: 'center',
      maxWidth: 100,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <LoadingButton
            variant="outlined"
            style={{ marginRight: '8px' }}
            onClick={() => onCancel(cellValues)}
          >
            Cancel
          </LoadingButton>
        );
      },
    },
  ];
  return columms;
};

function WebSocketComponent({ iw, refresh }: { iw: number; refresh: any }) {
  const { onMessage } = useWebSocket(`/ws/process-instance/${iw}/`);

  useEffect(() => {
    onMessage(() => {
      refresh(false);
    });
  }, []);

  return <></>;
}

export const WorkspaceDetails = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { workspace, companiesState } = useSelector((state: any) => state);
  const { workspaceSelected } = workspace;
  const { selectedCompany } = companiesState;
  const [open, setOpen] = useState(false);
  const [openVault, setOpenVault] = useState(false);
  const [healcheckLoading, setHealcheckLoading] = useState(false);
  const [startLoading, setStartLoading] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);
  const [vnc, setVnc] = useState(null);
  const dispatch = useDispatch();
  const [workspaceChanged, setWorkspaceChanged] = useState<null | Workspace>(null);
  const [secrets, setSecrets] = useState<{ key: string; value: string }[]>([]);

  const {
    data: queueDetail,
    loading,
    refresh: refreshData,
    error,
  } = useFetch<WorkspaceQueue>(`/frontend/v1/workspace/queue/${workspaceSelected?.id}/`);

  const { onMessage } = useWebSocket(`/ws/v1/workspace/vnc/${workspaceSelected?.id}/`);

  const cancelTransitionHandler = async (cellValues: any) => {
    if (!cellValues.row.id) return;
    try {
      await cancelTransition(cellValues.row.id);
      enqueueSnackbar('Transition canceled successfully', {
        preventDuplicate: true,
        variant: 'success',
      });
      refreshData();
    } catch (e) {
      enqueueSnackbar('Error, try again later', {
        variant: 'error',
      });
    }
  };

  const cols = defCols(cancelTransitionHandler);

  useEffect(() => {
    if (workspaceChanged && workspaceSelected) {
      dispatch(setWorkspaceSelected(workspaceChanged));
    }
    return () => {
      dispatch(setWorkspaceSelected(null));
    };
  }, [workspaceChanged]);

  useEffect(() => {
    if (!openVault) {
      apiSecret({
        action: 'L',
        company_id: selectedCompany.id,
        workspace_id: workspaceSelected?.id,
      })
        .then((response) => {
          if (response.keys) {
            const mapped = response.keys.map((key: string) => ({ key, value: '********' }));
            setSecrets(mapped);
          }
        })
        .catch(console.log);
    }
  }, [openVault]);

  useEffect(() => {
    if (workspaceSelected) {
      setVnc(workspaceSelected.vnc);
    }

    if (!vnc) {
      onMessage(({ data }: any) => {
        const res = JSON.parse(data).message;
        const workspaceWs = {
          ...workspaceSelected,
          vnc: res.vnc,
        };
        dispatch(setWorkspaceSelected(workspaceWs));

        setVnc(res.vnc);
      });
    }
  }, [vnc]);

  const handleHealcheck = () => {
    setHealcheckLoading(true);
    healthcheckOn(workspaceSelected?.id)
      .then((response) => {
        enqueueSnackbar(response.Message, {
          preventDuplicate: true,
        });
        dispatch(setWorkspaceSelected({ ...workspaceSelected, status: response.Response }));
      })
      .catch(() => {
        enqueueSnackbar('Error, try again later', {
          variant: 'error',
          preventDuplicate: true,
        });
        setHealcheckLoading(false);
      })
      .finally(() => setHealcheckLoading(false));
  };

  const handleStart = () => {
    setStartLoading(true);
    startContainer(workspaceSelected?.id)
      .then((response: any) => {
        enqueueSnackbar(response.success, {
          preventDuplicate: true,
        });
        refreshData();
      })
      .catch((e) => {
        enqueueSnackbar(e.error, {
          variant: 'error',
          preventDuplicate: true,
        });
        setStartLoading(false);
      })
      .finally(() => setStartLoading(false));
  };

  const handleStop = () => {
    setStopLoading(true);
    stopContainer(workspaceSelected?.id)
      .then((response: any) => {
        enqueueSnackbar(response.success, {
          preventDuplicate: true,
        });
        refreshData();
      })
      .catch((e) => {
        enqueueSnackbar(e.error, {
          variant: 'error',
          preventDuplicate: true,
        });
        setStopLoading(false);
      })
      .finally(() => setStopLoading(false));
  };

  return (
    <>
      <PageContainer title="Workspace Detail" description="this is workspace detail">
        <Breadcrumb title={workspaceSelected?.hostname} subtitle="Workspace Detail">
          {workspaceSelected?.system === 'CONTENEDOR' && (
            <>
              <LoadingButton
                variant="contained"
                color="primary"
                style={{ marginRight: '20px' }}
                loading={startLoading}
                onClick={handleStart}
              >
                Play
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="error"
                style={{ marginRight: '20px' }}
                onClick={handleStop}
                loading={stopLoading}
              >
                Stop
              </LoadingButton>
            </>
          )}

          <LoadingButton
            variant="contained"
            color={workspaceSelected?.status ? 'primary' : 'error'}
            style={{ marginRight: '20px', width: 'auto' }}
            onClick={handleHealcheck}
            loading={healcheckLoading}
          >
            Healthcheck
          </LoadingButton>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: '20px' }}
            onClick={() => setOpenVault(!openVault)}
          >
            Secrets
          </Button>
          <LoadingButton variant="contained" color="primary" onClick={() => setOpen(!open)}>
            Edit
          </LoadingButton>
        </Breadcrumb>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <List>
                  <ListItem divider>
                    <span style={{ marginRight: '10px' }}>Name:</span>
                    <ListItemText
                      secondaryTypographyProps={{ align: 'left' }}
                      secondary={workspaceSelected?.hostname || ''}
                    />
                  </ListItem>
                  <ListItem divider>
                    <span style={{ marginRight: '10px' }}>Token:</span>
                    <ListItemText
                      secondaryTypographyProps={{ align: 'left' }}
                      secondary={workspaceSelected?.token_id || ''}
                    />
                  </ListItem>
                  <ListItem divider>
                    <span style={{ marginRight: '10px' }}>Url:</span>
                    <ListItemText
                      secondaryTypographyProps={{ align: 'left' }}
                      secondary={workspaceSelected?.ip || ''}
                    />
                  </ListItem>
                  <ListItem divider>
                    <span style={{ marginRight: '10px' }}>Port:</span>
                    <ListItemText
                      secondaryTypographyProps={{ align: 'left' }}
                      secondary={workspaceSelected?.puerto || ''}
                    />
                  </ListItem>
                  <ListItem divider>
                    <span style={{ marginRight: '10px' }}>System:</span>
                    <ListItemText
                      secondaryTypographyProps={{ align: 'left' }}
                      secondary={workspaceSelected?.system || ''}
                    />
                  </ListItem>
                  <ListItem divider>
                    <span style={{ marginRight: '10px' }}>Parallelize:</span>
                    <Switch
                      checked={workspaceSelected?.paralelize}
                      name="parallelize"
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled
                    />
                  </ListItem>
                </List>
                {vnc && vnc !== '' ? (
                  <Card>
                    <CardContent>
                      <iframe
                        title="VNC"
                        style={{ width: '100%', height: '700px', overflow: 'hidden', border: 0 }}
                        src={vnc}
                      />
                    </CardContent>
                  </Card>
                ) : null}
              </Grid>
            </Grid>
            <h4>Queue Workspace</h4>
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: 'enable', sort: 'desc' }],
                },
              }}
              columns={cols}
              rows={queueDetail?.results || []}
              rowsPerPageOptions={[5, 10, 15]}
              autoHeight
            />
          </CardContent>
        </Card>
      </PageContainer>
      <EditWorkspaceModal
        companyId={selectedCompany.id}
        workspace={workspaceSelected}
        onClose={() => setOpen(!open)}
        isOpen={open}
        setWorkspaceChanged={setWorkspaceChanged}
      />

      {openVault && (
        <EditAddSecretModal
          isOpen={openVault}
          onClose={() => setOpenVault(!openVault)}
          companyId={selectedCompany.id}
          workspace={workspaceSelected}
          secrets={secrets}
          setSecrets={setSecrets}
        />
      )}
      {queueDetail &&
        queueDetail.results.map((item, index) => (
          <div key={index}>
            <WebSocketComponent iw={item.iw} refresh={refreshData} />
          </div>
        ))}
    </>
  );
};
