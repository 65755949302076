import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import { Button, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { StepParametersForm } from './StepParametersForm';
import { Workspace } from '../../interface/Workspace';
import { Robot } from '../../interface/Robot';
import CancelAndSave from '../shared/cancel-save/CancelAndSave';
import AddNewRobot from './AddNewRobot';
import AddNewWorkspace from './AddNewWorkspace';
import FeatherIcon from '../feather-icon/icon';
import { createStep } from '../../lib/apiClient';
import { useFetch } from '../../hooks/useFetch';

const helperTextStyles = {
  margin: '10px 0 0 0',
  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: '12px',
  lineHeight: '12px',
};

type IProps = {
  onClose: () => void;
  companyId: number;
  processId: number;
  openDialog: boolean;
  refresh: any;
};

const AddStepModal = ({ onClose, companyId, openDialog, processId, refresh }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: workspaces,
    loading: loadingWorkspaces,
    error: errorWorkSpaces,
    refresh: refreshWorkspaces,
  } = useFetch<Workspace[]>(`/frontend/v1/workspace/list/${companyId}/`);

  const {
    data: robots,
    loading: loadingRobots,
    error: errorRobots,
    refresh: refreshRobots,
  } = useFetch<Robot[]>(`/frontend/v1/robot/list/${companyId}/`);

  const {
    data: capabilities,
    loading: loadingCapabilities,
    error: errorCapabilities,
  } = useFetch<any[]>(`/frontend/v1/capability/${companyId}/`);

  const [jsonValue, setJsonValue] = React.useState({});
  const [robot, setRobot] = React.useState<any>(-1);
  const [workspace, setWorkspace] = React.useState<number>(-1);
  const [capability, setCapability] = React.useState<number>(-1);

  const [openDialogWorkspace, setOpenDialogWorkspace] = React.useState(false);
  const [openDialogRobot, setOpenDialogRobot] = React.useState(false);

  React.useEffect(() => {
    
  }, [robot])
  

  const handleSave = async () => {
    try {
      if (robot === -1 || workspace === -1 || capability === -1) {
        return;
      }
      const body = {
        proceso: processId,
        robot,
        workspace,
        config: JSON.stringify(jsonValue),
        capability_id: capability
      };
      await createStep(body);
      enqueueSnackbar('Step created successfully', {
        preventDuplicate: true,
      });
      await refresh();
      onClose();
    } catch (error) {
      enqueueSnackbar('Error, try again later', {
        variant: 'error',
      });
    }
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="md" open={openDialog} onClose={onClose}>
        <DialogTitle sx={{ mt: 2, ml: 3 }}>
          <Grid container>
            <Grid item lg={11} sx={{ mb: 2 }}>
              <Typography variant="h4" sx={{ fontWeight: 500, color: '#363636' }}>
                New Step
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose}>
                <FeatherIcon fontSize="small" icon="x" width="16" height="16" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ ml: 3 }}>
          <Grid container>
            <Grid item lg={3} xs={10} md={6}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                    Robot
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    fullWidth
                    placeholder="Robot"
                    onChange={({ target }: any) => setRobot(target.value)}
                    size="small"
                    value={robot}
                  >
                    {!loadingRobots &&
                      !errorRobots &&
                      robots &&
                      Array.isArray(robots) &&
                      robots?.map((robot) => (
                        <MenuItem key={robot.id} value={robot.id}>
                          {robot.nombre}
                        </MenuItem>
                      ))}
                    <MenuItem value={''} onClick={() => setOpenDialogRobot(!openDialogRobot)}>
                      <Button> + new robot</Button>
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item>
                  <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                    Workspace
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    fullWidth
                    placeholder="Workspace"
                    onChange={({ target }: any) => setWorkspace(target.value)}
                    size="small"
                    value={workspace}
                  >
                    {!loadingWorkspaces &&
                      !errorWorkSpaces &&
                      workspaces &&
                      Array.isArray(workspaces) &&
                      workspaces?.map((workspace) => (
                        <MenuItem key={workspace.id} value={workspace.id}>
                          {' '}
                          {workspace.hostname}{' '}
                        </MenuItem>
                      ))}
                    <MenuItem
                      value={''}
                      onClick={() => setOpenDialogWorkspace(!openDialogWorkspace)}
                    >
                      <Button> + new workspace</Button>
                    </MenuItem>
                  </Select>
                  <p style={helperTextStyles}>This is where the robot will run</p>
                </Grid>
                <Grid item>
                  <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                    Capabilities
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    fullWidth
                    placeholder="Workspace"
                    onChange={({ target }: any) => setCapability(target.value)}
                    size="small"
                    value={capability}
                  >
                    {!loadingCapabilities &&
                      !errorCapabilities &&
                      capabilities &&
                      capabilities.map((capability) => (
                        <MenuItem key={capability.id} value={capability.id}>
                          {' '}
                          {capability.tag}{' '}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 4 }}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                Step Parameters
              </Typography>
              <StepParametersForm jsonValue={jsonValue} setJsonValue={setJsonValue}/>
            </Grid>
          </Grid>
          <CancelAndSave handleSave={handleSave} handleCancel={onClose} />
        </DialogContent>
      </Dialog>
      <AddNewRobot
        onClose={() => setOpenDialogRobot(!openDialogRobot)}
        isOpen={openDialogRobot}
        refresh={refreshRobots}
        companyId={companyId}
        setRobotChanged={setRobot}
      />
      <AddNewWorkspace
        onClose={() => setOpenDialogWorkspace(!openDialogWorkspace)}
        isOpen={openDialogWorkspace}
        companyId={companyId}
        refresh={refreshWorkspaces}
        setWorkspace={setWorkspace}
      />
    </div>
  );
};

export default AddStepModal;
