import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Typography, TextField, IconButton, TextareaAutosize } from '@mui/material';
import FeatherIcon from '../feather-icon/icon';

type TProps = {
  onClose: () => void;
  isOpen: boolean;
  meta: { result: Result }
}

type Result = {
  [key: string]: any;
  body: string;
  email_from: string;
  email_to: string;
  fecha_mail: string;
  message_id: string;
  subject: string;
}

const createMapResult  = (result: Result) => {
  const auxReturn: {key: string, value: any }[] = [];
  for (const key in result) {
    auxReturn.push({ key, value: result[key] });
  }
  return auxReturn;
}

const MetaInfoModal = ({ onClose, isOpen, meta }: TProps) => {

  if(!meta.result){
    return <></>;
  }

  const results = createMapResult(meta.result);

  return (
    <div>
      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
        <DialogTitle sx={{ mt: 2, ml: 3 }}>
          <Grid container>
            <Grid item lg={11} sx={{ mb: 2 }}>
              <Typography variant="h4" sx={{ fontWeight: 500, color: '#363636' }}>
                Process Run
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose}>
                <FeatherIcon fontSize="small" icon="x" width="16" height="16" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item style={{ width: '100%' }}>
              <Grid container direction="column" spacing={2}>
                {
                  results.map( result => (
                    <Grid item key={result.key}>
                      <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                        { result.key }
                      </Typography>
                      <TextareaAutosize
                        
                        value={result.value}
                        name="keyword"
                        disabled={true}
                        style={{ 
                          width: '100%', 
                          resize: 'none', 
                          backgroundColor: 'white',
                          borderRadius: '5px'
                        }}
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MetaInfoModal;
