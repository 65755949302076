import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import CustomTextField from '../forms/custom-elements/CustomTextField';
import { createProcess } from '../../lib/apiClient';
import { useNavigate } from 'react-router';
import { setAddStepDialogOpen } from '../../redux/processes/processesActions';

export default function FormDialog({ modalState, setModalState, refresh }) {
  const [newProcessName, setNewProcessName] = useState('');
  const [errorProcess, setErrorProcess] = useState(false);
  const { selectedCompany } = useSelector((state) => state.companiesState);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [console, setConsole] = useState(false);
  const [notify, setNotify] = useState(false);

  function isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  const handleClose = () => {
    setModalState(false);
  };

  const handleCreateProcess = async () => {
    if (!isBlank(newProcessName) && !errorProcess) {
      try {
        const result = await createProcess({
          empresa: selectedCompany.id,
          name: newProcessName,
          enable: true,
          console,
          notify,
        });
        enqueueSnackbar(`Process with name ${newProcessName} was created`, {
          variant: 'success',
        });
        const { id } = result;
        setNewProcessName('');
        handleClose();
        dispatch(setAddStepDialogOpen());
        navigate(`/process/${id}`);
      } catch (error) {
        enqueueSnackbar(`${error.message}`, {
          variant: 'error',
        });
      }
    } else {
      setErrorProcess(true);
      enqueueSnackbar('You need to fill the fields', {
        variant: 'error',
      });
    }
  };

  const handleProcessNameChange = (e) => {
    setNewProcessName(e.target.value);
    if (isBlank(e.target.value)) {
      setErrorProcess(true);
    } else {
      setErrorProcess(false);
    }
  };

  return (
    <Dialog open={modalState} fullWidth onClose={handleClose}>
      <DialogTitle sx={{ mt: 1 }}>
        <Typography sx={{ mb: 2 }} component="span" variant="h4">
          Create New Process
        </Typography>
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              m: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            mt: 2,
            mb: 2,
            mr: 3,
            ml: 3,
          }}
        >
          <CustomTextField
            id="name"
            fullWidth
            placeholder="Enter Process Name"
            value={newProcessName}
            onChange={handleProcessNameChange}
            variant="outlined"
            size="small"
            inputProps={{ 'aria-label': 'Enter Name' }}
            sx={{
              mb: 1,
            }}
            error={errorProcess}
          />

          <FormControlLabel
            sx={{
              display: 'block',
            }}
            control={<Switch checked={console} onChange={() => setConsole(!console)} />}
            label="Console"
          />

          <FormControlLabel
            sx={{
              mb: 3,
            }}
            control={<Switch checked={notify} onChange={() => setNotify(!notify)} />}
            label="Notify"
          />

          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              width: '100%',
              display: 'block',
            }}
            onClick={handleCreateProcess}
          >
            Create Process
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
