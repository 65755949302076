import { LoadingButton } from '@mui/lab';
import { Card, CardContent, CircularProgress, Grid, Tab, Tabs, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import PageContainer from '../../components/container/PageContainer'
import { Breadcrumb } from '../../components/shared';
import TabPanel from '../../components/TabPanel';
import { BILLING_INFO } from '../../constants/apiPaths';
import { useFetch } from '../../hooks/useFetch';
import BillingInfo from './info/BillingInfo';
import Plan from './plan/Plan';

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const MainBilling = () => {
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const handleChangeSelectedTab = (event: any, newValue: any) => {
        setSelectedTabIndex(newValue);
    };
    const { selectedCompany } = useSelector((state: any) => state.companiesState);
    const { data, loading, refresh } = useFetch<any>(BILLING_INFO(selectedCompany?.id));

    useEffect(() => {
        setSelectedTabIndex(0);
    }, [selectedCompany]);

    useEffect(() => {
        refresh(false);
    }, [selectedCompany])
    
    
    return (
        <PageContainer title="Plan & Billing" description="this is plan & billing">
            <Breadcrumb title="Plan & Billing" />
            <Card>
                <CardContent>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={selectedTabIndex}
                            onChange={handleChangeSelectedTab}
                            aria-label="basic tabs example"
                        >
                        <Tab label="Plans" {...a11yProps(0)} />
                        {/* <Tab label="Billing Info" {...a11yProps(1)} /> */}
                        {/* <Tab label="Invoices" {...a11yProps(2)} />
                        <Tab label="Cost Management" {...a11yProps(3)} /> */}
                        </Tabs>
                    </Box>
                    <TabPanel value={selectedTabIndex} index={0}>
                        <Plan />
                    </TabPanel>
                    <TabPanel value={selectedTabIndex} index={1}>
                        {
                            loading ?
                            <Grid container display='flex' justifyContent='center'>
                                <CircularProgress size={50} />
                            </Grid> :
                            
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >   
                                    {
                                        data &&
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h4" sx={{ mt: 3, color: 'gray' }}>Payment Details</Typography>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: '3px',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    {
                                                        [1,2,3,4].map( (k) => (
                                                            <div key={k} style={{
                                                                width: '8px',
                                                                height: '8px',
                                                                backgroundColor: 'black',
                                                                borderRadius: '4px'
                                                            }}></div>
                                                        ))
                                                    }
                                                    <div style={{ marginLeft: '10px' }}>{ data ? data.last4 : '' }</div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    }
                                    <LoadingButton 
                                        variant='contained'
                                        size='large'
                                        sx={{
                                            paddingLeft: '60px',
                                            paddingRight: '60px',
                                            width: '300px',
                                            alignSelf: 'center',
                                            marginTop: '20px'
                                        }}
                                        onClick={() => setIsOpen(!isOpen)}
                                    >{ data ? 'Update' : 'Add' } billing information</LoadingButton>
                                </div>
                            
                        }
                        <BillingInfo 
                            setIsOpen={setIsOpen}
                            isOpen={isOpen}
                        />
                    </TabPanel>
                    {/* <TabPanel value={selectedTabIndex} index={2}>
                        <p>2</p>
                    </TabPanel>
                    <TabPanel value={selectedTabIndex} index={3}>
                        <p>3</p>
                    </TabPanel> */}
                    </Box>
                </CardContent>
            </Card>
        </PageContainer>
    )
}

export default MainBilling