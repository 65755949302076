import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import PublicProcessInstanceDetail from '../../views/public/ProcessInstanceDetail';

const getData = async (url: string, navigate: any) => {
  try {
    const response = await fetch(url);
    if (response.status !== 200) {
      throw new Error('error');
    }
    return response.json();
  } catch (error) {
    navigate('/noauth/error');
  }
};

export function PublicMiddleware({ children }: any) {
  const { search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    let isMouted = true;
    const url = search.split('?url=')[1];
    getData(url, navigate).then((data) => {
      if (isMouted) {
        setData(data);
        setLoading(false);
      }
    });
    return () => {
      isMouted = false;
    };
  }, []);

  if (loading) {
    return <></>;
  }

  return <PublicProcessInstanceDetail processInstanceId={data.id} processInstance={data} />;
}
