import { Breadcrumbs, Chip, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ProcessInstance } from '../../../../interface/ProcessInstance';
import { TextResult } from './TextResult';
import TimelineHorizontal from '../../../../components/shared/timeline/Timeline';

type Props = {
  loading: boolean;
  error: boolean;
  processInstance: ProcessInstance;
};

export const TriggeredBy = ({ loading, error, processInstance }: Props) => {
  return (
    <>
      <Grid item xs={12} md={12} lg={8}>
        <Card style={{ minHeight: '250px' }}>
          <CardContent>
            <Grid container spacing={2}>
              {!loading || !error || processInstance ? (
                <>
                  <Grid item xs={6}>
                    <Chip label={processInstance?.status} variant="outlined" />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h3">Triggered By: {processInstance?.origin}</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <TimelineHorizontal info={processInstance} />
                    {
                      /*

                        <Breadcrumbs
                          separator={<NavigateNextIcon fontSize="small" />}
                          aria-label="breadcrumb"
                        >
                          <Grid item>
                            <Stack key={1}>
                              <Typography variant="h3" color="primary">
                                Created
                              </Typography>
                              <Typography>{processInstance?.date_create?.toString()}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item>
                            <Stack key={2}>
                              <Typography variant="h3" color="primary">
                                Started
                              </Typography>
                              <Typography>{processInstance?.date_start}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item>
                            <Stack key={3}>
                              <Typography variant="h3" color="primary">
                                Finished
                              </Typography>
                              <Typography>
                                {processInstance?.date_end
                                  ? processInstance?.date_end
                                  : 'nothing to show'}
                              </Typography>
                            </Stack>
                          </Grid>
                        </Breadcrumbs>
                      */
                    }
                  </Grid>
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px',
                    width: '100%',
                  }}
                >
                  <CircularProgress className="animate__animated animate__fadeIn" />
                </div>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <TextResult loading={loading} error={error} processInstance={processInstance} />
    </>
  );
};
