import { Company } from '../../interface/Company';
import { getCompanies } from '../../lib/apiClient';
import { Storage } from '../../lib/utils';
import { CLEAN, COMPANIES_LOADING, GET_COMPANIES, SELECT_COMPANY } from './types';

export const getCompaniesAction = (companies: Company[]) => ({
  type: GET_COMPANIES,
  payload: companies,
});

export const cleanCompanies = () => ({
  type: CLEAN,
});

export const selectCompanyAction = (company: Company) => {
  Storage.set('selectedCompany', company);
  return {
    type: SELECT_COMPANY,
    payload: company,
  };
};

export const getCompaniesStart = () => {
  return async (dispatch: any) => {
    dispatch({ type: COMPANIES_LOADING });
    const result = await getCompanies();
    dispatch(getCompaniesAction(result));
    dispatch({ type: COMPANIES_LOADING });
  };
};
