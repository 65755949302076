import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  IconButton,
  Select,
  MenuItem,
} from '@mui/material';
import CancelAndSave from '../shared/cancel-save/CancelAndSave';
import { getWorkspaceById, updateWorkspace } from '../../lib/apiClient';
import { useSnackbar } from 'notistack';
import FeatherIcon from '../feather-icon/icon';
import { useFetch } from '../../hooks/useFetch';

const EditWorkspaceModal = ({
  onClose,
  isOpen,
  companyId,
  workspace,
  setWorkspaceChanged,
}: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    ...workspace,
    empresa: companyId,
  });
  const [readOnly, setReadOnly] = React.useState(false);
  const { data: systems } = useFetch<any[]>(`/frontend/v1/workspace/systems/`);
  const { hostname, ip, puerto, parallelize, system } = formValues;

  const handleSave = async () => {
    await setLoading(true);
    try {
      await updateWorkspace(formValues);
      const workspaceEdited = await getWorkspaceById(workspace.id);
      setWorkspaceChanged(workspaceEdited);
      setFormValues({ ...workspaceEdited });
      enqueueSnackbar('Workspace edited successfully', {
        preventDuplicate: true,
      });
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Error, try again later', {
        variant: 'error',
      });
    }
  };

  const handleChange = ({ target }: any) => {
    if (target.name === 'parallelize') {
      return setFormValues({
        ...formValues,
        parallelize: !parallelize,
      });
    }
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
    
    if (target.name === 'system') {
      setReadOnly(target.value === 'WINDOWS')
    }
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
        <DialogTitle sx={{ mt: 2, ml: 3 }}>
          <Grid container>
            <Grid item lg={11} sx={{ mb: 2 }}>
              <Typography variant="h4" sx={{ fontWeight: 500, color: '#363636' }}>
                Edit Workspace
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose}>
                <FeatherIcon fontSize="small" icon="x" width="16" height="16" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ ml: 3 }}>
          <Grid container>
            <Grid item xs>
              <Grid container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                    Name
                  </Typography>
                  <TextField
                    variant="outlined"
                    name="hostname"
                    value={hostname}
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                    IP/DNS
                  </Typography>
                  <TextField
                    variant="outlined"
                    name="ip"
                    InputProps={{
                      readOnly: !readOnly,
                    }}
                    value={ip}
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                    Port
                  </Typography>
                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    name="puerto"
                    InputProps={{
                      readOnly: !readOnly,
                    }}
                    value={puerto}
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                    System
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    fullWidth
                    placeholder="System"
                    onChange={handleChange}
                    size="small"
                    value={system}
                    style={{ width: '200px' }}
                    name="system"
                  >
                    {systems?.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={parallelize}
                        onChange={handleChange}
                        name="parallelize"
                        inputProps={{ 'aria-label': 'controlled' }}
                        value={parallelize}
                      />
                    }
                    label="Paralelize"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{ marginTop: '50px' }}>
              <CancelAndSave handleSave={handleSave} handleCancel={onClose} loading={loading} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditWorkspaceModal;
