import ReactJson from '@microlink/react-json-view';
import { Box, Button, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useFetch } from '../../../hooks/useFetch';

interface ProcessAIAgentProps {
  processId: number;
  onClose: () => void;
}

const ProcessAIAgent: React.FC<ProcessAIAgentProps> = ({ processId, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, refresh } = useFetch<any>(`/api/ai/procesos/${processId}/`, null, true);
  const { patchData } = useFetch<any>(`/api/ai/procesos/${processId}/update/`, null, false);

  const [formValues, setFormValues] = useState({
    aiField: {} as Record<string, any>,
    enableAI: false,
  });

  const [editMode, setEditMode] = useState(false);
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    if (data) {
      const aiFieldString = data.aiField ? JSON.stringify(data.aiField, null, 2) : '';
      setFormValues({
        aiField: data.aiField ? data.aiField : {},
        enableAI: data.enableAI || false,
      });
      setTextValue(aiFieldString);
    }
  }, [data]);

  const handleSave = async () => {
    try {
      let parsedAiField;
      try {
        parsedAiField = JSON.parse(textValue);
      } catch (e) {
        enqueueSnackbar('Invalid JSON format', {
          variant: 'error',
        });
        return;
      }

      const updatedData = await patchData({ ...formValues, aiField: parsedAiField });
      if (updatedData) {
        enqueueSnackbar('Process updated successfully', {
          variant: 'success',
          preventDuplicate: true,
        });
        const aiFieldString = JSON.stringify(updatedData.aiField, null, 2);
        setFormValues({
          aiField: updatedData.aiField,
          enableAI: updatedData.enableAI,
        });
        setTextValue(aiFieldString);
      } else {
        throw new Error('Failed to update process');
      }
    } catch (error) {
      enqueueSnackbar('Error, try again later', {
        variant: 'error',
      });
    }
  };

  const handleJsonChange = (edit: any) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      aiField: edit.updated_src,
    }));
    setTextValue(JSON.stringify(edit.updated_src, null, 2));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, type, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(event.target.value);
  };

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <Box sx={{ padding: '16px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
            AI Field
          </Typography>
          {editMode ? (
            <textarea
              value={textValue}
              onChange={handleTextAreaChange}
              style={{
                width: '100%',
                height: '400px',
                fontFamily: 'monospace',
                fontSize: '14px',
                padding: '8px',
                borderRadius: '4px',
                resize: 'vertical',
              }}
            />
          ) : (
            <ReactJson
              src={formValues.aiField}
              onEdit={handleJsonChange}
              onAdd={handleJsonChange}
              onDelete={handleJsonChange}
              theme="monokai"
              iconStyle="triangle"
              style={{ fontSize: '14px' }}
              displayDataTypes={false}
            />
          )}
          <Button
            onClick={() => setEditMode(!editMode)}
            variant="contained"
            color="secondary"
            sx={{ mt: 2 }}
          >
            {editMode ? 'Switch to JSON View' : 'Switch to Text View'}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={formValues.enableAI}
                onChange={handleChange}
                name="enableAI"
                color="primary"
              />
            }
            label="Enable AI"
          />
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button onClick={onClose} variant="contained" color="secondary" sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProcessAIAgent;
