import * as React from 'react';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setRobotDetailBreadcrumb } from '../../redux/breadcrumb-general/breadCrumbsActions';
import { setWorkspaceSelected } from '../../redux/workspace/workspaceActions';

const defCols = () => {
  const columns: GridColumns = [
    { field: 'hostname', headerName: 'Hostname', minWidth: 300, flex: 1 },
    {
      field: 'ip',
      headerName: 'Url',
      minWidth: 400,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'puerto',
      headerName: 'Port',
      minWidth: 20,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 20,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (cellValues) => {
        return (
          <div style={{
            width: '20px',
            height: '20px',
            borderRadius: '10px',
            backgroundColor: cellValues.row.status ? '#00c292' : '#e46a76'
          }}></div>
        );
      },
    },
  ];
  return columns;
};

export default function WorkspaceTable({ workspaces, company }: any) {
  const [pageSize, setPageSize] = React.useState(15);
  const cols = defCols();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (params: any) => {
    dispatch(setWorkspaceSelected(params.row));
    dispatch(setRobotDetailBreadcrumb(company, 'workspaces', params.row.hostname, params.id));
    navigate({ pathname: `/workspaces/${params.id}` });
  };

  return (
    <div style={{ width: '100%', marginTop: '20px' }}>
      <DataGrid
        className="animate__animated animate__fadeIn"
        initialState={{
          sorting: {
            sortModel: [{ field: 'enable', sort: 'desc' }],
          },
        }}
        rows={workspaces || []}
        columns={cols}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15]}
        onRowClick={handleClick}
        autoHeight
        getRowClassName={(params) => {
          return params.row.enable ? 'row-custom-enabled' : 'row-custom-disabled';
        }}
      />
    </div>
  );
}
