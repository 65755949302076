
import { MenuItem, Select, Typography } from '@mui/material';
import React from 'react'
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel'
import CustomTextField from '../../components/forms/custom-elements/CustomTextField'

type TProps = {
    label: string;
    value: string | number;
    onChange: any;
    name?: string;
    variant?: 'outlined' | 'filled' | 'standart';
    error?: boolean;
    helperText?: string;
    onEnter?: any;
    htmlFor?: string;
    type?: string;
    sx?: any;
    size?: any;
    options?: any[];
}

const CustomInput = ({ 
    label, 
    onChange, 
    name, 
    variant, 
    value, 
    error,
    helperText,
    onEnter,
    htmlFor,
    type,
    sx,
    size,
    options
}: TProps ) => {

    const handleEnter = (event: any) => {
        if(!onEnter){
            return;
        }
        if (event.key === 'Enter') {
            onEnter();
        }
    }
    return (<>
        {
            type !== 'select' && 
            <>
                <CustomFormLabel htmlFor={htmlFor}>{ label }</CustomFormLabel>
                <CustomTextField
                    name={name || ''}
                    value={value}
                    onChange={onChange}
                    variant={ variant || 'outlined' }
                    fullWidth
                    error={error}
                    className={
                        error && 'animate__animated animate__pulse animate__faster'
                    }
                    onKeyDown={handleEnter}
                    helperText={ error && helperText}
                    type={ type || 'text' }
                    sx={ sx || {...sx} }
                    size='small'
                />
            </>
        }
        {
            <>
            {
                type === 'select' &&
                <>
                    <CustomFormLabel htmlFor={htmlFor}>{ label }</CustomFormLabel>
                    <Select
                         name={name || ''}
                         value={value}
                         onChange={onChange}
                         variant={ (variant || 'outlined') as any }
                         fullWidth
                         error={error}
                         className={
                             (error && 'animate__animated animate__pulse animate__faster') as any
                         }
                         onKeyDown={handleEnter}
                         type={ type || 'text' }
                         sx={ sx || {...sx} }
                         size={size || ''}

                    >   
                    {
                        options?.map(( option ) => (
                            <MenuItem key={option.id} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    
                    </Select>
                </>
            }
          </>
        }
    </>)
}

export default CustomInput