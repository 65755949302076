import { login } from '../../lib/apiClient';
import { Storage } from '../../lib/utils';
import { LOGIN, LOGOUT } from './types';

export const loginAction = (
  username: string | null,
  accessToken: string | null,
  id: number | null,
  isLoggedIn = true,
) => {
  return {
    type: LOGIN,
    payload: {
      username,
      accessToken,
      isLoggedIn,
      id
    },
  };
};

export const logoutAction = () => {
  Storage.set('session', {});
  return {
    type: LOGOUT,
  };
};

export const startLogin = (user: string, password: string) => {
  return async (dispatch: any) => {
    try {
      const respuesta = await login(user, password);
      const { accessToken, username, id } = respuesta;
      Storage.set('session', { accessToken, username, id });
      dispatch(loginAction(username, accessToken, id));
    } catch (error) {
      dispatch(loginAction(null, null, null, false));
      throw error;
    }
  };
};

export const startLoginWithGoogle = (accessToken:string, username:string | null, id:number | null) => {
  return async (dispatch: any) => {
    try {
     
     
      Storage.set('session', { accessToken, username, id});
      dispatch(loginAction(username, accessToken, id));
    } catch (error) {
      dispatch(loginAction(null, null, null, false));
      throw error;
    }
  };
};

