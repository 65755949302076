import { Grid, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux';
import ProcessTab from './ProcessTab';

const ProcessConfig = ({ onClose, processId }) => {
  const { configDialogOpen } = useSelector((state) => state.processesState);
  const handleCancel = () => {
    onClose(false);
  };
  return (
    <div>
      <Dialog fullWidth maxWidth="xl" open={configDialogOpen} onClose={handleCancel}>
        <DialogTitle sx={{ mt: 2, ml: 3 }}>
          <Grid container>
            <Grid item lg={11} sx={{ mb: 2 }}>
              <Typography variant="h4" sx={{ fontWeight: 500, color: '#363636' }}>
                Process Config
              </Typography>
            </Grid>
            <Grid
              item
              lg={1}
              sx={{ mb: 2 }}
              container
              direction="column"
              alignItems="flex-end"
              justify="flex-end"
            >
              <IconButton onClick={handleCancel} variant="contained" aria-label="Close Logs tab">
                <FeatherIcon fontSize="small" icon="x" width="16" height="16" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <ProcessTab onClose={handleCancel} processId={processId} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProcessConfig;
