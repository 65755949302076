import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Skeleton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb } from '../../components/shared';
import PageContainer from '../../components/container/PageContainer';
import { Robot } from '../../interface/Robot';
import RobotTable from '../../components/robot/RobotTable';
import AddNewRobot from '../../components/process/AddNewRobot';
import { firstListAction } from '../../redux/breadcrumb-general/breadCrumbsActions';
import { useFetch } from '../../hooks/useFetch';
import { ROBOT_LIST_PATH } from '../../constants/apiPaths';

export const RobotList = () => {
  const { companiesState } = useSelector((state: any) => state);
  const { selectedCompany } = companiesState;
  const dispatch = useDispatch();
  const [selectedRobot, setSelectedRobot] = useState<any>(null);

  const {
    data: robots,
    loading,
    error,
    refresh: refreshRobots,
  } = useFetch<Robot[]>(ROBOT_LIST_PATH(selectedCompany.id), []);

  const [openDialogRobot, setOpenDialogRobot] = React.useState(false);

  useEffect(() => {
    dispatch(firstListAction(selectedCompany, 'Robots'));
  }, []);

  useEffect(() => {
    refreshRobots();
  }, [selectedCompany]);

  return (
    <>
      <PageContainer title="Robots List" description="this is robot list">
        {/* breadcrumb */}
        <Breadcrumb title="Robots List" subtitle="Welcome User">
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => setOpenDialogRobot(!openDialogRobot)}
          >
            New Robot
          </LoadingButton>
        </Breadcrumb>
        <Card>
          <CardContent>
            <Grid container>
              {/* ------------------------- row 1 ------------------------- */}
              <Grid item xs={12} lg={12}>
                {loading || error ? (
                  <Skeleton variant="rectangular" width="100%" height={400} />
                ) : (
                  <RobotTable refresh={refreshRobots} robots={robots} company={selectedCompany} />
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </PageContainer>
      <AddNewRobot
        onClose={() => setOpenDialogRobot(!openDialogRobot)}
        isOpen={openDialogRobot}
        refresh={refreshRobots}
        companyId={selectedCompany.id}
        setRobotChanged={setSelectedRobot}
      />
    </>
  );
};

export default RobotList;
