import { CircularProgress, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { resetPassword, resetValidateToken } from '../../lib/apiClient'
import CustomForm, { InputField } from '../../shared/form/CustomForm'
import { Validators } from '../../validators'

const inputsField: InputField[] = [
  {
    label: 'Password',
    validators: [ Validators.minLength(7) ],
    name: 'password',
    type: 'password',
    helperText: 'Please type at least 8 characters'
  },
  {
    label: 'Confirm Password',
    validators: [ Validators.minLength(7) ],
    name: 'confirmPassword',
    type: 'password',
    helperText: 'Please type at least 8 characters'
  }
]

const ChangePassword = ({ token }: any ) => {

    const [loading, setLoading] = useState(false);
    const [loadingGlobal, setLoadingGlobal] = useState(false);
    const [tokenIsNotValid, setTokenIsNotValid] = useState(false);
    const [resetOk, setResetOk] = useState(false);

    useEffect(() => {
      setLoadingGlobal(true);
      resetValidateToken({ token })
        .then( (resp) => {
          if(resp.status?.toUpperCase() !== 'OK'){
            setTokenIsNotValid(true);
            return;
          }
        })
        .catch( () => {
          setTokenIsNotValid(true);  
        })
        .finally( () => setLoadingGlobal(false) )
    }, []);
    

    const handleClick = async ({ password }: any) => {
      try {
        await resetPassword({ token, password });
        setResetOk(true);
      } catch (error) {
        
      }finally{
        setLoading(false);
      }
    }


  return (
    <Grid item xs={12} sm={8} lg={6} display="flex" alignItems="center">
          <Grid container spacing={0} display="flex" justifyContent="center">
            {
              !loadingGlobal && !tokenIsNotValid && !resetOk &&
              <Grid item xs={12} lg={9} xl={6}>
                <Box
                  sx={{
                    p: 4,
                  }}
                >
                    <CustomForm 
                      titleForm=''
                      inputsField={inputsField}
                      buttonLabel='Reset Password'
                      endpoint={handleClick}
                      thereIsPasswordConfirm={true}
                    />
                </Box>
              </Grid>
            }
            {
              loadingGlobal &&
              <Grid item>
                <CircularProgress size={50} />
              </Grid>
            }
            {
              !loadingGlobal && tokenIsNotValid &&
              <Grid item>
                <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '15px 0 10px 0',
                      flexDirection: 'column'
                    }}
                  >
                    <Box>
                      <Typography
                        fontWeight="500"
                        sx={{
                          display: 'block',
                          textDecoration: 'none',
                          mb: '16px',
                        }}
                      >
                        The token has expired or is not invalid
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        component={Link}
                        to="/auth/login"
                        fontWeight="500"
                        sx={{
                          display: 'block',
                          mb: '16px',
                          color: 'primary.main',
                          textAlign: 'center'
                        }}
                      >
                        Go back to log in page
                      </Typography>
                    </Box>
                  </Box>
              </Grid>
            }
            {
              !loading && resetOk &&
              <Grid item>
                <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '15px 0 10px 0',
                      flexDirection: 'column'
                    }}
                  >
                    <Box>
                      <Typography
                        fontWeight="500"
                        sx={{
                          display: 'block',
                          textDecoration: 'none',
                          mb: '16px',
                        }}
                      >
                        Your password was successfully updated
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        component={Link}
                        to="/auth/login"
                        fontWeight="500"
                        sx={{
                          display: 'block',
                          mb: '16px',
                          color: 'primary.main',
                          textAlign: 'center'
                        }}
                      >
                        Go back to log in page
                      </Typography>
                    </Box>
                  </Box>
              </Grid>
            }
          </Grid>
        </Grid>
  )
}

export default ChangePassword