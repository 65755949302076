import { Button, Card, CircularProgress, Grid, List, ListItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import Pricing from '../../../components/pricing/Pricing';
import { useFetch } from '../../../hooks/useFetch';
import PlanConfirm from './PlanConfirm';

const Plan = () => {

    const { data, loading } = useFetch<any[]>('/frontend/v1/plan/', []);
    const { selectedCompany } = useSelector( (state: any) => state?.companiesState );
    const [isOpen, setIsOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<any>(null);
    if(loading){
        return (
            <Grid container display='flex' justifyContent='center'>
                <CircularProgress size={50} />
            </Grid>
        )
    }

    const pricingOrdered = data?.sort( (a, b) => {
        if(a.id < b.id){
          return -1;
        }
        if(a.id > b.id){
          return 1;
        }
        return 0;
      })

    const handleClick = (plan: any) => {
        setIsOpen( prev => {
            setSelectedPlan(plan);
            return !prev;
        });
    }

    return (
        <>
        <Grid container style={{ height: '100%' }}>
            {pricingOrdered?.map((price: any, index: number) => (
            <Grid item xs={3} key={price.id}>
                <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' ,textAlign: 'center', overflow: 'unset', position: 'relative', height: '100%' }}>
                <div>
                    <Typography variant="h4" sx={{ mt: 3 }}>
                    {price.plan}
                    </Typography>
                    <Box sx={{ mt: 3 }}>
                    <Box sx={{ mt: 3, fontSize: '30px', fontWeight: '600' }}>
                        <sup style={{ fontSize: '13px' }}>$</sup>
                        {price.price}
                    </Box>
                    <Typography sx={{ fontSize: '13px', color: 'grey.A200', fontWeight: '400', ml: 1 }}>
                        Per Month
                    </Typography>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                    <List>
                        <ListItem>
                        <Typography sx={{ fontSize: '13px', fontWeight: '400', ml: 1 }}>
                            { price.description  }
                        </Typography>
                        </ListItem>
                    </List>
                    </Box>
                </div>
                <Button
                        variant="contained"
                        color={ selectedCompany?.plan === price.id ? 'inherit' : 'primary' }
                        onClick={ () => handleClick(price) }
                        disabled={selectedCompany?.plan === price.id}
                >
                    {  selectedCompany?.plan === price.id ? 'Current' : 'Sign Up' }
                </Button>
                </Card>
            </Grid>
            ))}
            
      </Grid>
      <PlanConfirm 
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        plan={selectedPlan}
        idCompany={selectedCompany?.id}
      />
      </>
    )
}

export default Plan