import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@mui/material';

export const CardBase = ({ children }) => (
  <Card>
    <CardContent>{children}</CardContent>
  </Card>
);

CardBase.propTypes = {
  children: PropTypes.node,
};

export default CardBase;
