import { Form } from '../../interface/Form';
import { Storage } from '../../lib/utils';
import { LOADING, FORM_SELECTED } from './types';

interface State {
  formSelected: Form | null;
  name: string;
  loading: boolean;
}

const initialState: State = {
  name: 'Edit Forms',
  formSelected: Storage.get('formSelected')! || null,
  loading: false,
};

export const formReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case FORM_SELECTED:
      Storage.set('formSelected', action.payload);
      return {
        ...state,
        formSelected: action.payload,
      };
    default:
      return state;
  }
};
