import React, { useEffect } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '../TabPanel';
import CustomInput from '../../shared/input/CustomInput';
import { FileUploader } from "react-drag-drop-files";
import { TextField, Typography } from '@mui/material';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function RobotTabs({ onChange, gitValue, dockerValue, gitBranch, gitToken, error }: any) {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const handleChangeSelectedTab = (event: any, newValue: any) => {
    setSelectedTabIndex(newValue);
  };

  useEffect(() => {
    if (dockerValue) {
      setSelectedTabIndex(3)
    }
    else if (gitValue) {
      setSelectedTabIndex(1)
    }
    else if (gitToken) {
      setSelectedTabIndex(2)
    }
  }, [])

  const handleChange = (event: any) => {
    onChange(event);
  }

  return (
    <Box sx={{ width: '77%' }}>
      <Box>
        <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
          Code Source
        </Typography>
        <Tabs
          value={selectedTabIndex}
          onChange={handleChangeSelectedTab}
          aria-label="basic tabs example"
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Tab label="LOCAL WORKSPACE CODE" />
          <Tab label="PUBLIC GITHUB" />
          <Tab label="PRIVATE GITHUB" />
          <Tab label="PUBLIC DOCKER" />
          {/*
            <Tab label="ZIP FILE"  />
            <Tab label="LOCAL ROBOT" />
          */}
        </Tabs>
      </Box>
      <TabPanel value={selectedTabIndex} index={0}>
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        <CustomInput error={error} label='Github repository URL' value={gitValue} name='git_url' onChange={handleChange} size='small' />
        <CustomInput error={error} label='Branch' value={gitBranch} name='branch_name' onChange={handleChange} size='small' />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={2}>
        <CustomInput error={error} label='Github repository URL' value={gitValue} name='git_url' onChange={handleChange} size='small' />
        <CustomInput error={error} label='Branch' value={gitBranch} name='branch_name' onChange={handleChange} size='small' />
        <CustomInput error={error} label='Token (Fine-grained)' value={gitToken} name='git_token' onChange={handleChange} size='small' />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={3}>
        <CustomInput error={error} label='Public docker URL' value={dockerValue} name='docker_url' onChange={handleChange} size='small' />
      </TabPanel>
      {
        /*
          <TabPanel value={selectedTabIndex} index={2}>
            <FileUploader handleChange={console.log} name="file" types={["JPG", "TXT", "GIF"]} />
          </TabPanel>
        */
      }
    </Box>
  );
}
