import React, { useState } from 'react'
import { CustomTable } from '../../shared';
import { TField } from '../../shared/CustomTable';
import CustomModal from '../../shared/modal/CustomModal';
import { InfraLogs } from '../transition/InfraLogs';

const fields: TField[] = [
    {
        headerName: 'Date',
        field: 'date_create'
    },
    {
        headerName: 'Status',
        field: 'status'
    },
    {
        headerName: 'Message',
        field: 'message'
    }
]

const RobotHistorialTable = ({ data }: any) => {

    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
  
  const handleClick = ({ row }: any) => {
    setIsOpen( (prev) => {
        setMessage(row?.message);
        return !prev;
    })
  }

  return (
    <>
        <CustomTable 
            fields={fields} 
            rows={data} 
            handleRowClick={ handleClick }        
        />
        <CustomModal
            isOpen={isOpen} 
            title={''} onClose={() => setIsOpen(!isOpen)}
            size="md"
        >
            <InfraLogs  transition={{ infra_logs: message }} />
        </CustomModal>
    </>
  )
}

export default RobotHistorialTable