import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import useForm from '../../hooks/useForm';
import CustomInput from '../input/CustomInput';

export type InputField = {
    label: string;
    validators: any[];
    name: string;
    initialValue?: string;
    size?: number;
    size2?: string;
    type?: string;
    helperText?: string;
    formater?: any;
    options?: any[];
}

type TProps = {
    inputsField: InputField[];
    endpoint: any;
    thereIsPasswordConfirm?: boolean;
    buttonLabel: string;
    titleForm: string;
    onEnter?: any;
    width?: string;
    btnWidth?: string;
    listeningValues?: any;
    listeningError?: any;
    withoutBtn?: boolean;
}

const createObjectFormaters = (inputFields: InputField[]) => {
    const formater: any = {};
    for (const input of inputFields) {
        if(input.formater){
            formater[input.name] = input.formater;
        }
    }
    return formater;
}

const CustomForm = ({ 
    width, 
    btnWidth, 
    inputsField, 
    endpoint, 
    thereIsPasswordConfirm, 
    buttonLabel, 
    titleForm,
    listeningValues,
    withoutBtn
}: TProps ) => {

    let values: any = {};
    for (const input of inputsField) {
        values[input.name] = [ input.initialValue || '', ...input.validators ]
    }
    const [passwordConfirm, setPasswordConfirm] = useState<any>(false);

    const getHelperTextPasswordEqual = (input: InputField ) => {
        if(input.name === 'password' || input.name === 'confirmPassword'){
            return 'Passwords do not match';
        }
        return input.helperText;
    }
    
    const { 
        form, 
        handleInputChange, 
        errorInputs, 
        checkErrors, 
        hasAnError,
        setErrorInputs
      } = useForm<any>( values, listeningValues, createObjectFormaters(inputsField) );

    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        try {
            const error = checkErrors();
            if(error || hasAnError){
                return;
            }
            if(thereIsPasswordConfirm){
                if( form['password'] !== form['confirmPassword'] ){
                    setErrorInputs( { ...errorInputs, password: true, confirmPassword: true } )
                    setPasswordConfirm(true);
                    return;
                }
            }
            setLoading(true);
            await endpoint(form);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

  return (
    <>
        <Typography sx={{ 
                    textAlign: 'center',
                    mb: 1
        }} fontWeight="700" variant="h2">
            { titleForm }
        </Typography>
        <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container justifyContent="space-between" style={{ width, gap: '5px' }}>
                {
                    inputsField.map( v => (
                        
                    <Grid item xs={v.size || 12} key={v.name}>
                        <CustomInput
                            label={v.label}
                            name={v.name}
                            value={ form[v.name] }
                            onChange={handleInputChange}
                            onEnter={handleSubmit}
                            error={errorInputs[v.name]}
                            type={v.type}
                            htmlFor={v.type}
                            helperText={ passwordConfirm ? getHelperTextPasswordEqual(v) : v.helperText}
                            size='small'
                            options={v.options}
                       />
                    </Grid>
                        
                    ))
                }

            </Grid>
            {
                !withoutBtn &&
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                        loading={loading}
                        onClick={handleSubmit}
                        sx={{
                            pt: '10px',
                            pb: '10px',
                            mt: 6,

                            width: btnWidth
                        }}
                        >
                            { buttonLabel }
                    </LoadingButton>
                </div>
            }
        </Grid>
    </>
  )
}

export default CustomForm