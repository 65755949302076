import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Typography, TextField, IconButton } from '@mui/material';
import CancelAndSave from '../shared/cancel-save/CancelAndSave';
import { createRobot, getRobotById, updateRobot } from '../../lib/apiClient';
import { useSnackbar } from 'notistack';
import FeatherIcon from '../feather-icon/icon';
import RobotTabs from './RobotTabs';
import RobotTypeTabs from '../robot/RobotTypeTabs';

const AddNewRobot = ({
  onClose,
  isOpen,
  companyId,
  refresh,
  isEdit,
  robot,
  setRobotChanged,
}: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [formValues, setFormValues] = React.useState(
    robot || {
      nombre: '',
      keyword: '',
      origin: '',
      empresa: companyId,
      git_url: '',
      docker_url: '',
      python_code: '',
      console_code: '',
    },
  );

  const {
    nombre,
    docker_url,
    keyword,
    git_url,
    branch_name,
    git_token,
    python_code,
    console_code,
  } = formValues;

  const handleSave = async () => {
    if (docker_url && git_url) {
      setError(true);
      enqueueSnackbar("Can't use git url and docker url. You should only use one", {
        variant: 'error',
      });
      return;
    }
    setError(false);
    await setLoading(true);
    try {
      if (isEdit) {
        await updateRobot({ ...formValues, id: robot.id });
        const robotEdited = await getRobotById(robot.id);
        setFormValues({ ...robotEdited });
        setRobotChanged(robotEdited);
        enqueueSnackbar('Robot edited successfully', {
          preventDuplicate: true,
          variant: 'success',
        });
      } else {
        const robot = await createRobot(formValues);
        setRobotChanged(robot.id);
        enqueueSnackbar('Robot created successfully', {
          preventDuplicate: true,
          variant: 'success',
        });
        await refresh();
      }
      setLoading(false);
      onClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
      enqueueSnackbar('Error, try again later', {
        variant: 'error',
      });
    }
  };

  const handleChange = ({ target }: any) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleCancel = () => {
    onClose(false);
  };
  return (
    <div>
      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleCancel}>
        <DialogTitle sx={{ mt: 2, ml: 3 }}>
          <Grid container>
            <Grid item lg={11} sx={{ mb: 2 }}>
              <Typography variant="h4" sx={{ fontWeight: 500, color: '#363636' }}>
                {isEdit ? 'Edit Robot' : 'New Robot'}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose}>
                <FeatherIcon fontSize="small" icon="x" width="16" height="16" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ ml: 3 }}>
          <Grid container>
            <Grid item lg={3} xs={10} md={6}>
              <Grid container direction="row" spacing={2}>
                <Grid item style={{ marginTop: '5px', marginBottom: '5px' }}>
                  <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                    Name
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={nombre}
                    name="nombre"
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginTop: '10px',
              }}
            >
              <RobotTypeTabs
                onChange={handleChange}
                keyword={keyword}
                python_code={python_code}
                console_code={console_code}
              />
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginTop: '10px',
              }}
            >
              <RobotTabs
                onChange={handleChange}
                dockerValue={docker_url}
                gitValue={git_url}
                gitBranch={branch_name}
                gitToken={git_token}
              />
            </div>
            <Grid container sx={{ marginTop: '10px' }}>
              <CancelAndSave handleSave={handleSave} handleCancel={onClose} loading={loading} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddNewRobot;
