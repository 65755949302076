import { LoadingButton } from '@mui/lab';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from '../../../../components/feather-icon/icon';
import AddStepModal from '../../../../components/process/AddStepModal';
import { setAddStepDialogOpen } from '../../../../redux/processes/processesActions';

type IProps = {
  companyId: number;
  processId: number;
  refresh: any;
  withOutTitle?: boolean | undefined;
};

export const WithoutSteps = ({ companyId, processId, refresh, withOutTitle }: IProps) => {
  const dispatch = useDispatch();
  const { addStepDialogOpen } = useSelector((state: any) => state.processesState);

  return (
    <>
      <Grid item xs={12} lg={12}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              {/* ------------------------- row 1 ------------------------- */}
              <Grid item xs={12}>
                {!withOutTitle && (
                  <Typography component="span" variant="h2">
                    Steps
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container alignItems="center" direction="column">
              <Typography component="span" style={{ fontSize: '20px' }}>
                Looks like you dont have any steps, go to Process Settings to Add one
              </Typography>
              <Grid item>
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  startIcon={<FeatherIcon icon="plus" width="18" />}
                  style={{ marginTop: '15px' }}
                  onClick={() => dispatch(setAddStepDialogOpen())}
                >
                  Add Step
                </LoadingButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {addStepDialogOpen && (
        <AddStepModal
          onClose={() => dispatch(setAddStepDialogOpen())}
          companyId={companyId}
          openDialog={addStepDialogOpen}
          processId={processId}
          refresh={refresh}
        />
      )}
    </>
  );
};
