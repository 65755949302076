import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Chip } from '@mui/material';

const defCols = () => {
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', headerAlign: 'center', align: 'center', flex: 1 },
    {
      field: 'name',
      headerName: 'Name',
      align: 'center',
      headerAlign: 'center',
      renderCell: (cellValues: any) => {
        return cellValues.row.step.robot_name;
      },
      minWidth: 200,
      flex: 1
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      renderCell: (cellValues) => {
        return <Chip label={cellValues.row.status} variant="outlined" />;
      },
      minWidth: 100,
      flex: 1
    },
    {
      field: 'message',
      headerName: 'Message',
      align: 'center',
      headerAlign: 'center',
      renderCell: (cellValues: any) => {
        return cellValues.row.message;
      },
      minWidth: 300,
      flex: 1
    },
    { field: 'enable', headerName: 'Enabled', hide: true },
  ];
  return columns;
};

export default function TransitionsTable({ transitions, onClick }: any) {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = React.useState(5);

  const cols: any = defCols();

  const handleClick = (params: any, event: any, details: any) => {
    navigate(`/transition/${params.id}`);
  };

  return (
    <div>
      <DataGrid
        rows={transitions || []}
        columns={cols}
        onRowClick={onClick || handleClick}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15]}
        autoHeight
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '.MuiDataGrid-cell': {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
          },
        }}
      />
    </div>
  );
}
