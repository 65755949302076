import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import CustomInput from '../../shared/input/CustomInput'
import { Validators } from '../../validators'
import CreditCardInput from '../credit-card/CreditCard'
import { Country } from 'country-state-city';

interface FormValues {
    [key: string]: string,
    country: string,
    name: string,
    addres: string,
    city: string,
    state: string,
    zip_code: string,
    mail: string
}

interface FormValuesError {
    [key: string]: boolean,
    country: boolean,
    name: boolean,
    addres: boolean,
    city: boolean,
    state: boolean,
    zip_code: boolean,
    mail: boolean
}

const inputsField = (options: any[]) => [
    {
        label: 'Country',
        validators: [],
        name: 'country',
        size2: 'small',
        initialValue: '',
        type: 'select',
        options: options.map(c => ({ label: c.name, value: c.isoCode, id: c.isoCode }))
    },
    {
        label: 'Name',
        validators: [Validators.isRequired],
        name: 'name',
        size2: 'small',
        size: 12
    },
    {
        label: 'Street Address',
        validators: [Validators.isRequired],
        name: 'addres',
        size2: 'small',
        size: 12
    },
    {
        label: 'City',
        validators: [Validators.isRequired],
        name: 'city',
        size2: 'small',
        size: 3.8
    },
    {
        label: 'State',
        validators: [Validators.isRequired],
        name: 'state',
        size2: 'small',
        size: 3.8
    },
    {
        label: 'Zip Code',
        validators: [Validators.isRequired],
        name: 'zip_code',
        size2: 'small',
        size: 3.8
    },
    {
        label: 'Billing Email',
        validators: [Validators.isEmail],
        name: 'mail',
        size2: 'small',
    }

]

interface IProps {
    cardData: any, 
    setCardData: any, 
    formValues: any, 
    setFormValues: any,
    formValuesError: any, 
    setFormValuesError: any,
    setGeneralError: any
}

const RequestBillingInfo = ({ 
    cardData, 
    setCardData, 
    formValues, 
    setFormValues,
    formValuesError, 
    setFormValuesError,
    setGeneralError
}: IProps) => {

    const handleInputChange = ({ target }: any) => {
        setGeneralError(false);
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
        setFormValuesError({
            ...formValuesError,
            [target.name]: false
        });
    }

    return (
        <>
            <Box
                sx={{
                    width: '60%'
                }}
            >
                <Grid container direction='row' width='100%' gap={1} justifyContent='space-between'>
                    {
                        inputsField(Country.getAllCountries()).map((v) => {

                            return (
                                <Grid item xs={v?.size || 12}>
                                    <CustomInput
                                        label={v.label}
                                        name={v.name}
                                        value={formValues[v.name]}
                                        onChange={handleInputChange}
                                        error={formValuesError[v.name]}
                                        type={v.type}
                                        size='small'
                                        options={v.options}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>
            <Box
                sx={{
                    width: '60%',
                    marginTop: '5px'
                }}
            >
                <CreditCardInput
                    cardData={cardData}
                    setCardData={setCardData}
                />
            </Box>
        </>
    )
}

export default RequestBillingInfo