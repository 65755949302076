export const ROBOT_LIST_PATH = (companyId: string) => `/frontend/v1/robot/list/${companyId}/`;
export const ROBOT_DEPLOY_PATH = (robotId: string) => `/frontend/v1/robot/deployment/${robotId}/`;
export const ROBOT_DEPLOY_LIST_PATH = (robotId: string) => `/frontend/v1/robot/deployment/list/${robotId}/`;
export const WORKSPACE_LIST_PATH = (companyId: string) =>
  `/frontend/v1/workspace/list/${companyId}/`;

export const PROCESS_LIST_PATH = (companyId: string) => `/frontend/process/${companyId}/`;
export const PROCESS_INSTANCES_PATH = (companyId: string, processId: string) =>
  `/frontend/instance/list/${companyId}/${processId}/`;
export const PROCESS_INSTANCE_DETAIL_PATH = (companyId: string, processInstanceId: string) =>
  `/frontend/instance/detail/${companyId}/${processInstanceId}/`;
export const PROCESS_PATH = (processId: string) => `/frontend/v1/process/${processId}/`;

export const STEP_LOGS_PATH = (stepId: string) => `/frontend/transition/list/${stepId}/`;

export const TRANSITION_DETAIL_PATH = (transitionId: string) =>
  `/frontend/transition/detail/${transitionId}/`;

export const BILLING_INFO = (companyId: string) =>
  `/frontend/v1/billing/${companyId}/`;

export const PROCESS_LIST_TO_CREATE_FORM_PATH = (companyId: string) => `/frontend/v1/process/active/${companyId}/`; 
export const FORMS_LIST_PATH = (companyId: string) => `/frontend/v1/form/${companyId}/`

export const FORM_PATH = (formId: string) => `/frontend/v1/form/public/${formId}/`;



