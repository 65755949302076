import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import TabPanel from '../TabPanel';
import ProcessAIAgent from './ProcessConfigTabs/ProcessAIAgent';
import { ProcessScheduler } from './ProcessConfigTabs/ProcessScheduler';
import ProcessSetting from './ProcessConfigTabs/ProcessSetting';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ProcessTab = ({ onClose, processId }: any) => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const handleChangeSelectedTab = (event: any, newValue: any) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedTabIndex}
          onChange={handleChangeSelectedTab}
          aria-label="basic tabs example"
        >
          <Tab label="Scheduler" {...a11yProps(0)} />
          <Tab label="Settings" {...a11yProps(1)} />
          <Tab label="AI Agent" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={selectedTabIndex} index={0}>
        <ProcessScheduler onClose={onClose} />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        <ProcessSetting onClose={onClose} />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={2}>
        <ProcessAIAgent onClose={onClose} processId={processId} />
      </TabPanel>
    </Box>
  );
};

export default ProcessTab;
