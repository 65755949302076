import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const DAYS = [
  {
    key: 'monday',
    label: 'M',
    index: 1,
  },
  {
    key: 'tuesday',
    label: 'T',
    index: 2,
  },
  {
    key: 'wednesday',
    label: 'W',
    index: 3,
  },
  {
    key: 'thursday',
    label: 'T',
    index: 4,
  },
  {
    key: 'friday',
    label: 'F',
    index: 5,
  },
  {
    key: 'saturday',
    label: 'S',
    index: 6,
  },
  {
    key: 'sunday',
    label: 'S',
    index: 7,
  },
];

const WeekdayPicker = ({ days, setDays }) => {
  return (
    <>
      <ToggleButtonGroup
        size="medium"
        color="success"
        arial-label="Days of the week"
        value={days}
        onChange={(event, value) => setDays(value)}
      >
        {DAYS.map((day) => (
          <ToggleButton key={day.key} value={day.index} aria-label={day.key}>
            {day.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
};

export default WeekdayPicker;
