import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';

import { Breadcrumb } from '../../components/shared';
import PageContainer from '../../components/container/PageContainer';
import { processInstanceAction } from '../../redux/brearcrumbs-processes/breadCrumbsActions';
import { ProcessInstance } from '../../interface/ProcessInstance';
import { TriggeredBy } from './components/ProcessIntance/TriggeredBy';
import { TransitionDetails } from './components/ProcessIntance/TransitionDetails';
import { Timeline } from './components/ProcessIntance/Timeline';
import { useFetch } from '../../hooks/useFetch';
import { PROCESS_INSTANCE_DETAIL_PATH } from '../../constants/apiPaths';
import { useWebSocket } from '../../hooks/useWebSocket';

export const ProcessInstanceDetail = () => {
  const { processInstanceId } = useParams();
  const { selectedCompany } = useSelector((state: any) => state.companiesState);
  const {
    data: processInstance,
    loading,
    refresh: refreshProcessInstance,
    error,
  } = useFetch<ProcessInstance>(
    PROCESS_INSTANCE_DETAIL_PATH(selectedCompany?.id, processInstanceId!),
  );

  const { onMessage } = useWebSocket(`/ws/process-instance/${processInstanceId!}/`);

  const dispatch = useDispatch();

  const refresh = async () => {
    refreshProcessInstance();
  };

  useEffect(() => {
    onMessage(() => {
      refreshProcessInstance(false);
    });
  }, []);

  useEffect(() => {
    if (processInstanceId) {
      dispatch(processInstanceAction(processInstanceId));
    }
  }, [dispatch, processInstanceId]);

  return (
    <PageContainer title={`Process Run #${processInstanceId}`}>
      {/* breadcrumb */}
      <Breadcrumb title={` #${processInstanceId}`} subtitle="Process Run">
        <LoadingButton loading={loading} onClick={refresh} variant="outlined">
          Refresh
        </LoadingButton>
      </Breadcrumb>
      {/* end breadcrumb */}
      <Grid container spacing={0} sx={{ m: 3 }}>
        {/* ------------------------- TRIGGERED BY ------------------------- */}
        <TriggeredBy loading={loading} error={error} processInstance={processInstance!} />
        {/* ------------------------- DETAILS ------------------------- */}
        <TransitionDetails loading={loading} error={error} processInstance={processInstance!} />
        {/* ------------------------- TIME LINE ------------------------- */}
        <Timeline loading={loading} error={error} processInstance={processInstance!} />
      </Grid>
    </PageContainer>
  );
};

export default ProcessInstanceDetail;
