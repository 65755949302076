import { Company } from '../../interface/Company';
import { PROCESS_LIST, PROCESS_DETAIL, PROCESS_INSTANCE, TRANSITION_DETAIL } from './types';

export const processListAction = (selectedCompany: Company) => ({
  type: PROCESS_LIST,
  payload: selectedCompany,
});

export const processDetailAction = (
  companyName: string,
  processName: string,
  processId: string,
) => ({
  type: PROCESS_DETAIL,
  payload: {
    processName,
    processId,
    companyName,
  },
});

export const processInstanceAction = (processInstanceId: string) => ({
  type: PROCESS_INSTANCE,
  payload: processInstanceId,
});

export const setTransitionId = (transitionId: string) => ({
  type: TRANSITION_DETAIL,
  payload: transitionId,
});
