import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { LoadingButton } from '@mui/lab';

import { updateSchedule } from '../../../lib/apiClient';

import TabPanel from '../../TabPanel';
import CustomTextField from '../../forms/custom-elements/CustomTextField';
import WeekdayPicker from './Scheduler/WeekdayPicker';

// import WeekdayPicker from 'react-weekday-picker';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ProcessScheduler = ({ onClose }) => {
  const { process } = useSelector((state) => state.processesState);
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = React.useState(0);

  const [buttonLoading, setButtonLoading] = useState(false);

  const [scheduler] = React.useState({});
  const [processEnabled, setProcessEnabled] = useState(false);
  const [oneOff, setOneOff] = useState(false);

  const [daysValue, setDaysValue] = useState([1, 2, 3, 4, 5]);
  const [timeValue, setTimeValue] = React.useState(new Date());

  const [intervalValue, setIntervalValue] = useState(0);
  const [startTimeValue, setStartTimeValue] = React.useState(new Date());

  const [cronValue, setCronValue] = useState('');

  const refreshForm = () => {
    if (process.scheduler) {
      if (process.scheduler.weekday) {
        setTabValue(0);
        const weekDays = process.scheduler.weekday.split(',').map(Number);
        setDaysValue(weekDays);
        const hM = process.scheduler.time.split(':');

        const d = new Date();
        d.setHours(hM[0]);
        d.setMinutes(hM[1]);
        setTimeValue(d);
      } else if (process.scheduler.interval) {
        setTabValue(1);
        setIntervalValue(process.scheduler.interval);
        const hM = process.scheduler.start_time.split(':');

        const d = new Date();
        d.setHours(hM[0]);
        d.setMinutes(hM[1]);
        setStartTimeValue(d);
      } else {
        setTabValue(2);
        setCronValue(process.scheduler.cron);
      }
      setProcessEnabled(process.scheduler.enabled);
      setOneOff(process.scheduler.one_off);
    }
  };

  useEffect(() => {
    refreshForm();
  }, [process]);

  const handleCancel = () => {
    onClose();
  };

  const formatDate = (timeToFormat) => {
    return `${timeToFormat.getHours()}:${timeToFormat.getMinutes()}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (buttonLoading) {
      return;
    }
    setButtonLoading(true);

    let payload = {
      process: process.id,
      enable: processEnabled,
      one_off: oneOff,
    };

    if (tabValue === 0) {
      const timeLet = formatDate(timeValue);
      const weekDays = daysValue.join(',');

      payload = {
        ...payload,
        weekday: weekDays,
        time: timeLet,
      };
    } else if (tabValue === 1) {
      const startTimeLet = formatDate(startTimeValue);

      payload = {
        ...payload,
        interval: intervalValue,
        start_time: startTimeLet,
      };
    } else {
      payload = {
        ...payload,
        cron: cronValue,
      };
    }
    try {
      await updateSchedule(payload);
      enqueueSnackbar('Schedule Created Successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to save scheduler. Status Code:${error.message}`, {
        variant: 'error',
      });
    }

    setButtonLoading(false);

    onClose();
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeTime = (newValue) => {
    console.log('Time value');
    console.log(newValue);
    setTimeValue(newValue);
  };

  const handleChangeStartTime = (newValue) => {
    setStartTimeValue(newValue);
  };

  // process && process.scheduler ? (
  return (
    <>
      <Grid container alignItems="center" justifyContent="center" sx={{ width: '100%', mb: 7 }}>
        <Grid item lg={9}>
          <Box m="auto" sx={{ m: 6 }}>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <FormGroup sx={{ mb: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={processEnabled}
                      onChange={() => setProcessEnabled(!processEnabled)}
                    />
                  }
                  label="Schedule Enabled"
                />
                <FormControlLabel
                  control={<Switch checked={oneOff} onChange={() => setOneOff(!oneOff)} />}
                  label="Oneoff Task"
                />
              </FormGroup>
              {scheduler && (
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={tabValue}
                      onChange={handleChangeTab}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Day of the week" {...a11yProps(0)} />
                      <Tab label="Interval" {...a11yProps(1)} />
                      <Tab label="Cron Expression" {...a11yProps(2)} />
                    </Tabs>
                  </Box>

                  <TabPanel value={tabValue} index={0} sx={{ m: 2 }}>
                    <Grid container>
                      <Grid item lg={12} sx={{ mb: 2 }}>
                        <Typography sx={{ mb: 1 }}>Day of the week</Typography>
                        <WeekdayPicker days={daysValue} setDays={setDaysValue} />
                      </Grid>
                      <Grid item lg={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            label="Time"
                            value={timeValue}
                            onChange={handleChangeTime}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel value={tabValue} index={1} sx={{ m: 2 }}>
                    <Grid container>
                      <Grid item lg={12} sx={{ mb: 2 }}>
                        <Typography sx={{ mb: 1 }}>Interval in minutes</Typography>
                        <CustomTextField
                          id="name"
                          placeholder="minutes"
                          value={intervalValue}
                          onChange={(e) => setIntervalValue(e.target.value)}
                          variant="outlined"
                          type="number"
                          size="small"
                          inputProps={{ 'aria-label': 'Enter Name' }}
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <Typography sx={{ mb: 1 }}>Start Time</Typography>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            value={startTimeValue}
                            onChange={handleChangeStartTime}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel value={tabValue} index={2} sx={{ m: 2 }}>
                    <Typography sx={{ mb: 1 }}>Custom Cron Expression</Typography>
                    <CustomTextField
                      id="name"
                      placeholder="* * * * *"
                      value={cronValue}
                      onChange={(e) => setCronValue(e.target.value)}
                      variant="outlined"
                      size="small"
                      inputProps={{ 'aria-label': 'Enter Name' }}
                      label="Cron expression"
                    />
                  </TabPanel>
                </Box>
              )}
            </form>
          </Box>
        </Grid>
      </Grid>

      <Grid container alignItems="center" justifyContent="center" spacing={1}>
        {buttonLoading ? (
          <Grid item lg={3}>
            <LoadingButton loading />
          </Grid>
        ) : (
          <>
            <Grid item lg={2}>
              <Button onClick={handleCancel} fullWidth>
                Cancel
              </Button>
            </Grid>

            <Grid item lg={2}>
              <Button fullWidth onClick={handleSubmit}>
                Save
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
