import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom'
import { Validators } from '../../validators'
import CustomForm, { InputField } from '../../shared/form/CustomForm'

const inputsField: InputField[] = [
  {
    label: 'Email associated with the account',
    validators: [Validators.isEmail],
    name: 'email',
    type: 'email'
  }
]

const SendEmail = ({ onClick }: any) => {

  return (
    <Grid item xs={12} sm={8} lg={6} display="flex" alignItems="center">
      <Grid container spacing={0} display="flex" justifyContent="center">
        <Grid item xs={12} lg={9} xl={6}>
          <Box
            sx={{
              p: 4,
            }}
          >
            <CustomForm
              inputsField={inputsField}
              endpoint={onClick}
              buttonLabel="Send email"
              titleForm=''
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '15px 0 10px 0'
              }}
            >

              <Box>
                <Typography
                  component={Link}
                  to="/auth/login"
                  fontWeight="500"
                  sx={{
                    display: 'block',
                    textDecoration: 'none',
                    mb: '16px',
                    color: 'primary.main',
                  }}
                >
                  Go back to log in page
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

      </Grid>
    </Grid>
  )
}

export default SendEmail