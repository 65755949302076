import React, { useEffect, useState } from 'react';

import { FormControlLabel, Switch, Button, Typography } from '@mui/material';

import { ObjectInput } from 'react-object-input';
import CustomTextField from '../forms/custom-elements/CustomTextField';
import '../../styles/metaform.css';

export const StepParametersForm = ({ jsonValue, setJsonValue, toggleEnabled = false }) => {
  const [stringValue, setStringValue] = useState(JSON.stringify(jsonValue));

  const [toggleConfig, setToggleConfig] = useState(toggleEnabled);
  const [warningJson, setWarningJson] = useState(false);

  const handleResetConfig = () => {
    setJsonValue({});
    setStringValue(JSON.stringify(jsonValue, null, 2));
  };

  const handleChange = (e) => {
    const jsonString = e.target.value;
    setStringValue(jsonString);
    try {
      setJsonValue(JSON.parse(jsonString));
      setWarningJson(false);
    } catch {
      setWarningJson(true);
    }
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            disabled={warningJson}
            checked={toggleConfig}
            onChange={() => {
              setToggleConfig(!toggleConfig);
              setStringValue(JSON.stringify(jsonValue, null, 2));
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label="Show as JSON"
      />
      {!toggleConfig ? (
        <>
          <ObjectInput
            obj={jsonValue}
            onChange={setJsonValue}
            renderAdd={(addItem) => {
              return '';
            }}
            renderItem={(key, value, updateKey, updateValue, deleteProperty) => (
              // render an editor row for an item, using the provided callbacks
              // to propagate changes { "A": [ "b","c" ] }
              <div>
                <Typography style={{ textAlign: 'start' }}>{key}</Typography>

                <CustomTextField
                  value={value || ''} // value will be undefined for new rows
                  onChange={(e) => {
                    if (Array.isArray(value)) {
                      updateValue(e.target.value.split(','));
                      return;
                    }
                    updateValue(e.target.value);
                  }}
                  variant="outlined"
                  size="small"
                  sx={{ width: '100%', mb: 1 }}
                />
              </div>
            )}
          />
        </>
      ) : (
        <div style={{ flex: 1 }}>
          <textarea
            id="metaTextArea"
            style={{
              height: 150,
              width: '100%',
              padding: '5px',
              color: '#666',
              borderColor: warningJson ? 'red' : 'gray',
            }}
            value={stringValue}
            onChange={handleChange}
          />
        </div>
      )}
      <Button onClick={handleResetConfig} variant="outlined">
        Reset
      </Button>
    </>
  );
};
