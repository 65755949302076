import React, { useState, useEffect } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import { Button, Grid, IconButton, MenuItem, Select, Typography, TextField } from '@mui/material';
import { AdvancedStepParamsForm } from './AdvancedStepParamsForm';
import { Workspace } from '../../interface/Workspace';
import { Robot } from '../../interface/Robot';
import CancelAndSave from '../shared/cancel-save/CancelAndSave';
import AddNewRobot from './AddNewRobot';
import AddNewWorkspace from './AddNewWorkspace';
import FeatherIcon from '../feather-icon/icon';
import { updateStep } from '../../lib/apiClient';
import { useFetch } from '../../hooks/useFetch';

const helperTextStyles = {
  margin: '10px 0 0 0',
  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: '12px',
  lineHeight: '12px',
};

type IProps = {
  onClose: () => void;
  companyId: number;
  processId: number;
  refresh: any;
  step: any;
};

const AvancedStepSettings = ({ step, onClose, companyId, processId, refresh }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: workspaces,
    loading: loadingWorkspaces,
    error: errorWorkSpaces,
    refresh: refreshWorkspaces,
  } = useFetch<Workspace[]>(`/frontend/v1/workspace/list/${companyId}/`);

  const {
    data: robots,
    loading: loadingRobots,
    error: errorRobots,
    refresh: refreshRobots,
  } = useFetch<Robot[]>(`/frontend/v1/robot/list/${companyId}/`);

  const {
    data: capabilities,
    loading: loadingCapabilities,
    error: errorCapabilities,
  } = useFetch<any[]>(`/frontend/v1/capability/${companyId}/`);

  const [jsonValue, setJsonValue] = React.useState({});
  const [robot, setRobot] = React.useState<any>(step.robot);
  const [workspace, setWorkspace] = React.useState<number>(step.workspace);
  const [capability, setCapability] = React.useState<number>(step.capability_id);
  const [jsonReset, setJsonReset] = useState(false);

  const [openDialogWorkspace, setOpenDialogWorkspace] = React.useState(false);
  const [openDialogRobot, setOpenDialogRobot] = React.useState(false);
  const [timeoutState, setTimeoutState] = useState(0);

  const [stepState, setStepState] = useState({});
  const [stringValue, setStringValue] = useState('{}');
  const [warningJson, setWarningJson] = useState(false);

  React.useEffect(() => {}, [robot]);

  useEffect(() => {
    console.log(step);
    setStepState(step);
    if (step.config) {
      setJsonValue(JSON.parse(step.config));
      setStringValue(JSON.stringify(jsonValue, null, 2));
    }
    setTimeoutState(step.time_out);
  }, []);

  const handleSave = async () => {
    if (!warningJson) {
      try {
        const payload = {
          config: JSON.stringify(jsonValue),
          id: step.id,
          time_out: timeoutState,
          robot,
          workspace,
          capability_id: capability,
        };
        enqueueSnackbar('Saving step config', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        await updateStep(payload);
        enqueueSnackbar(`Step config was updated successfully`, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        await refresh();
      } catch (error) {
        enqueueSnackbar('Error, try again later', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    } else {
      enqueueSnackbar('Verify that the JSON provided is valid', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }
  };

  const handleCancel = () => {
    if (step.config) {
      setJsonValue(JSON.parse(step.config));
      setStringValue(JSON.stringify(jsonValue, null, 2));
    }
    setJsonReset(!jsonReset);
    setTimeoutState(step.time_out);
    setWorkspace(step.workspace);
    setRobot(step.robot);
    setCapability(step.capability_id);
  };

  const handleChangeTimeout = ({ target }: any) => {
    setTimeoutState(target.value);
  };

  return (
    <div>
      <Grid container direction="row" spacing={12} sx={{ mb: 3 }}>
        <Grid item xs={6}>
          <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
            Step Parameters
          </Typography>
          <AdvancedStepParamsForm
            jsonValue={jsonValue}
            warningJson={warningJson}
            setWarningJson={setWarningJson}
            setJsonValue={setJsonValue}
            reset={jsonReset}
            toggleEnabled={false}
          />
        </Grid>

        <Grid item xs={6}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                Timeout
              </Typography>
              <TextField
                variant="outlined"
                value={timeoutState}
                name="timeoutState"
                onChange={handleChangeTimeout}
                size="small"
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item>
              <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                Robot
              </Typography>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                placeholder="Robot"
                onChange={({ target }: any) => setRobot(target.value)}
                size="small"
                value={robot}
              >
                {!loadingRobots &&
                  !errorRobots &&
                  robots &&
                  Array.isArray(robots) &&
                  robots?.map((robot) => (
                    <MenuItem key={robot.id} value={robot.id}>
                      {robot.nombre}
                    </MenuItem>
                  ))}
                <MenuItem value={''} onClick={() => setOpenDialogRobot(!openDialogRobot)}>
                  <Button> + new robot</Button>
                </MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                Workspace
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                placeholder="Workspace"
                onChange={({ target }: any) => setWorkspace(target.value)}
                size="small"
                value={workspace}
              >
                {!loadingWorkspaces &&
                  !errorWorkSpaces &&
                  workspaces &&
                  Array.isArray(workspaces) &&
                  workspaces?.map((workspace) => (
                    <MenuItem key={workspace.id} value={workspace.id}>
                      {' '}
                      {workspace.hostname}{' '}
                    </MenuItem>
                  ))}
                <MenuItem value={''} onClick={() => setOpenDialogWorkspace(!openDialogWorkspace)}>
                  <Button> + new workspace</Button>
                </MenuItem>
              </Select>
              <p style={helperTextStyles}>This is where the robot will run</p>
            </Grid>
            <Grid item>
              <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                Capabilities
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                placeholder="Capability"
                onChange={({ target }: any) => setCapability(target.value)}
                size="small"
                value={capability}
              >
                {!loadingCapabilities &&
                  !errorCapabilities &&
                  capabilities &&
                  capabilities.map((capability) => (
                    <MenuItem key={capability.id} value={capability.id}>
                      {' '}
                      {capability.tag}{' '}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <CancelAndSave handleSave={handleSave} handleCancel={handleCancel} />
      <AddNewRobot
        onClose={() => setOpenDialogRobot(!openDialogRobot)}
        isOpen={openDialogRobot}
        refresh={refreshRobots}
        companyId={companyId}
        setRobotChanged={setRobot}
      />
      <AddNewWorkspace
        onClose={() => setOpenDialogWorkspace(!openDialogWorkspace)}
        isOpen={openDialogWorkspace}
        companyId={companyId}
        refresh={refreshWorkspaces}
        setWorkspace={setWorkspace}
      />
    </div>
  );
};

export default AvancedStepSettings;
