import React, { useState, useRef, useEffect, useContext } from 'react';
import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';
import { expirationDate, expirationMonth, expirationYear } from "card-validator";
import { CreditCardDataContext } from './CreditCard';
import { absLenght } from './helpers/converters';
import CustomFormLabel from '../forms/custom-elements/CustomFormLabel';


const DateInput = ({leaveFieldCallback, focus, tabIndex}) => {
  const [error, setError] = useState(false);
  const [info, setInfo] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const CardContext = useContext(CreditCardDataContext);

  const handleChange = (event) => {
    const value = event?.target?.value;
    const epirationDate = expirationDate(value);
    const epirationMonth = expirationMonth(value);
    const epirationYear = expirationYear(value);
    const DateLength = absLenght(value);

    if(DateLength > 0 && !epirationMonth.isPotentiallyValid) {
      setInfo("Wrong month data");
      setError(true);
    } else if(DateLength > 0 && !epirationYear.isPotentiallyValid) {
      setInfo("Wrong year data");
      setError(true);
    } else if(DateLength > 0 && !epirationDate.isValid) {
      setInfo("Wrong data");
      setError(true);
    } else {
      setInfo("");
      setError(false);
    }
    if(epirationDate.isValid) {
      if(leaveFieldCallback) {
        leaveFieldCallback(tabIndex + 1);
      }
    }
  }

  const handleBlur = (event) => {
    const value = event?.target?.value;
    const epirationDate = expirationDate(value);
    if(epirationDate.isValid) {
      setError(false);
      setInfo("");
      CardContext?.setCardData({
        ...CardContext.cardData,
        date: {
          moth: epirationDate.month,
          year: epirationDate.year
        }
      });
    } else {
      setError(true);
    }
  }

  useEffect(() => {
    if(focus) {
      
    }
  }, [focus]);

  return (
    <>
    <CustomFormLabel style={{ margin: '5px' }}>Expiration Date (mm/yy)</CustomFormLabel>
    <InputMask 
      mask="99 / 99" 
      maskChar=" "
      onChange={handleChange}
      onBlur={handleBlur}
      tabIndex={tabIndex}
      
    >
      {() =>
        <TextField
          fullWidth
          size='small'
          variant='outlined'
          name="expire"
          type="text"
          error={error}
          helperText={info}
          autoFocus={focus}
          required
          inputRef={inputRef}
        />
      }
    </InputMask>
    </>
  )
}

export default DateInput;