import React, { useState } from 'react';

type Event = React.FormEvent<HTMLInputElement>;

type UseFormReturn<T> = {
    form: T,
    handleInputChange: (e: Event ) => void,
    errorInputs: any,
    setErrorInputs: any,
    hasAnError: boolean,
    checkErrors: any,
}

const createErrorObject = <T>(formValues: T) => {
    const auxReturn: any = {};
    for (const key in formValues) {
        auxReturn[key] = false;
    }
    return auxReturn;
}

const formMap = ( initialState: any ) => {
    const values: any = {};
    const validators: any = {};
    for (const key in initialState) {
        values[key] = initialState[key][0];
        const arrayValidators: any[] = [];
        initialState[key].forEach( (value: any, index: number) => {
            if(index > 0){
                arrayValidators.push(value);
            }
        });
        validators[key] = arrayValidators;
    }
    return { values, validators };
}

const useForm = <T>(initialState: T, listeningValues?: any, formater?: any  ): UseFormReturn<T> => {
    const { values, validators: formValidators } = formMap( initialState );
    const [form, setForm] = useState<any>( values );
    const [hasAnError, sethasAnError] = useState<any>( false );
    const [errorInputs, setErrorInputs] = useState<any>(createErrorObject(values));

    const handleInputChange = ( { target } : any ) => {
        sethasAnError(false);
        setErrorInputs((prev: any) => {
            const newValues = {
                ...prev,
                [target.name] : false
            }
            return newValues;
        })
        setForm( () => {
            const newValues = {
                ...form,
                [target.name] : formater[target.name] ? formater[target.name](target.value) : target.value
            }
            if(listeningValues){
                listeningValues(newValues);
            }
            return newValues;
        });
    }

    const checkErrors = () => {
        let auxReturn = false;
        let newError = { ...errorInputs };
        for (const key in formValidators) {
            // eslint-disable-next-line no-loop-func
            formValidators[key].map((f: any) => {
                newError = {
                    ...newError,
                    [key]: !f( form[key] )
                }
            })
        }
        
        for (const key in newError) {
            if( newError[key] ){
                auxReturn = true;
                sethasAnError(true);
            }
        }
        setErrorInputs(newError);
        return auxReturn;
    }

    return { form, handleInputChange, errorInputs, checkErrors, hasAnError, setErrorInputs }
}

export default useForm