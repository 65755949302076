import { Card, CardContent, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import FeatherIcon from '../../../../components/feather-icon/icon';
import LogsTable from '../../../../components/process/LogsTable';
import { useFetch } from '../../../../hooks/useFetch';
import { StepLog } from '../../../../interface/StepLog';

type Props = {
  selectedStepName: string | null;
  handleCloseLogsCard: any;
  selectedStep: string | null;
};

export const LogsForSteps = ({ handleCloseLogsCard, selectedStepName, selectedStep }: Props) => {
  const { data: stepLogs, loading, error } = useFetch<StepLog[]>(`/frontend/transition/list/${selectedStep}/`);

  return (
    <Grid item xs={12} lg={12} className="animate__animated animate__fadeIn">
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            {/* ------------------------- row 1 ------------------------- */}
            <Grid item xs={10}>
              <Typography component="span" variant="h2">
                Logs for step: {selectedStepName}
              </Typography>
            </Grid>
            <Grid item xs={2} container direction="column" alignItems="flex-end">
              <IconButton onClick={handleCloseLogsCard} aria-label="Close Logs tab">
                <FeatherIcon fontSize="small" icon="x" width="16" height="16" />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              {!loading && !error ? (
                <LogsTable logs={stepLogs} />
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                  <CircularProgress className="animate__animated animate__fadeIn" />
                </div>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
