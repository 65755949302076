import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Typography, TextField, IconButton, Button, useRadioGroup } from '@mui/material';
import CancelAndSave from '../../shared/cancel-save/CancelAndSave';

import PreviewCancelSave from '../../shared/cancel-save/PreviewCancelSave';

import { createForm, updateForm } from '../../../lib/apiClient';
import { useSnackbar } from 'notistack';
import FeatherIcon from '../../feather-icon/icon';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { StepParametersForm } from '../StepParametersForm';
import ListProcess from '../Forms/ListProcess';

import SurveyPreview from './SurveyPreview';

//import { Model } from 'survey-core';

const initialJsonFormat = {
  name: '',
  email: '',
  amount: '',
};

type Elements = {
  name: string;
  title: string;
  type: string;
}[];

const AddNewForm = ({
  onClose,
  isOpen,
  companyId,
  refresh,
  isEdit,
  setFormChanged,
  formChanged,
}: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);

  const [jsonValue, setJsonValue] = React.useState(formChanged?.meta || {});
  const [formValues, setFormValues] = React.useState({
    name: '',
    process: -1,
    publicUrl: false,
    referal_form_id: '',
  });

  React.useEffect(() => {
    if (formChanged && isEdit) {
      console.log(formChanged.public);
      setFormValues({
        name: formChanged.name,
        process: formChanged.process,
        publicUrl: formChanged.public,
        referal_form_id: formChanged.referal_form_id,
      });
      setJsonValue(formChanged.meta);
    } else {
      setJsonValue({});
      setFormValues({ name: '', process: -1, publicUrl: false, referal_form_id: '' });
    }
  }, [formChanged, isEdit]);

  const handlePrevieClose = () => {
    setIsPreviewOpen(false);
  };

  const checkJson = () => {
    //const myModel = new Model(jsonValue);

    //console.log(myModel);

    return true;
  };

  const handleSave = async () => {
    setError(false);
    setLoading(true);
    try {
      checkJson();

      const body = {
        process: formValues.process,
        name: formValues.name,
        description: formValues.name,
        public: formValues.publicUrl,
        meta: jsonValue,
        referal_form_id: formValues.referal_form_id,
      };
      console.log(body);

      if (isEdit) {
        const res = await updateForm({
          ...body,
          id: formChanged.id as number,
          url: formChanged.url as string,
        });

        console.log(res);
        enqueueSnackbar('Form edit successfully', {
          preventDuplicate: true,
        });
      } else {
        await createForm(body);

        enqueueSnackbar('Form created successfully', {
          preventDuplicate: true,
        });
      }

      await refresh();

      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Error, try again later', {
        variant: 'error',
      });
    }
  };

  const handleChange = ({ target }: any) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleChangeProcess = ({ target }: any) => {
    setFormValues({
      ...formValues,
      process: target.value,
    });
  };

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleCancel}>
        <DialogTitle sx={{ mt: 2, ml: 3 }}>
          <Grid container>
            <Grid item lg={11} sx={{ mb: 2 }}>
              <Typography variant="h4" sx={{ fontWeight: 500, color: '#363636' }}>
                {isEdit ? 'Edit Form' : 'New Form'}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose}>
                <FeatherIcon fontSize="small" icon="x" width="16" height="16" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ ml: 3 }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={12}>
                <Grid item xs={6}>
                  <Grid item style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                      Form Name
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={formValues.name}
                      name="name"
                      onChange={handleChange}
                      size="small"
                    />
                  </Grid>

                  <ListProcess handleChange={handleChangeProcess} process={formValues.process} />
                  <Grid item style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                    Jotform Integration: Jotform ID
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={formValues.referal_form_id}
                      name="referal_form_id"
                      onChange={handleChange}
                      size="small"
                    />
                  </Grid>
                  {isEdit && formChanged.url && (
                    <Grid item style={{ marginTop: '10px', marginBottom: '10px' }}>
                      <Button
                        fullWidth
                        onClick={() => {
                          //const key = formChanged.url.split('/form/')[1];
                          //const url = `${window.location.origin}/form/${key}`;
                          const url = formChanged.url;
                          navigator.clipboard.writeText(url);
                          enqueueSnackbar('URL copied to clipboard', {
                            preventDuplicate: true,
                          });
                        }}
                        variant="contained"
                      >
                        Copy URL
                      </Button>
                    </Grid>
                  )}

                  <Grid item>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formValues.publicUrl}
                            onChange={() => {
                              setFormValues({
                                ...formValues,
                                publicUrl: !formValues.publicUrl,
                              });
                            }}
                          />
                        }
                        label="Is PublicUrl"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid item>
                    <Typography variant="h5" sx={{ fontWeight: 500, color: '#363636' }}>
                      Form Parameters JSON Format
                    </Typography>

                    <StepParametersForm
                      toggleEnabled={true}
                      jsonValue={jsonValue}
                      setJsonValue={setJsonValue}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{ marginTop: '20px' }}>
              <PreviewCancelSave
                handleSave={handleSave}
                handleCancel={onClose}
                loading={loading}
                handlePreview={() => {
                  setIsPreviewOpen(true);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        { /*<SurveyPreview surveyJson={jsonValue} isOpen={isPreviewOpen} onClose={handlePrevieClose} />*/}
      </Dialog>
    </div>
  );
};

export default AddNewForm;
