import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import { Button } from '@mui/material';
import MetaInfoModal from './MetaInfoModal';

const defCols: any = () => {
  const columns = [
    {
      field: 'id',
      headerName: 'Run #',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      renderCell: (cellValues: any) => {
        return `# ${cellValues.row.id}`;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (cellValues: any) => {
        return <Chip label={cellValues.row.status} variant="outlined" />;
      },
    },
    {
      field: 'status_result',
      headerName: 'Step Status',
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      renderCell: (cellValues: any) => {
        return (
          <Chip
            color={
              cellValues.row.status_result === 'FAILURE'
                ? 'error'
                : cellValues.row.status_result === 'WARNING'
                ? 'warning'
                : 'default'
            }
            label={cellValues.row.status_result}
            variant="outlined"
          />
        );
      },
    },
    {
      field: 'meta',
      headerName: '',
      flex: 1,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (cellValues: any ) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [isOpen, setIsOpen] = useState(false);
        return (
          <>
            <Button
              disabled={ !cellValues.row.meta?.result }
              color='primary'
              variant='outlined'
              onClick={ () => setIsOpen(true) }>
                  RESULT
            </Button>
            <MetaInfoModal  
              isOpen={isOpen}
              onClose={ () => setIsOpen(false) }
              meta={ cellValues.row.meta }
            />
            
          </>
        );
      },
    },
    {
      field: 'completed',
      headerAlign: 'center',
      align: 'center',
      headerName: '% of completion',
      flex: 1,
      minWidth: 100,
    },
    { field: 'date_start', type: 'dateTime', headerName: 'Run Date', flex: 1, minWidth: 200 },
  ];
  return columns;
};

export default function ProcessInstancesTable({ processInstances }: any) {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = React.useState(5);

  const cols = defCols();

  const handleClick = (params: any, event: any, details: any) => {
    if(event.target.innerText === 'RESULT'){
      return;
    }
    navigate(`/instance/${params.id}`);
  };

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        rows={processInstances}
        columns={cols}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15]}
        autoHeight
        disableSelectionOnClick
        onRowClick={handleClick}
        sx={{
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        }}
      />
    </div>
  );
}
