import { Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import ProcessInstancesTable from '../../../../components/process/ProcessInstancesTable';
import { useFetch } from '../../../../hooks/useFetch';
import { ProcessInstance } from '../../../../interface/ProcessInstance';
import { useWebSocket } from '../../../../hooks/useWebSocket';

type Props = {
  isNecessaryRefresh: boolean;
  companyId: string;
};

export const ProcessRuns = ({ isNecessaryRefresh, companyId }: Props) => {
  const { processId } = useParams();
  const {
    data: processInstances,
    loading: loadingInstances,
    refresh,
    error,
  } = useFetch<ProcessInstance[]>(`/frontend/instance/list/${companyId}/${processId}/`);

  useEffect(() => {
    refresh();
  }, [isNecessaryRefresh, refresh]);

  const { onMessage } = useWebSocket(`/ws/process/${processId}/`);

  onMessage(() => refresh());

  return (
    <Grid item xs={12} lg={12}>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography component="span" variant="h2">
                Process Runs
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {loadingInstances ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                  <CircularProgress />
                </div>
              ) : (
                <ProcessInstancesTable processInstances={processInstances || []} />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
