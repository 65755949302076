import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid, Box, Typography } from '@mui/material';
import PageContainer from '../../components/container/PageContainer';
import { Validators } from '../../validators';
import { register, signUpValidateToken } from '../../lib/apiClient';
import LogoScreen from './LogoScreen';
import CustomForm, { InputField } from '../../shared/form/CustomForm';
import { useSnackbar } from 'notistack';

const inputsField: InputField[] = [
  {
    label: 'First Name',
    validators: [Validators.minLength(2)],
    name: 'name',
    size: 5.5,
    helperText: 'Must contain at least 3 characters',
  },
  {
    label: 'Last Name',
    validators: [Validators.minLength(2)],
    name: 'lastName',
    size: 5.5,
    helperText: 'Must contain at least 3 characters',
  },
  {
    label: 'Company Name',
    validators: [Validators.minLength(2)],
    name: 'company',
    helperText: 'Must contain at least 3 characters',
  },
  {
    label: 'Email',
    validators: [Validators.isEmail],
    name: 'email',
    type: 'email',
  },
  {
    label: 'Password',
    validators: [Validators.minLength(5)],
    name: 'password',
    type: 'password',
    helperText: 'Must contain at least 6 characters',
  },
  {
    label: 'Confirm Password',
    validators: [Validators.minLength(5)],
    name: 'confirmPassword',
    type: 'password',
    helperText: 'Must contain at least 6 characters',
  },
];

export const Register = () => {
  const [registered, setRegistered] = useState(false);
  const { enqueueSnackbar } = useSnackbar();


  const handleRegister = async (data: any) => {
    try {
      await register({
        email: data.email,
        first_name: data.name,
        last_name: data.lastName,
        username: data.email,
        password: data.password,
      });
      setRegistered(true);
    } catch (e:any) {
      enqueueSnackbar(e.username[0] ?? 'something went wrong.', { variant: 'error' });
    }
  };

  return (
    <PageContainer title="Login" description="this is Login page">
      <Grid container spacing={0} sx={{ height: '100vh', justifyContent: 'center' }}>
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          sx={{
            background: (theme) => `${theme.palette.mode === 'dark' ? '#1c1f25' : '#ffffff'}`,
          }}
        >
          <LogoScreen />
        </Grid>
        <Grid item xs={12} sm={8} lg={6} display="flex" alignItems="center">
          <Grid container spacing={0} display="flex" justifyContent="center">
            <Grid item xs={12} lg={9} xl={6}>
              <Box>
                {!registered && (
                  <CustomForm
                    inputsField={inputsField}
                    endpoint={handleRegister}
                    buttonLabel="Sign Up"
                    titleForm="User Registration"
                    thereIsPasswordConfirm={true}
                  />
                )}
                {registered && (
                  <Grid item>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '15px 0 10px 0',
                        flexDirection: 'column',
                      }}
                    >
                      <Box>
                        <Typography
                          fontWeight="500"
                          sx={{
                            display: 'block',
                            textDecoration: 'none',
                          }}
                        >
                          Check your email box to verify your account
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px',
                  }}
                >
                  <Box>
                    <Typography
                      component={Link}
                      to="/auth/login"
                      fontWeight="500"
                      sx={{
                        display: 'block',
                        textDecoration: 'none',
                        color: 'primary.main',
                      }}
                    >
                      Go back to log in page
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Register;
