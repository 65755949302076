import React from 'react';
import { Navigate } from 'react-router-dom';
import { HistoryBreadCrumbs } from '../components/shared';
import { ProcessList, TransitionDetail, ProcessInstanceDetail, ProcessDetail } from '../views';
import { HasCompany } from './guards/HasCompany';
import { InstanceDetailCompanySelected } from './guards/InstanceDetailCompanySelected';
import { ProcessDetailCompanySelected } from './guards/ProcessDetailCompanySelected';
import { RequireAuth } from './guards/RequireAuth';
import { FullLayout } from './lazyload/FullLayout';

export const processesRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/processes" /> },
      {
        path: '/processes',
        exact: true,
        element: (
          <RequireAuth>
            <HistoryBreadCrumbs option="breadcrumbs" />
            <ProcessList />
          </RequireAuth>
        ),
      },
      {
        path: '/process/:processId',
        exact: true,
        element: (
          <RequireAuth>
            <HistoryBreadCrumbs option="breadcrumbs" />
            <ProcessDetailCompanySelected>
              <ProcessDetail />
            </ProcessDetailCompanySelected>
          </RequireAuth>
        ),
      },
      {
        path: '/instance/:processInstanceId',
        exact: true,
        element: (
          <RequireAuth>
            <HasCompany>
              <HistoryBreadCrumbs option="breadcrumbs" />
              <ProcessInstanceDetail />
            </HasCompany>
          </RequireAuth>
        ),
      },
      {
        path: '/transition/:transitionId',
        exact: true,
        element: (
          <RequireAuth>
            <HasCompany>
              <HistoryBreadCrumbs option="breadcrumbs" />
              <TransitionDetail />
            </HasCompany>
          </RequireAuth>
        ),
      },

      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];
