export const PROCESS_LIST = 'Process List';
export const PROCESS_DETAIL = 'Process Detail';
export const PROCESS_INSTANCE = 'Process Instance';
export const TRANSITION_DETAIL = 'Transition Detail';
export const CLEAN = 'Clean BreadCrumbs';

export type ActionType =
  | 'Process List'
  | 'Process Detail'
  | 'Process Instance'
  | 'Clean BreadCrumbs'
  | 'Transition Detail';
