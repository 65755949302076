import { Process } from '../../interface/Process';
import { Storage } from '../../lib/utils';
import {
  GET_PROCESSES,
  GET_PROCESS_BY_ID,
  LOADING,
  CLEAN_PROCESS,
  SET_CONFIG_DIALOG_OPEN,
  SET_ADD_STEP_DIALOG,
  ActionType,
} from './types';

type State = {
  processes?: Process[];
  loading: boolean;
  process?: Process | '';
  configDialogOpen: boolean;
  addStepDialogOpen: boolean;
};

const initialState: State = {
  processes: [],
  loading: false,
  process: Storage.get<Process>('process') || '',
  configDialogOpen: false,
  addStepDialogOpen: false,
};

export const processesReducer = (
  state = initialState,
  action: { type: ActionType; payload: any },
) => {
  switch (action.type) {
    case GET_PROCESSES:
      return {
        ...state,
        processes: action.payload,
        loading: false,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PROCESS_BY_ID:
      return {
        ...state,
        process: action.payload,
        loading: false,
      };
    case CLEAN_PROCESS:
      return {
        ...state,
        processes: undefined,
        process: undefined,
      };
    case SET_CONFIG_DIALOG_OPEN:
      return {
        ...state,
        configDialogOpen: !state.configDialogOpen,
      };
    case SET_ADD_STEP_DIALOG:
      return {
        ...state,
        addStepDialogOpen: !state.addStepDialogOpen,
      };
    default:
      return state;
  }
};
