import { Storage } from '../../lib/utils';
import { LOGIN, LOGOUT, ActionType } from './types';

interface Session {
  accessToken?: string;
  username?: string;
  id?: number;
}

const session = Storage.get<Session>('session', {});

const initialState = {
  accessToken: session?.accessToken,
  username: session?.username,
  isLoggedIn: !!session && !!session?.accessToken && !!session?.accessToken.length,
  id: session?.id
};

export const authReducer = (state = initialState, action: { type: ActionType; payload: any }) => {
  switch (action.type) {
    case LOGIN:
      return {
        username: action.payload.username,
        accessToken: action.payload.accessToken,
        isLoggedIn: action.payload.isLoggedIn,
        id: action.payload.id
      };
    case LOGOUT:
      return {
        isLoggedIn: false,
      };
    default:
      return state;
  }
};
