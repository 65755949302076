import { Transition, TransitionLogs } from '../interface/Transition';
import { Company } from '../interface/Company';
import { CreateStep, Process, Step, UpdatePartialStep } from '../interface/Process';
import { ProcessToCreateForm } from '../interface/Form';
import { ProcessInstance } from '../interface/ProcessInstance';
import { User } from '../interface/User';
import { Storage } from './utils';
import { StepLog } from '../interface/StepLog';
import { baseUrlLet, baseWSLet } from '../constants/baseUrl';
import { CreateRobot, Robot } from '../interface/Robot';
import { CreateFormBody, Form, CreateFormResponse } from '../interface/Form';
import { CreateWorkspace, Workspace } from '../interface/Workspace';
import { IBillingInfo } from '../interface/BillingInfo';

interface Session {
  accessToken?: string;
  id?: number;
}

type UpdateProcess = {
  name: string;
  enable: boolean;
  console: boolean;
  mail: string;
  etiqueta: string;
};

type Options = {
  body?: any;
  method?: string;
  withToken?: boolean;
};

type Register = {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
};

const baseUrl = baseUrlLet;
export const baseWS = baseWSLet;

const createOptions = <T>(body: T, method = 'POST', withToken = true) => {
  const auxReturn: any = {};
  const headers = new Headers();
  if (method !== 'GET') {
    headers.append('Content-Type', 'application/json');
    auxReturn.body = JSON.stringify(body);
    auxReturn.method = method;
  }
  if (withToken) {
    const session = Storage.get<Session>('session');
    headers.append('Authorization', `Token ${session?.accessToken}`);
  }
  auxReturn.headers = headers;
  return auxReturn;
};

const customFetch = async <T>(url: string, options: Options, msgError = '') => {
  const { body, method, withToken } = options;
  const response = await fetch(url, createOptions(body, method, withToken));

  if (!response.ok) {
    const msg = msgError ? msgError : `Error ${response.status}`;
    console.log();
    const responseError = await response.json();

    throw responseError;
  }
  return (await response.json()) as Promise<T>;
};

export async function login(username: string, password: string) {
  const url = `${baseUrl}/api/auth/token/`;
  const msg = 'User or Password is incorrect';
  const options: Options = {
    body: { username, password },
    withToken: false,
  };
  return await customFetch<User>(url, options, msg);
}

export async function getProcesses(company: Company): Promise<Process[]> {
  if (!company) {
    return [];
  }
  const url = `${baseUrl}/frontend/process/${company}/`;
  return customFetch(url, { body: null, method: 'GET' });
}

export async function deleteProcess(id: string) {
  const url = `${baseUrl}/frontend/v1/process/${id}/`;
  return customFetch(url, { body: null, method: 'DELETE' });
}

export async function runProcess(processId: string | number) {
  const url = `${baseUrl}/frontend/process/run/`;
  return customFetch<any>(url, { body: { proceso: processId } });
}

export async function updateSchedule(payload: any) {
  const url = `${baseUrl}/frontend/scheduler/`;
  return customFetch(url, { body: payload });
}

export async function updateStep(payload: any) {
  const url = `${baseUrl}/frontend/v1/step/`;
  return customFetch(url, { body: payload, method: 'PATCH' });
}

export async function createProcess(payload: any) {
  const url = `${baseUrl}/frontend/v1/process/`;
  return customFetch(url, { body: payload });
}

export async function getCompanies(): Promise<Company[]> {
  const url = `${baseUrl}/frontend/company/`;
  try {
    return customFetch(url, { body: null, method: 'GET' });
  } catch (error) {
    return [];
  }
}

export async function getLogs(processId: string) {
  const url = `${baseUrl}/frontend/logs/${processId}/`;
  return customFetch(url, { body: null, method: 'GET' });
}

export async function updateProcess(body: UpdateProcess) {
  const url = `${baseUrl}/frontend/v1/process/`;
  return customFetch(url, { body, method: 'PATCH' });
}

export async function updateCompany(id: any, body: Partial<Company>): Promise<any> {
  const url = `${baseUrl}/frontend/v1/company/${id}/`;
  return customFetch(url, { body, method: 'PATCH' });
}

export async function getSteps(companyId: string, processId: string): Promise<Step[]> {
  const url = `${baseUrl}/frontend/step/list/${companyId}/${processId}/`;
  return customFetch(url, { body: null, method: 'GET' });
}

export async function getProcessById(processId: string): Promise<Process> {
  const url = `${baseUrl}/frontend/v1/process/${processId}/`;
  return customFetch(url, { body: null, method: 'GET' });
}

export async function getStepLogs(stepId: string): Promise<StepLog[]> {
  const url = `${baseUrl}/frontend/transition/list/${stepId}/`;
  try {
    return customFetch(url, { body: null, method: 'GET' });
  } catch (error) {
    return [];
  }
}

export async function getProcessInstancesByProcessId(
  companyId: string,
  processId: string,
): Promise<ProcessInstance[]> {
  const url = `${baseUrl}/frontend/instance/list/${companyId}/${processId}/`;
  try {
    return customFetch(url, { body: null, method: 'GET' });
  } catch (error) {
    return [];
  }
}

export async function getProcessInstanceDetailById(
  companyId: string,
  processInstanceId: string,
): Promise<ProcessInstance> {
  const url = `${baseUrl}/frontend/instance/detail/${companyId}/${processInstanceId}/`;
  return customFetch(url, { body: null, method: 'GET' });
}

export async function getTransitionById(transitionId: string): Promise<Transition> {
  const url = `${baseUrl}/frontend/transition/detail/${transitionId}/`;
  return customFetch(url, { body: null, method: 'GET' });
}

export async function getTransitionLogsById(transitionId: string): Promise<TransitionLogs> {
  const url = `${baseUrl}/frontend/transition/console/${transitionId}/`;
  return customFetch(url, { body: null, method: 'GET' });
}

export async function cancelTransition(transitionId: number): Promise<void> {
  const url = `${baseUrl}/frontend/v1/transition/cancel/`;
  return customFetch(url, {
    body: {
      id: transitionId,
    },
    method: 'POST',
  });
}

export async function createWorkspace(body: CreateWorkspace): Promise<Workspace> {
  const url = `${baseUrl}/frontend/v1/workspace/`;
  return customFetch(url, { body });
}

export async function getWorkspaceById(workspaceId: string): Promise<TransitionLogs> {
  const url = `${baseUrl}/frontend/v1/workspace/${workspaceId}/`;
  return customFetch(url, { body: null, method: 'GET' });
}

export async function updateWorkspace(body: CreateWorkspace): Promise<Workspace> {
  const url = `${baseUrl}/frontend/v1/workspace/`;
  return customFetch(url, { body, method: 'PATCH' });
}

export async function createRobot(body: CreateRobot): Promise<Robot> {
  const url = `${baseUrl}/frontend/v1/robot/`;
  return customFetch(url, { body });
}

export async function updateRobot(body: Robot): Promise<Robot> {
  const url = `${baseUrl}/frontend/v1/robot/`;
  return customFetch(url, { body, method: 'PATCH' });
}
export async function deleteRobot(id: string): Promise<Robot> {
  const url = `${baseUrl}/frontend/v1/robot/${id}/`;
  return customFetch(url, { body: null, method: 'DELETE' });
}

export async function createForm(body: CreateFormBody): Promise<CreateFormResponse> {
  const url = `${baseUrl}/frontend/v1/form/`;
  return customFetch(url, {
    method: 'POST',
    body,
  });
}

export async function updateForm(body: Form): Promise<Form> {
  const url = `${baseUrl}/frontend/v1/form/`;
  return customFetch(url, {
    body,
    method: 'PATCH',
  });
}

export async function deleteForm(id: string) {
  const url = `${baseUrl}/frontend/v1/form/delete/${id}/`; //frontend/v1/form/delete/<int:id>/
  return customFetch(url, { body: null, method: 'DELETE' });
}

interface SubmitFormBody {
  meta: Record<string, string>;
  key: string;
}

export async function submitForm(body: SubmitFormBody): Promise<void> {
  const url = `${baseUrl}/frontend/v1/form/submit/`;
  return customFetch(url, {
    body,
    method: 'POST',
  });
}

export async function getRobotById(robotId: string): Promise<Robot> {
  const url = `${baseUrl}/frontend/v1/robot/${robotId}/`;
  return customFetch(url, { body: null, method: 'GET' });
}

export async function getCapability(companyId: string): Promise<Robot> {
  const url = `${baseUrl}/frontend/v1/capability/${companyId}/`;
  return customFetch(url, { body: null, method: 'GET' });
}

export async function createStep(body: CreateStep): Promise<CreateStep> {
  const url = `${baseUrl}/frontend/v1/step/`;
  return customFetch(url, { body });
}

export async function updatePartialSteps(body: UpdatePartialStep[]): Promise<CreateStep> {
  const url = `${baseUrl}/frontend/v1/steps/`;
  return customFetch(url, { body, method: 'PATCH' });
}

type SecretBody = {
  action: 'C' | 'L' | 'U' | 'D';
  secret_key?: string;
  secret_value?: string;
  company_id?: string;
  workspace_id?: string;
};

export async function apiSecret(body: SecretBody): Promise<{ keys: string[] }> {
  const url = `${baseUrl}/frontend/v1/secret/`;
  return customFetch(url, { body, method: 'POST' });
}

export async function healthcheckOn(id: number): Promise<{ Response: true; Message: string }> {
  const url = `${baseUrl}/frontend/v1/workspace/healthcheck/${id}/`;
  return customFetch(url, { body: null, method: 'GET' });
}

export async function startContainer(id: number): Promise<{ Response: true; Message: string }> {
  const url = `${baseUrl}/frontend/v1/workspace/deployment/`;
  const paylaod = {
    workspace_id: id,
    start: true,
    stop: false,
  };
  return customFetch(url, { body: paylaod, method: 'POST' });
}

export async function stopContainer(id: number): Promise<{ Response: true; Message: string }> {
  const url = `${baseUrl}/frontend/v1/workspace/deployment/`;
  const paylaod = {
    workspace_id: id,
    start: false,
    stop: true,
  };
  return customFetch(url, { body: paylaod, method: 'POST' });
}

export async function resetEmail(body: { email: string }): Promise<{ status?: string }> {
  const url = `${baseUrl}/api/password_reset/`;
  return customFetch(url, { body, method: 'POST' });
}

export async function resetValidateToken(body: { token: string }): Promise<{ status?: string }> {
  const url = `${baseUrl}/api/password_reset/validate_token/`;
  return customFetch(url, { body, method: 'POST' });
}

export async function resetPassword(body: { token: string; password: string }): Promise<void> {
  const url = `${baseUrl}/api/password_reset/confirm/`;
  return customFetch(url, { body, method: 'POST' });
}

export async function register(body: Register): Promise<void> {
  const url = `${baseUrl}/frontend/v1/register/`;
  return customFetch(url, { body, method: 'POST', withToken: false });
}

export async function signUpValidateToken(body: { token: string }): Promise<{ status?: string }> {
  const url = `${baseUrl}/frontend/v1/register/email-verify/`;
  return customFetch(url, { body, method: 'POST', withToken: false });
}

export async function deployRobot(body: { id: number; step_id: number }): Promise<any> {
  const url = `${baseUrl}/frontend/v1/robot/deployment/`;
  return customFetch(url, { body, method: 'POST' });
}

export async function createCompany(body: any): Promise<any> {
  const url = `${baseUrl}/frontend/v1/company/`;
  return customFetch(url, { body, method: 'POST' });
}

export async function createBillingInfo(body: IBillingInfo): Promise<any> {
  const url = `${baseUrl}/frontend/v1/billing/`;
  return customFetch(url, { body, method: 'POST' });
}

export async function getStripeSecret(id: number): Promise<{ client_secret: any }> {
  const url = `${baseUrl}/frontend/v1/billing-secret/${id}/`;
  return customFetch(url, { body: null, method: 'GET' });
}

export async function deleteStep(id: string) {
  const url = `${baseUrl}/frontend/v1/step/${id}/`;
  return customFetch(url, { body: null, method: 'DELETE' });
}

export async function getWorkspaceQueue(id: string) {
  const url = `${baseUrl}/frontend/v1/workspace/queue/${id}/`;
  return customFetch(url, { body: null, method: 'GET' });
}

export async function postCode(data: any) {
  const url = `${baseUrl}/accounts/oauth/authorization_code/`;
  return customFetch(url, { body: data, method: 'POST', withToken: false });
}

export async function getUser() {
  const url = `${baseUrl}/api/v1/user/`;
  return customFetch(url, { body: null, method: 'POST' });
}
